import { Injectable } from "@angular/core";
import { StorageService } from "../services/storage.service";
import { isPartnerAccount } from "./WindowUtil";
import { P_HAVE_ACTIVE_SUBSCRIPTION, P_PARTNER_ACCOUNT, P_USER_TYPE } from "../constants/PartnerConstants";
import { HAVE_ACTIVE_SUBSCRIPTION, USER_TYPE } from "../constants/Constants";
import { HttpClient } from "@angular/common/http";
import { GET_USER_SUBSCRIPTIONS } from "../constants/ApiEndPoints";

@Injectable()
export class UserUtil {
    public phoneNumbers: string[] = [];
    isPA: boolean = false;
    constructor(public storageService: StorageService, private http: HttpClient) {
        // console.log('BaseComponent constructor is called');
        this.isPA = isPartnerAccount(this.storageService);
    }
    public  isPartnerAccount() {
        const isPA = this.storageService.getItem(P_PARTNER_ACCOUNT);
        return isPA != null && isPA === 'true';
    }

    haveActiveSubscription() {
        this.isPA = isPartnerAccount(this.storageService);
        const key = this.isPA ? P_HAVE_ACTIVE_SUBSCRIPTION : HAVE_ACTIVE_SUBSCRIPTION;
        const status = JSON.parse(this.storageService.getItem(key));
        return status;
    }

    public getUserType() {
        const isPA = isPartnerAccount(this.storageService);
        const userType = isPA ? P_USER_TYPE : USER_TYPE;
        return this.storageService.getItem(userType);
    }

    public getCurrentUser() {
        return this.storageService.getCurrentUser();
    }

    public getCurrentUserEmail() {        
        return this.storageService.getCurrentUser().email;
    }

}