<header>
	<div class="container">
		<div class="topsect">	
			<div *ngIf="!partnerLoginInprogress" (click)="homeButtonClick()" class="logo d-none d-sm-none d-md-block"><img src="assets/img/wlogo.svg" alt="" style="width: 130px;" /></div>
			<div *ngIf="!partnerLoginInprogress" (click)="homeButtonClick()" class="logo d-block d-sm-block d-md-none"><img src="assets/img/logo.svg" alt="" style="width: 40px;" /></div>
			<div *ngIf="!loggedIn && isAppInBrowser">
				<ul>
					<li class="nav-item" [ngClass]="{'active': header.isActive}" *ngFor="let header of guestUserHeaders" (click)="makeGuestUserHeaderActive(header.title)">
						<a class="nav-link" style="cursor: pointer;">{{header.title}}</a>
					</li>
				</ul>
			</div>
			<div *ngIf="loggedIn">
				<ul>
					<li class="nav-item" [ngClass]="{'active': header.isActive}" *ngFor="let header of userHeaders" (click)="makeActive(header.title, header.course_id)">
						<a class="nav-link"  style="cursor: pointer;">{{header.title}}</a>
					</li>
					<i *ngIf="!hideNavMenu" (click)="toggleNav()"  class="fa fa-bars" aria-hidden="true"></i>
					<!-- <i  (click)="toggleNav()"  class="fa fa-bars" aria-hidden="true"></i> -->
					<!-- <span  style="background-color: #ef6603;
					width: 15px;
					height: 15px;
					border-radius: 100%;
					cursor: pointer;
					position: relative;
					margin-top: -25px;
					margin-left: -10px;" (click)="toggleNav()"></span> -->
					<!-- <div class="munuinfo" (click)="toggleNav()"> 
								<i (click)="toggleNav()" style="font-size: 38px; color: #37726C;" class="fa fa-bars" aria-hidden="true">
							</i>
						<span style="font-size:30px;cursor:pointer; margin-left: 10px;" (click)="toggleNav()"></span>
					</div>
					<div class="munuinfo"><span (click)="toggleNav()"></span></div> -->
				</ul>
			</div>
		</div>
	</div>
	<div id="mySidenav" class="sidenav" [ngClass]="{'expandNav': isNavOpened}">
		<a href="javascript:void(0)" class="closebtn" (click)="toggleNav()">&times;</a>
		<a *ngIf="!loggedIn" (click)="navigateTo('privacy')"  style="cursor: pointer;">Privacy</a>
		<a *ngIf="loggedIn"  (click)="navigateTo('my-account')" style="cursor: pointer;">My Profile</a>
		<a *ngIf="loggedIn"  (click)="navigateTo('my-notifications')" style="cursor: pointer;">Notifications</a>
		<a *ngIf="loggedIn" (click)="navigateTo('my-journey')" style="cursor: pointer;">My Journey</a>
		<a *ngIf="loggedIn" (click)="navigateTo('moodtracker')" style="cursor: pointer;">Mood Tracker</a>
		<a *ngIf="loggedIn" (click)="navigateTo('onmood-course/3/courses/sleephistory')" style="cursor: pointer;">Sleep Hygiene</a>
		<a *ngIf="loggedIn" (click)="navigateTo('onmood-course/3/courses/checkouthistory')" style="cursor: pointer;">Sleep Tracker</a>
		<a *ngIf="loggedIn" (click)="navigateTo('my-favourites')" style="cursor: pointer;">My Favourites</a>
		<a *ngIf="loggedIn" (click)="navigateTo('my-therapists')" style="cursor: pointer;">My Therapists</a>
		<!-- <a *ngIf="loggedIn" (click)="navigateTo('my-care-plan')" style="cursor: pointer;">My Care Plan</a> -->
		
		<a *ngIf="loggedIn" href="https://wa.me/918897045351" target="_blank" style="cursor: pointer;">Mood9 Coach</a>
		<a *ngIf="loggedIn" (click)="navigateTo('blog/category/all')" style="cursor: pointer;">Learn</a>
		<a *ngIf="loggedIn" (click)="logout()" style="cursor: pointer;">Signout</a>

	</div>
</header>