import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { P_CUNIQUE_ID, P_ENCRYPTED, P_PARTNER_ACCOUNT_RELOAD } from 'src/app/constants/PartnerConstants';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-partner-alert',
  templateUrl: './partner-alert.component.html',
  styleUrls: ['./partner-alert.component.css']
})
export class PartnerAlertComponent implements OnInit {

  constructor(private _router: Router, private authenticationService: AuthenticationService, private storageService: StorageService) { }

  ngOnInit(): void {
  }

  continueHere() {
      const encrypted = this.storageService.getItem(P_ENCRYPTED);
			const p_cunique_id = this.storageService.getItem(P_CUNIQUE_ID);
			this.authenticationService.logout();
	  
			this.storageService.storeItem(P_PARTNER_ACCOUNT_RELOAD, 'true');
			this.storageService.storeItem(P_ENCRYPTED, encrypted);
			this.storageService.storeItem(P_CUNIQUE_ID, p_cunique_id);
      this._router.navigate(["/home"]);
      
      setTimeout(()=>{
        window.location.reload();
      }, 2000);
  }

}
