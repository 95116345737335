import { Component } from '@angular/core';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent {
  settings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplay: true,
    centerMode: true,
    centerPadding: 0,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }
 
  favouriteProfiles = [
    {
    "id": 1,
    "name": "Matthew Reyes",
    "image":"assets/img/doctors-grid/348x350-0.jpg",
    "specialist": "Obstetrics & Gynaecology",
    "location": "Hong Kong",

  },
  {
    "id": 2,
    "name": "Matthew Reyes",
    "image":"assets/img/doctors-grid/348x350-1.jpg",
    "specialist": "Obstetrics & Gynaecology",
    "location": "Hong Kong",

  },
  {
    "id": 3,
    "name": "Matthew Reyes",
    "image":"assets/img/doctors-grid/348x350-2.jpg",
    "specialist": "Obstetrics & Gynaecology",
    "location": "Hong Kong",

  },
  {
    "id": 4,
    "name": "Matthew Reyes",
    "image":"assets/img/doctors-grid/348x350-3.jpg",
    "specialist": "Obstetrics & Gynaecology",
    "location": "Hong Kong",

  },
  {
    "id": 5,
    "name": "Matthew Reyes",
    "image":"assets/img/doctors-grid/348x350-4.jpg",
    "specialist": "Obstetrics & Gynaecology",
    "location": "Hong Kong",

  },
  {
    "id": 6,
    "name": "Matthew Reyes",
    "image":"assets/img/doctors-grid/348x350-5.jpg",
    "specialist": "Obstetrics & Gynaecology",
    "location": "Hong Kong",

  },


]
}
