<div class="sidebar mb-5">
    <!-- Category Widget -->
    <!-- <div class="widget widget-categories">
        <h5 class="widget-title">Expertise (services)</h5>
        <ul>
            
            <li>
                <a>Stress</a>
                <a>Depression</a>
                <a>Anxiety</a>
                <a>Relationship</a>
                <a>Sleep Care </a>
            </li>
            
        </ul>
    </div>  -->
    <!-- <div class="widget widget-categories">
        <h5 class="widget-title">Therapies</h5>
        <ul>
           
            <li>
                <a>NLP</a>
                <a>Trauma Therapy</a>
                <a>CBT Therapy</a>
            </li>
            
        </ul>
    </div>  -->
    <!-- <div class="widget widget-categories">
        <h5 class="widget-title">Languages</h5>
        <div style="max-height: 100px; overflow-y: auto;scrollbar-color: #b3d0c9 #ffffff;scrollbar-width: thin;">
            <input type="checkbox" name="english" id="availabilityDoctors">
            <label class="mb-0" for="availabilityDoctors">English</label>
            <input type="checkbox" name="telugu" id="telugu">
            <label class="mb-0" for="telugu">Telugu</label>
            <input type="checkbox" name="hindi" id="hindi">
            <label class="mb-0" for="hindi">Hindi</label>
            <input type="checkbox" name="bengali" id="bengali">
            <label class="mb-0" for="bengali">Bengali</label>
        </div>
    </div> -->
    <div class="widget">
        <h5 class="widget-title">Consult</h5>
        <input type="radio" name="gendorDoctor" value="" id="genderDoctor">
        <label class="mb-0" for="genderDoctor">Online</label>
        <input type="radio" name="gendorDoctor" value="" id="genderDoctor1">
        <label class="mb-0"  for="genderDoctor1">Offline</label>
        <input type="radio" name="gendorDoctor" value="" id="genderDoctor2">
        <label class="mb-0" for="genderDoctor2">Both</label>
    </div>
    <!-- <div class="widget">
        <h5 class="widget-title">Consultation Fees - (Rs)</h5>
        <input type="checkbox" name="500" id="availabile">
        <label class="mb-0" for="availabile">Upto 500</label>
        <input type="checkbox" name="501" id="unavailableDoctors">
        <label class="mb-0" for="unavailableDoctors">501 - 1000</label>
        <input type="checkbox" name="1001" id="unavailableDoctors1">
        <label class="mb-0" for="unavailableDoctors1">1001 - 1500</label>
        <input type="checkbox" name="1500" id="unavailableDoctors2">
        <label class="mb-0" for="unavailableDoctors2">Above 1500</label>
    </div> -->

    <!-- <div class="widget">
        <h5 class="widget-title">Years of Experience</h5>
    </div> -->
</div>