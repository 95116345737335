import { P_PARTNER_ACCOUNT, P_PARTNER_APP } from "../constants/PartnerConstants";
import { StorageService } from "../services/storage.service";

export const isOpenedInBrowser = () => {
    return window.self === window.top;
}

export const isPartnerAccount = (storageService: StorageService) => {
    const isPA = storageService.getItem(P_PARTNER_ACCOUNT);
    return isPA != null && isPA === 'true';
}

export const isPartnerAppMobile = (storageService: StorageService) => {
    const isPartnerApp = storageService.getItem(P_PARTNER_APP);
    return isPartnerApp && isPartnerApp === 'mobile';
}

export const isPartnerAppWeb = (storageService: StorageService) => {
    const isPartnerApp = storageService.getItem(P_PARTNER_APP);
    return isPartnerApp && isPartnerApp === 'web';
}