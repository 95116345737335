<div class="body">
    <div class="sigma_banner style-8">
        <ngx-slick-carousel [config]="settings" class="sigma_banner-slider">
            <!-- Data -->
            <div ngxSlickItem class="banner-slider-inner bg-center bg-cover secondary-overlay"
                [style.background-image]="'url('+item.image+')'" *ngFor="let item of banner">
                <div class="sigma_banner-text text-center">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-lg-8">
                                <h5 class="text-white">{{item.subtitle}}</h5>
                                <h1 class="title text-white" [innerHtml]="item.title"></h1>
                                <div class="banner-links align-items-center justify-content-center">
                                    <a routerLink="/doctor-grid" class="sigma_btn">
                                        Psychologist
                                        <i class="fal fa-plus ml-3 d-none d-sm-inline-block"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Data -->
        </ngx-slick-carousel>

    </div>
    <app-search-form></app-search-form>
    <app-services></app-services>
    <div class="section bg-secondary-1" [style.background-image]="'url(assets/img/pattern.png)'">
        <div class="container">
            <app-why-us></app-why-us>
        </div>
    </div>
    <!-- <div class="section pb-0 bg-gray"></div> -->
    <app-work-process></app-work-process>
    <!-- <app-team></app-team> -->
</div>