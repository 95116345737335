import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute} from '@angular/router';
import { UserService } from "../../services/user.service";
import { AuthenticationService } from "../../services/authentication.service";

@Component({
  selector: 'app-activate-user-account',
  templateUrl: './activate-user-account.component.html',
  styleUrls: ['./activate-user-account.component.css']
})
export class ActivateUserAccountComponent implements OnInit {
  resetLink: string = "";
  userEmail: string = "";
  message = "Please wait, we are verifying your account";
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,private userService: UserService, private authenticationService: AuthenticationService,) { }

  ngOnInit(): void {
    // console.log("ActivateUserAccountComponent is called")
    this.activatedRoute.queryParams.subscribe((params:any) => {
      this.resetLink = params['code'];
      this.userEmail = params['login'];
      // console.log(this.userEmail+","+this.resetLink)
      this.verifyUserAccount();
    });
  }
  verifyUserAccount() {
    this.userService.verifyUserAccount(this.userEmail, this.resetLink)
      .subscribe(
        (data:any) => {
              // console.log("data:", data);
              if(data['status'] === false) {
                // this.errorResponseMessage = data['errorMessage'] 
                // SHow error message
              } else if(data['status'] === true) {
                // Show Success message, account is got activated, and redirect to signin page
                this.message = "Your account is verified, redirecting to Signin in few seconds..."
                setTimeout(()=> {
                  this.authenticationService.logout();
                  // this.router.navigate(['/home'])
                  // alert("Please Signin");
                }, 5000)
              }
          },
          error => {
              console.log("error:", error);
          });
  }

}
