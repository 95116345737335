<!-- <div *ngIf="!showPartnerRefresh"> -->
	<app-header *ngIf="!hideHeader || partnerLoginInprogress"></app-header>
	<div [style.min-height]="bodyHeight +'px'">
		<router-outlet></router-outlet>
	</div>
	<app-footer *ngIf="!hideFooter"></app-footer>
<!-- </div> -->
<!-- <div *ngIf="showPartnerRefresh" style="margin: 0; text-align: center; padding-top: 20%;">
	We lost your session data, click below button to fetch again<br />
	<button class="btn btn-primary" (click)="reload()">Continue</button>
</div> -->

  