import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Service } from 'src/app/model/service';
import { Specialization } from 'src/app/model/specialization';
import { Therapy } from 'src/app/model/therapy';
import { ApiService } from 'src/app/services/api-service';
import { ProfileDataService } from 'src/app/services/profile-data.service';
import { getNamesArray } from 'src/app/Utils/NameUtil';
import { CP_IMAGE_PATH } from 'src/app/constants/ApiEndPoints';
// import { DoctorHelperService } from 'src/app/services/doctor-helper.service';

@Component({
  selector: 'app-doctor-details',
  templateUrl: './doctor-details.component.html',
  styleUrls: ['./doctor-details.component.css']
})
export class DoctorDetailsComponent implements OnInit {
  cpImagePath: string;

  constructor(private router: Router, private route: ActivatedRoute, private service: ProfileDataService, private apiService: ApiService, private location: Location) {
    this.cpImagePath = CP_IMAGE_PATH;
  }
  item: any;
  cpProfileId: any;
  doctordetail: any;
  languages: [] = [];
  available_days: [] = [];
  therapyArray: [] = [];
  specializationArray: [] = [];
  serviceArray: [] = [];
  therayList: Array<Therapy> = [];
  serviceList: Array<Service> = [];
  getAuthor(arg0: any): any {
    throw new Error('Method not implemented.');
  }

  goBack() {
    this.location.back();
  }
  ngOnInit(): void {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.route.paramMap.subscribe((params: any) => {
      this.cpProfileId = params.get(['id']);
      console.log(this.cpProfileId);
    });
    this.apiService.getTherapy().subscribe(res => {
      // console.log(res.data);
      this.therayList = res.data;
    });
    this.apiService.getServices().subscribe(res => {
      // console.log(res.data);
      this.serviceList = res.data;
    });
    this.service.getProfileDetails(this.cpProfileId).subscribe(response => {
      // console.log(response.data[0]);
      this.doctordetail = response.data[0];
      this.doctordetail['picture'] = this.cpProfileId + '.jpeg';
      this.doctordetail['picture'] = this.doctordetail.is_picture === 'false' ? 'cpimage.jpg' : this.doctordetail.cp_profile_id + '.jpg';

      this.doctordetail['cp_therapy_id'] = getNamesArray('therapy', this.doctordetail?.cp_therapy_id, this.therayList);
      this.doctordetail['cp_service_id'] = getNamesArray('service', this.doctordetail?.cp_service_id, this.serviceList);
      this.doctordetail['cp_specialization_id'] = this.doctordetail?.cp_specialization_id?.split(',');
      this.doctordetail['mode'] = this.doctordetail?.mode === 'BOTH' ? 'ONLINE/OFFLINE' : this.doctordetail?.mode;
      const exp = this.doctordetail?.experience.split('-');
      if (exp && exp.length > 0) {
        this.doctordetail['experience'] = exp[0] + ' Years ' + exp[1] + ' Months';
      } else {
        this.doctordetail['experience'] = 'NA';
      }
      this.languages = response.data[0]?.languages?.split(',');
      this.available_days = response.data[0]?.available_days?.split(',');
    });



  }
}
