import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators} from '@angular/forms';
import {Router} from "@angular/router";
// import { HelperService } from 'src/app/services/helper.service';
import data from '../../../data/banner.json';

@Component({
    selector: 'app-care-provider',
    templateUrl: './care-provider.component.html',
    styleUrls: ['./care-provider.component.css']
  })
export class CareProviderComponent implements OnInit {
  public banner = data;
  constructor() { }
  settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: false,
    centerMode: true,
    centerPadding: 0,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true
        }
      }
    ]
  };
  ngOnInit(): void {
  }
}
