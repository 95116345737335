<div class="section sigma_post-details" style="background-color: #ffffffbf; padding: 1px;">
    <div class="header">
       <h2>My Therapist</h2>
    </div>
    <div class="container">
        <p style="font-size: 15px;">*By clicking the 'Approve' button, I hereby give my consent to share my personal data,
             including information related to my sleep patterns, mood, and well-being,
              with my therapist, in accordance with applicable privacy laws
               and regulations and I understand that this data will be used to enhance my therapy sessions
                and improve my care outcome'</p>
                <div class="toggle-btn">
                <label class="switch">
                    <input type="checkbox" [checked]="(this.allotedMemberCpProfile?.user_status) === 'ACTIVE' ?  true : false" (change)="ApproveStatus($event)">
                    <span class="slider round" style="display: flex">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"
                        style="fill:#4ADE80;">
                        <path d="M10 15.586L6.707 12.293 5.293 13.707 10 18.414 19.707 8.707 18.293 7.293z">
                        </path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"
                        style="fill:#F87171;">
                        <path
                            d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z">
                        </path>
                    </svg>
                    </span>
                    <!-- <span class="toggle-thumb">

                        

                    </span> -->
                </label>
            </div>
                <!-- <button class="btn btn-info" (click)="ApproveStatus()">Approve</button> -->
    </div>
    
    <div class="back-btn">
        <a (click)="goBack()" class="link" aria-label="Back"><i class='far fa-chevron-left'></i> Back</a>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="sigma_post-details-inner">
                    <div class="entry-content">
                        <div class="sigma_team style-17 mb-0">
                            <div class="row no-gutters">
                                <div class="col-md-4">
                                    <div class="sigma_team-thumb">
                                        <img [src]="cpImagePath + doctordetail?.picture" [alt]="">
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="sigma_team-body">
                                        <h5 style="color: #005963;">
                                            <a>{{doctordetail?.first_name}} {{doctordetail?.last_name}}</a>
                                            <!-- <a>test 1</a> -->
                                        </h5>

                                        <div class="sigma_team-categories">
                                            <a class="sigma_team-category">{{doctordetail?.specialization}}</a>
                                            <!-- <a class="sigma_team-category">speciality 1</a> -->

                                        </div>
                                        <span>Consult-{{doctordetail?.mode}}</span>
                                        <div class="sigma_team-info mt-4">
                                            <span>
                                                <i class="fal fa-phone"></i>
                                                (+91) - {{doctordetail?.phone_number}}
                                            </span>
                                            <span>
                                                <i class="fal fa-at"></i>
                                                {{doctordetail?.email}}
                                            </span>
                                            <span>
                                                <i class="fal fa-building"></i>
                                                {{doctordetail?.city}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="detail-menu-list">
                            <div class="row no-gutters">
                                <div class="col-md-4">
                                    <div class="menu nav-item">
                                        <a pageScroll href="#overview" class="nav-link active p-0">Overview</a>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="menu nav-item">
                                        <a pageScroll href="#contact-2" class="nav-link p-0">Contact</a>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="menu nav-item border-0">
                                        <a pageScroll href="#reviews" class="nav-link p-0">Profile</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="overview">
                            <h4>Education</h4>
                            <div [innerHtml]="doctordetail?.education"></div><br>
                            <h4>Achievements</h4>
                            <div [innerHtml]="doctordetail?.achievements"></div><br>
                            <h4>Expertise</h4>
                            <!-- <i class='far fa-check'></i> <span> {{item.therapies}}</span> -->
                            <div class="spacer" style="height:auto">
                                <div class='row'>
                                    <div class='col-lg-6' *ngFor="let service of  doctordetail?.cp_service_id; index as i">
                                        <div class='sigma_general-list style-3'>
                                            <ul>
                                                <li> <i class='far fa-check'></i> <span>{{service.service_name}}</span></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="spacer">
                        </div>
                        <div id="contact">
                            <div class="sigma_contact-blocks">
                                <h5>Address</h5>
                                <div class="row">
                                    <div class="col-md-4.5">
                                        <div class="sigma_contact-block style-3">
                                            <i class="fal fa-map-marker-alt icon"></i>
                                            <div class="contact-block-inner">
                                                <p>{{doctordetail?.address_line_one}}</p>
                                                <p>{{doctordetail?.address_line_two}}</p>
                                                <p>{{doctordetail?.city}}-{{doctordetail?.pincode}}, {{ doctordetail?.state}},
                                                    {{ doctordetail?.country}}</p>



                                                <!-- <p class="mb-0">{{doctordetail.city}}</p> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="spacer">
                        </div>
                        <div id="reviews">
                            <h4>Profile</h4>
                            <!-- Data -->
                            <div class="sigma_testimonial style-14">
                                <!-- <div class="sigma_testimonial-thumb">
                                    <img [src]="doctordetail.image" [alt]="doctordetail.name">
                                </div> -->
                                <div class="sigma_testimonial-body">
                                    <p>"{{doctordetail?.brief}}"</p>
                                </div>

                            </div>
                            <h4>Therapies</h4>
                            <!-- <i class='far fa-check'></i> <span> {{doctordetail.therapies}}</span> -->
                            <div class="spacer" style="height:auto">


                                <div class='row'>
                                    <div class='col-lg-6' *ngFor="let therapy of  doctordetail?.cp_therapy_id; index as i">
                                        <div class='sigma_general-list style-3'>
                                            <ul>
                                                <li> <i class='far fa-check'></i> <span>{{therapy.therapy_name}}</span></li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Sidebar Start -->
            <div class="col-lg-4">
                <div class="sidebar style-10 mt-5 mt-lg-0">
                    <!-- form Widget -->
                    <div class="widget widget-form">
                        <!-- <h5 class="widget-title">Booking Summary</h5> -->
                        <div class="widget-inner">

                            <label style="
                                font-size: 26px;
                                color: #005963;
                                font-family:'Heebo', sans-serif;">Experience</label>
                            <div class="form-group mb-0">
                                <span>{{doctordetail?.experience}}</span>
                            </div><br>
                            <label style="
                            font-size: 26px;
                            color: #005963;
                            font-family:'Heebo', sans-serif;">Consultation fee</label>
                            <div class="form-group mb-0">
                                <span> <span *ngIf="doctordetail?.fee > 0">&#x20B9;</span>{{doctordetail?.fee}}</span>
                            </div><br>

                        </div>
                        <hr>
                        <div class="widget-inner widget-service">
                            <form>
                                <label style="
                            font-size: 26px;
                            color: #005963;
                            font-family:'Heebo', sans-serif;
                            font-size:26px;">Availability</label>
                                <div class="form-group">
                                    <label>Consultation - Time</label>
                                    <ul>
                                        <li class="d-flex justify-content-between mb-3">
                                            <span class="mb-0">{{doctordetail?.work_hours}}</span>
                                        </li>
                                    </ul>

                                    <label>Consultation - Days</label>
                                    <ul *ngFor="let day of available_days">
                                        <li class="justify-content-between mb-3">
                                            <div>
                                                <span class="mb-0" for="checkbox">{{day}}</span>
                                            </div>

                                            <!-- <span>9:00 AM - 6:30 PM</span> -->
                                        </li>
                                    </ul>
                                </div>
                                <!-- <a routerLink="/appointment" class="sigma_btn btn-block btn-sm">
                                    Book Appointment
                                    <i class="fal fa-arrow-right ml-3"></i>
                                </a> -->
                            </form>
                        </div>
                    </div>
                    <!-- form Widget 2 -->
                    <div class="widget">
                        <h5 class="widget-title">Languages</h5>
                        <div class="widget-inner"
                            style="max-height: 100px; overflow-y: auto;scrollbar-color: #b3d0c9 #ffffff;scrollbar-width: thin;">
                            <ul *ngFor="let l of languages">
                                <li>{{l}}</li>
                                <!-- <li>Hindi</li>
                                <li>Telugu</li>
                                <li>Bengali</li> -->

                            </ul>
                        </div>
                    </div>
                    <!-- Contact Widget -->
                    <div class="widget" id="contact-2">
                        <h5 class="widget-title">Contact</h5>
                        <div class="widget-inner">
                            <div class="sigma_info style-24 p-0 shadow-none">
                                <div class="sigma_info-title">
                                    <span class="sigma_info-icon bg-primary-1 text-white">
                                        <i class="fal fa-phone"></i>
                                    </span>
                                </div>
                                <div class="sigma_info-description">
                                    <h5>Our Phone</h5>
                                    <p>(+91)- {{doctordetail?.phone_number}}</p>
                                </div>
                            </div>
                            <div class="sigma_info style-24 p-0 shadow-none">
                                <div class="sigma_info-title">
                                    <span class="sigma_info-icon bg-primary-1 text-white">
                                        <i class="fal fa-envelope-open-text"></i>
                                    </span>
                                </div>
                                <div class="sigma_info-description">
                                    <h5>Our Email</h5>
                                    <p>{{doctordetail?.email}}</p>
                                </div>
                            </div>
                            <div class="sigma_info style-24 p-0 shadow-none mb-0">
                                <div class="sigma_info-title">
                                    <span class="sigma_info-icon bg-primary-1 text-white">
                                        <i class="fal fa-globe icon"></i>
                                    </span>
                                </div>
                                <div class="sigma_info-description">
                                    <h5>Our Web Page</h5>
                                    <p><a href="https://{{doctordetail?.website}}/" target="new">{{doctordetail?.website}}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Sidebar End -->
        </div>
    </div>
</div>