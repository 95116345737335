<div class="container">
   <div style="text-align: center;" *ngIf="carePlanList.length > 0">My care Plan</div>
   <div style="text-align: center;" *ngIf="carePlanList.length === 0">No Active Care Plan Exist</div>
   <div *ngIf="carePlanList.length > 0">
        <div>
            <span>Plan: {{carePlanList[0].care_plan_name}}</span>
        </div>
        <div>
            <span>Description: {{carePlanList[0].care_plan_description}}</span>
        </div>
        <!-- <div class="row">
            <div class="col-6" style="text-align: center;" *ngFor="let item of carePlanList[0].items">
                Module: {{item.module_name}}
                <div *ngFor="let time of item.time">
                        Time: {{time.time_of_day}}
                </div>
            </div>
        </div> -->
        <div class="ptb60">
            <div class="row">
                <div class="col-md-6 mb-5" *ngFor="let item of carePlanList[0].items" data-aos="fade-up" data-aos-delay="100">
                    <div class="all-modulebx">
                        <div class="prod-module-img">
                            <!-- {{item.module_image | json}} -->
                            <img [src]="moduleImagePath + item.module_image" alt="" />
                            <!-- <i *ngIf="item.isLocked" class="fa fa-lock lock-icon"></i> -->
                        </div>
                        <div class="ml-4" >
                            <h4 *ngIf="item.module_name.length <= 20">
                                <a>{{item.module_name}}</a>
                            </h4>
                            <h4 *ngIf="item.module_name.length > 20">
                                <a>{{item.module_name | slice: 0: 20}}...</a>
                            </h4>
                            <p *ngFor="let time of item.time">
                                Time: {{time.time_of_day}}</p>
                            <!-- <p *ngIf="item.short_description.length <=75">
                                {{item.short_description}}</p>
                            <p *ngIf="item.short_description.length > 75">
                                {{item.short_description | slice: 0: 75}}...</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</div>