import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { isOpenedInBrowser, isPartnerAccount } from 'src/app/Utils/WindowUtil';
import { APPROVE_CP_PROFILE } from 'src/app/constants/ApiEndPoints';
import { CORP, CURRENT_USER, LINKED_ACCOUNT, USER_TYPE, CURRENT_SUBSCRIPTION, CP_PROFLE_ID } from 'src/app/constants/Constants';
import { P_CURRENT_SUBSCRIPTION, P_LINKED_ACCOUNT, P_USER_TYPE } from 'src/app/constants/PartnerConstants';
import { User } from 'src/app/model/user';
import { ApiService } from 'src/app/services/api-service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ProfileDataService } from 'src/app/services/profile-data.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';
import { BlogDetailsComponent } from '../blogs/blog-details/blog-details.component';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.css']
})
export class UserAccountComponent implements OnInit {

  screenHeight: number = 0;
  screenWidth: number = 0;
  showLoader: boolean = false;
  showMessage: string = "";
  showPasswordMessage: string = "";
  isMessageEmpty: boolean = true;
  isEditingPersonalInfo: boolean = false;
  isChangingPassword: boolean = false;
  isPasswordMessageEmpty: boolean = true;
  cpwd: string = "*************";
  user!: User;
  personalInfoForm!: FormGroup;
  passwordForm!: FormGroup;
  accountType: string;
  linkedAccount: string;
  currentSubscription: any = undefined;
  memberShipType: string = "";
  cpProfileId: any;
  allotedMemberCpProfile: any;
  allotedCpProfile: any;

  constructor(private formBuilder: FormBuilder, 
    private userService: UserService, 
    private authenticationService: AuthenticationService,
    private storageService: StorageService,
    private apiService: ApiService,
    private service: ProfileDataService) {
      this.getScreenSize();
    }
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    const isPA = isPartnerAccount(this.storageService);
    const userType = isPA && !isOpenedInBrowser() ? P_USER_TYPE : USER_TYPE;
    const LA = isPA && !isOpenedInBrowser() ? P_LINKED_ACCOUNT : LINKED_ACCOUNT;
    this.accountType = this.storageService.getItem(userType) === CORP ? "Corporate" : "Personal";
    if(isPA) this.accountType = "Corporate - Partner";
    this.linkedAccount = this.storageService.getItem(LA);
    const currentUser = this.storageService.getCurrentUser(); 
    console.log(currentUser);

    this.apiService.getUserByMemberCpProfileId(currentUser.id).subscribe(response => {
      this.storageService.storeItem(CP_PROFLE_ID, response['data'][0].cp_profile_id);
      this.cpProfileId = this.storageService.getItem(CP_PROFLE_ID);
      this.allotedMemberCpProfile = response['data'][0];
      console.log(this.cpProfileId)
          console.log(response['data'][0]);
    });

    this.service.getProfileDetails(this.storageService.getItem(CP_PROFLE_ID)).subscribe(response => {
      console.log(response.data[0]);
      this.allotedCpProfile = response.data[0];
    });

    
    if(currentUser) {
      this.user = currentUser;
      this.setupPersonalInfoForm();
      this.setupPasswordForm();

      // this.getMySubscriptions();
      this.getSubscriptionDetails();
    }
  }

  getSubscriptionDetails() {
    // no need to check for Partner account here, since we are not providing access for my profile
    // const isPA = isPartnerAccount(this.storageService);
    // const key = isPA ? P_CURRENT_SUBSCRIPTION : CURRENT_SUBSCRIPTION;
    const key = CURRENT_SUBSCRIPTION;
    this.currentSubscription = JSON.parse(this.storageService.getItem(key));
    console.log("currentSubscription:",this.currentSubscription);
    
    this.memberShipType = this.currentSubscription ? "Premium" : "Free";
  }

  setupPersonalInfoForm() {
    this.personalInfoForm = this.formBuilder.group({
      fname: [{value: this.user.fname, disabled: true}, Validators.required],
      contact: [{value: this.user.contact, disabled: true}, Validators.required],
      email: [{value: this.user.email, disabled: true}, [Validators.required, Validators.email]],
    });
  }

  setupPasswordForm() {
    this.passwordForm = this.formBuilder.group({
      cpwd: [{value: '', disabled: true}, Validators.required],
      npwd: [{value: '', disabled: true}, Validators.required],
      rtpwd: [{value: '', disabled: true}, Validators.required],
      email: [{value: this.user.email, disabled: true}, [Validators.required, Validators.email]],
    });
  }

  editPersonalInfo(isEditing: boolean) {
    this.isEditingPersonalInfo = isEditing;
    if(isEditing) {
      this.personalInfoForm.controls['fname'].enable();
      this.personalInfoForm.controls['contact'].enable();
      // this.personalInfoForm.controls['email'].enable();
    } else {      
      this.personalInfoForm.controls['fname'].disable();
      this.personalInfoForm.controls['contact'].disable();
      this.personalInfoForm.controls['email'].disable();
    }
  }
  editPassword(isEditing: boolean) {
    this.isChangingPassword = isEditing;
    // if(!isEditing) { 
    //   // this.cpwd = "*************";
    //   this.setupPasswordForm();
    // } else {
    //   this.cpwd = "";
    // }
    if(isEditing) {
      this.passwordForm.controls['cpwd'].enable();
      this.passwordForm.controls['npwd'].enable();
      this.passwordForm.controls['rtpwd'].enable();
    } else {      
      this.passwordForm.controls['cpwd'].disable();
      this.passwordForm.controls['npwd'].disable();
      this.passwordForm.controls['rtpwd'].disable();
    }
  }

  updatePersonalInfo() {
    // stop here if form is invalid
    if (this.personalInfoForm.invalid) { 
        return;
    }
    this.showLoader = true;
    let input = {
      userId: this.user.id,
      fname: this.personalInfoForm.controls['fname'].value,
      contact: this.personalInfoForm.controls['contact'].value,
      email: this.personalInfoForm.controls['email'].value
    };
    this.userService.updatePersonalInfo(input)
    .subscribe(
      (data:any) => {
          this.showLoader = false;
            if(data['status'] === true) {
                this.showMessage = "Successfully updated";
                this.isMessageEmpty = false;
                this.isEditingPersonalInfo = false;
                this.editPersonalInfo(false);
                this.storageService.storeItem(CURRENT_USER, JSON.stringify(data['user']))
                // this.authenticationService.currentUserSubject.next(data['user']);

                setTimeout(()=> {
                  this.showMessage = "";
                  this.isMessageEmpty = true;
                }, 5000)
            }
        },
        error => {
          this.showLoader = false;
            console.log("error:", error);
        });
        
  }
  
  updatePasswordInfo() {
    let npwd = this.passwordForm.controls['npwd'].value;
    let rtpwd = this.passwordForm.controls['rtpwd'].value;
    if(npwd === '' || rtpwd === '') {
      alert('New Password or Retype password cannot be empty');
      return;
    }
    if(npwd === rtpwd) {
      this.showLoader = true;
      let input = {
        userId: this.user.id,
        email: this.user.email,
        cpwd: this.passwordForm.controls['cpwd'].value,
        npwd: this.passwordForm.controls['npwd'].value
      };
      this.userService.updateUserPassword(input)
      .subscribe(
        (data:any) => {
          this.showLoader = false;
            if(data['status'] === true) {
                this.showPasswordMessage = "Successfully updated";
                this.isPasswordMessageEmpty = false;
                this.isChangingPassword = false;

                setTimeout(()=> {
                  this.showPasswordMessage = "";
                  this.isPasswordMessageEmpty = true;
                  this.editPassword(false);
                }, 5000)
            } else {
              this.showPasswordMessage = data['message'];
              this.isPasswordMessageEmpty = false;
              setTimeout(()=> {
                  this.showPasswordMessage = "";
                  this.isPasswordMessageEmpty = true;
                }, 5000)
            }
        },
        error => {
          this.showLoader = false;
            console.log("error:", error);
        });
    } else {
      alert("New Password and Retype Password didnot match");
    }
  
  }

  approveCp(cpau_id: string){
      if(confirm("Are you sure ?")) {
        const payload = {
          cpau_id: cpau_id
        }
        this.apiService.isUserAccept(APPROVE_CP_PROFILE, payload).subscribe((res: any) => {
          if(res['status'] === true) {
            alert("successfully approved");
          } else {
            alert("Something went wrong");
            
          }
        })
      }
    
  }


  // getMySubscriptions() {
  //   this.userService.getMySubscriptions(this.user.id).subscribe(data => { 
  //   }, error => {
  //     console.log(error)
  //   });
  // }

}
