import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { P_ENCRYPTED, P_IS_LOGGEDIN, P_PARTNER_ACCOUNT, P_PARTNER_ACCOUNT_LOGIN_FAILED, P_PARTNER_APP, P_PARTNER_LOGIN_INPROGRESS } from 'src/app/constants/PartnerConstants';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-partner-terms-privacy',
  templateUrl: './partner-terms-privacy.component.html',
  styleUrls: ['./partner-terms-privacy.component.css']
})
export class PartnerTermsPrivacyComponent implements OnInit {

  isProcessing: boolean = true;
  message1: string = 'Fetching your sessions data….';
  message2: string = 'Analysing your Mood Tracker records….';
  seconds: number = 5;
  intervalTimer: any;
  cunique_id: string = '';
  user_id: string = '';

  outputText = '';
  userSessionObj: any;
  constructor(private router:Router, private localStorageService: LocalStorageService, private authService: AuthenticationService, private storageService: StorageService, private route: ActivatedRoute) {
    this.storageService.storeItem(P_PARTNER_ACCOUNT, "true");
    this.storageService.storeItem(P_PARTNER_LOGIN_INPROGRESS, "true");
  }

  ngOnInit(): void {
    this.authService.hideFooter(true);
    this.authService.hideHeader(true);  
    // Before processing, clear existing cookies
    // this.storageService.clearStorage();
    // this.authService.clearPartnerSessionData();
    // this.storageService.storeItem(P_PARTNER_ACCOUNT, "true");

    this.route.paramMap.subscribe((params:any) => {
      const type = String(params.get('type'));
      const partnerApp = String(params.get('partnerApp'));
      const corporateId = String(params.get('cid'));
      const encrypted =  String(params.get('encrypted')); 
      // console.log(corporateId, encrypted);
      
      
      if(partnerApp === 'mobile') {
        this.storageService.clearStorage();
        this.storageService.storeItem(P_PARTNER_ACCOUNT, "true");
        this.storageService.storeItem(P_PARTNER_LOGIN_INPROGRESS, "true");
      }
      // this.outputText += ',partnerApp:'+partnerApp+'\n';
      // this.outputText += ',storage count:'+this.storageService.getStorageCount()+'\n';
      if(partnerApp === 'web') {
        this.authService.clearPartnerSessionData();
      }

      this.storageService.storeItem(P_PARTNER_ACCOUNT, "true");
      this.storageService.storeItem(P_PARTNER_LOGIN_INPROGRESS, "true");
      // this.outputText += ',partnerApp:'+partnerApp+'\n';
      // this.outputText += ',corporateId:'+corporateId+'\n';
      // this.outputText += ',encrypted:'+encrypted+'\n';
      setTimeout(()=>{
        this.processLogin(partnerApp, corporateId, encrypted, type);
      }, 3000);
      
    })
  }



  processLogin(partnerApp: string, corporateId:string, encrypted: string, type: string) {
    
    this.authService.partnerUserLogin2(partnerApp, corporateId, encrypted, type).subscribe((data:any) => {
      // console.log(data);
      if(data != null && data['status'] === true) {
        this.message1 = '';
        this.message2 = '';
        // console.log('success');
        this.isProcessing = false;
        // set PARTNER as true, and proceed further
        this.storageService.storeItem(P_PARTNER_APP, partnerApp)
        this.storageService.storeItem(P_PARTNER_ACCOUNT, "true");
        this.storageService.storeItem(P_IS_LOGGEDIN, "true");
        
        // this.storageService.storeItem(P_ENCRYPTED, encrypted);
        this.storageService.storeItem(P_PARTNER_LOGIN_INPROGRESS, "true");

        const user = data['user'];
        this.cunique_id = data['cunique_id'];
        this.user_id = user['email'];
        this.userSessionObj = {
          user: JSON.stringify(user), 
          name: data['name'], 
          token: data["token"], 
          user_type: data["user_type"],
          cunique_id: data['cunique_id'],
          haveSubscription: data['haveSubscription'],
          terms_privacy: data['terms_privacy'],
          subscription: (data['haveSubscription'] && data['subscriptions'].length > 0)  
              ? data['subscriptions'][0] 
              : {},

          linkedAccount: "CUID"
        }
        this.localStorageService.storePartnerUserInLocalStorage(this.userSessionObj);
        
        this.authService.showHeaderLinks();
        this.authService.hideNavMenu(true);
        // alert(data['terms_privacy']);
        // alert(typeof data['terms_privacy']);
        if(data['terms_privacy'] === "true") {
            this.authService.hideHeader(false);
            this.storageService.removeItem(P_PARTNER_LOGIN_INPROGRESS);
            this.router.navigate(['/user-home']); 
      //       this.outputText += ',success:\n';
      // this.outputText += ',storage count:'+this.storageService.getStorageCount()+'\n';

        }
        // this.router.navigate(['user-home']);  
      } else {
        this.intervalTimer = setInterval(() => {
          this.storageService.storeItem(P_PARTNER_LOGIN_INPROGRESS, "false");

          this.seconds = this.seconds - 1;
          // this.message = "Invalid link, please contact Yuva Health Team"+this.seconds;
          this.message1 = "Invalid link";
          if(this.seconds === 0) {
            clearInterval(this.intervalTimer);
            // this.storageService.clearStorage();
            this.storageService.storeItem(P_PARTNER_ACCOUNT_LOGIN_FAILED, "true");
          }
        }, 1000)
        
      }
      
    })
  }

  saveTermsPrivacy() {
    this.authService.saveTerms(this.user_id).subscribe((data:any) => {
      
      if(data != null && data['status'] === true) {
            this.authService.hideFooter(true);
            this.authService.hideHeader(false);
            this.storageService.removeItem(P_PARTNER_LOGIN_INPROGRESS);
            this.router.navigate(['/user-home']);
      }
    }, error => {
      // alert(JSON.stringify(error))
      console.log(JSON.stringify(error));
      
    })
  }


}
