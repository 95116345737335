<div class="sidebar-style-9" style="background:#fff">
    <div class="back-btn">
        <a (click)="goBack()" class="link" aria-label="Back"><i class='far fa-chevron-left'></i> Back</a>
    </div>
    <div class="section section-padding">
        <div class="container">
            <!-- search box -->
            <div class="sigma_banner-info style-2" style="margin-bottom: 20px;">
                <div class="container">
                    <div class="sigma_cta style-13">
                        <form [formGroup]="searchForm">
                            <div class="row no-gutters">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Search Care Provider</label>
                                        <input type="text" class="topic-field"
                                            placeholder="Search doctors, clinic, Hospitals etc."
                                            formControlName="first_param" required>
                                    </div>
                                </div>   
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Location</label>
                                        <div class="input-group">
                                            <i class="fal fa-map-marker-alt d-none d-sm-block"></i>
                                            <input type="text" class="location-field" placeholder="city"
                                                formControlName="location" required>
                                            <div class="input-group-append">
                                                <button type="submit" (click)="search()"> <i
                                                        class="fal fa-search mr-1"></i> <span id="mb">Find Now</span></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- search box -->
            <div class="row">
                <div class="col-lg-4">
                    <div class="sidebar mb-5">
                        <div class="widget widget-categories">
                            <h5 class="widget-title">Languages</h5>
                            <div style="max-height: 100px; overflow-y: auto;scrollbar-color: #b3d0c9 #ffffff;scrollbar-width: thin;">
                            <div class="form-check" *ngFor="let l of Languages;">
                                <input class="form-check-input" name="{{l}}" type="checkbox" (change)="onChange(l, $any($event.target).checked)" id="{{l}}">
                                <label class="form-check-label" for="{{l}}">
                                    {{l}}
                                </label>
                            </div>
                        </div>

                        </div>
                        <div class="widget">
                            <h5 class="widget-title">Consult</h5>
                            <input type="radio" name="gendorDoctor" value="ONLINE" id="genderDoctor" (click)="filterByMode($event)">
                            <label class="mb-0" for="genderDoctor">Online</label>
                            <input type="radio" name="gendorDoctor" value="OFFLINE" id="genderDoctor1" (click)="filterByMode($event)">
                            <label class="mb-0"  for="genderDoctor1">Offline</label>
                            <input type="radio" name="gendorDoctor" value="BOTH" id="genderDoctor2" (click)="filterByMode($event)">
                            <label class="mb-0" for="genderDoctor2">Both</label>
                        </div>
                        <div class="widget">
                            <h5 class="widget-title">Consultation Fees - (Rs)</h5>
                            <input type="checkbox" name="500" id="availabile" (change)="filterByFee(500, $any($event.target).checked)">
                            <label class="mb-0" for="availabile">Upto 500</label>
                            <input type="checkbox" name="501" id="unavailableDoctors" (change)="filterByFee(1000, $any($event.target).checked)">
                            <label class="mb-0" for="unavailableDoctors">501 - 1000</label>
                            <input type="checkbox" name="1001" id="unavailableDoctors1" (change)="filterByFee(1500, $any($event.target).checked)">
                            <label class="mb-0" for="unavailableDoctors1">1001 - 1500</label>
                            <input type="checkbox" name="1500" id="unavailableDoctors2" (change)="filterByFee(1501, $any($event.target).checked)">
                            <label class="mb-0" for="unavailableDoctors2">Above 1500</label>
                        </div>
						<!-- <div class="widget">
							<h5 class="widget-title">Years of Experience</h5>
                            <input type="range" class="form-range" id="customRange1" [min]="0" [max]="35">
                        </div> -->
                    </div>
                    
                </div>
                <div class="col-lg-8">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 text-center" *ngIf="loadingProfiles">
                            Loading Profiles...
                        </div>
                        <div class="col-lg-12 col-md-12 text-center" *ngIf="doctorblock.length === 0 && !loadingProfiles">
                            No Profiles found with Given search
                        </div>
                        <!-- Data -->
                        <div class="col-lg-6 col-md-6"
                            *ngFor="let item of doctorblock | paginate: { itemsPerPage: 20, currentPage: page } | filter: searchByKeyword">
                            <div class="sigma_team style-16" *ngIf="!loadingProfiles">
                                <div class="sigma_team-thumb">
                                    <img [src]="cpImagePath + item.picture" [alt]="item.first_name">
                                    <div class="sigma_team-controls">
                                        <!-- <a href="javascript:void(0)" [ngClass]="item.favorite ? 'active':''"
                                            (click)="favoriteTrigger(item)">
                                            <i class="fal fa-heart"></i>
                                        </a> -->
                                    </div>
                                </div>
                                <div class="sigma_team-body">
                                    <h5>
                                        <a>{{item.first_name}} {{item.last_name}}</a>
                                    </h5>
                                    <div class="sigma_team-categories">
                                        <a href="" *ngFor="let name of item.specializationsList; index as i">{{name.specialization_name}} {{i === item.specializationsList.length - 1 ? '' : ','}}</a>
                                </div>
                                    <div class="sigma_rating">
                                        <a>Consult-{{item.mode === 'BOTH' ? 'ONLINE/OFFLINE' : item.mode}}</a>
                                    </div>
                                    <div class="sigma_rating">
                                        <a >Consultation fee -  <span *ngIf="item.fee > 0">&#x20B9;</span>{{item.fee}}</a>
                                    </div>
                                    <div class="sigma_rating">
                                        <a>Experience - {{item.experience}}</a>
                                    </div>
                                    
                                
                                    <div class="sigma_team-info">
                                        <span>
                                            <i class="fal fa-map-marker-alt"></i>
                                            {{item.city}}
                                        </span>
                                        <a class="sigma_btn btn-block btn-sm" (click)="goToDetailsPage(item.cp_profile_id)">View
                                            More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Data -->
                    </div>
                    <!-- Pagination -->
                    <pagination-controls *ngIf="doctorblock.length > 0" (pageChange)="page = $event" previousLabel="<" nextLabel=">">
                    </pagination-controls>
                    <!-- Pagination -->
                </div>
            </div>
        </div>
    </div>
</div>