export const environment = {
    production: false,
    apiUrl: 'https://onmood9.com/test-env/ui-server/index.php/api/',
    BASE_URL: 'https://onmood9.com/test-env/ui-server/index.php/api/',
    MEDIA_PATH: 'https://onmood9.com/server/',
    CP_IMAGE_PATH: 'https://onmood9.com/test-env/admin-server/'

    // apiUrl: 'http://localhost/hostgator-mood9-ui-api/index.php/api/',
    // BASE_URL: 'http://localhost/hostgator-mood9-ui-api/index.php/api/',
    //  MEDIA_PATH: 'https://onmood9.com/hostgator-mood9-ui-api/',
    //  CP_IMAGE_PATH: 'https://onmood9.com/hostgator-mood9-ui-api/'
};

