import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GET_PAGE_META_DETAILS_URL } from '../constants/ApiEndPoints';
@Injectable({
  providedIn: 'root'
})
export class PageService {

  constructor(private httpClient: HttpClient) { }
  getPageMetaByName(name: string) {
		let url = GET_PAGE_META_DETAILS_URL.replace("{name}", ""+name)
		return this.httpClient.get(url);
	}
}
