import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ONMOOD9_COURSE_IMAGES_PATH } from 'src/app/constants/ApiEndPoints';
import { CarePlan } from 'src/app/model/care-plan';
import { ApiService } from 'src/app/services/api-service';
import { StorageService } from 'src/app/services/storage.service';
import { convertEmail } from 'src/app/Utils/StringUtil';

@Component({
  selector: 'app-old-care-plans',
  templateUrl: './old-care-plans.component.html',
  styleUrls: ['./old-care-plans.component.scss']
})
export class OldCarePlansComponent implements OnInit {
  currentUser: any;
  oldCarePlanList: Array<CarePlan> = [];
  carePlanList: Array<CarePlan> = [];
  oldcareplanlist:boolean = true;
  shownMyCarePlan:boolean = false;
  moduleImagePath = "";
  constructor(private route: ActivatedRoute, private storageService: StorageService, private router: Router, private apiService: ApiService) { 
    this.moduleImagePath = ONMOOD9_COURSE_IMAGES_PATH;

  }

  ngOnInit(): void {
    this.getMyOldCarePlan();
  }
  getMyOldCarePlan() {
    this.currentUser = this.storageService.getCurrentUser();
    convertEmail(this.currentUser.email)
    this.apiService.getMyOldCarePlans(convertEmail(this.currentUser.email)).subscribe((data) => {
      console.log(data);
      this.oldCarePlanList = data['oldCarePlanList'];
    });
  }
  
  goToMyCarePlan(item:any){
    console.log(item);
    this.oldcareplanlist = false;
    this.shownMyCarePlan = true;
    this.carePlanList = [item];
  }
  BackOldCarePlanList(){
    this.oldcareplanlist = true;
    this.shownMyCarePlan = false;
    this.getMyOldCarePlan();
  }
  goBack() {
    this.router.navigate(['/my-care-plan']);
  }
}
