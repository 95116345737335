<div style="margin-top: 70px;">
    <div class="container">
        <h2>
            Privacy Policy
        </h2>
        <p>
            This Privacy Policy informs you about our policies and practices regarding the collection and use (collects, uses, and discloses information about you when you access or use our websites, mobile application, and other online products and services and when you contact our customer service team, engage with us on social media, or interact with us) of your information and lets you know about options that you have to control or restrict the availability and use of your information.
        </p>
        <p> 
            We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of the policy and, in some cases, we may provide you with additional notice (such as adding a statement to our website homepage or sending you a notification). We encourage you to review the Privacy Policy whenever you access the Services or otherwise interact with us to stay informed about our information practices and the choices available to you.                
        </p>
        <!--<ol type = "1">
            <li *ngFor="let policy of policies">{{policy.title}}</li>
        </ol>-->
        <ol type = "1">
            <li><strong>What Personal Information Do We Collect About You?</strong>
                <ul>
                    <li>Account/Registration Information (such as user name and password, telephone number and email address)</li>
                    <li>Payment Information (such as name, billing address, and credit card information, collected when making a purchase)</li>
                  
                    <li>
                        Contact Information (such as name, phone number, postal address or email address)
                    </li> 
                    <li>
                        Demographic information (such as age, gender)
                    </li>
                    <li>
                        Online identifiers (such as IP address, internal and third party identifiers and device information like unique device ID)
                    </li>
                    <li>Location Data (like your address, city and zip code)</li>
                    <li>Internet Activity Information (such as browsing history and information on how you interact with our Services)</li>
                    <li>Publicly Available Information (such as your posts to a connected social networking community) </li>
                    <li>
                        Transactional Information When you make a purchase or return, we collect information about the transaction, such as product description, price, subscription or free trial expiration date, and time and date of the transaction.
                    </li>
                </ul>
            </li><br />
            <li><strong>Where/How Do We Collect Information About You?</strong> 
                <ul>
                    <li>From You Through the Services (e.g., when you create an account or connect to the Services through a connected social networking community)</li>
                    <li>From Your Device  From Cookies & Other Technologies (information stored on your browser, which tell us your browser, device information, IP address, and how you interact with the Services), including mobile “SDKs” (software development kits that operate like cookies in our mobile app).</li>
                    <li>From Others  Third party applications (if you enable interoperability</li>
                </ul>
            </li><br />
            <li><strong>How we use your Information</strong> 
                <ul>
                    <li>To provide the Services (to track and report to you on your progress and to provide you with newsletters and other information about features that may be of interest to you).</li>
                    <li>Periodically informing and recommending you about changes on our Services, and about new products and services;</li>
                    <li>Authenticating your login and processing payments;</li>
                    <li>Allowing you to monitor your meditation use and progress in Onmood9; for example, by presenting updated  of your meditation progress, for determining completion of sessions in courses and prerequisites for other courses, etc;</li>
                    <li>Performing statistical analyses of users of the site, and their viewing and participation patterns and for product development;</li>
                    <li>Customizing and delivering information about our events, Webinars, meditation courses and offerings by email;</li>
                    <li>To communicate with you, reminders, including to deliver ads and marketing communications to you, and to analyse the performance of advertising and marketing activities.</li>
                    <li>Providing customer support and sending confirmations about your account;</li>
                    <li>Protecting our intellectual property and other rights. We may use the information that we collect to prevent illegal activities, to enforce the Onmood9 Terms of Use, or as otherwise required by law; and</li>
                    <li>For our events, Assessments and trainings</li>

                </ul>
            </li><br />
            <li><strong>With whom we share your information</strong> 
                <ul>
                    <li>We employ contractors and service providers to perform the following site-related services: database management, maintenance services, analytics, marketing, and billing and email distribution. These third parties have access to your information only to perform these tasks on our behalf.</li>
                    <li>We may share information with third parties which may be the result of any mergers, acquisitions, asset sales, joint ventures, etc.</li>
                    <li>We share information with third parties when we have a good faith belief that such disclosure is reasonably necessary to (a) take action regarding suspected illegal activities; (b) enforce or apply our Terms of Service; (c) comply with legal process, such as a search warrant, subpoena, statute, or court order; or (d) protect our rights, reputation, and property, or that of our users, affiliates, or the public</li>
                </ul>
            </li><br />
            <li><strong>International data transfers</strong> 
                <p> If you choose to provide us with personal information, it will be stored in the United States and may be stored in other jurisdictions. Onmood9 may transfer that information to its affiliates and subsidiaries, across borders, and from your country or jurisdiction to other countries or jurisdictions around the world. If you are visiting the Services from the European Union (“EU”) or other regions with laws governing data collection and use, please note that your personal data is transferred to the United States and may be transferred to other jurisdictions. The United States does not and these other jurisdictions may not have the same data protection laws as the EU and may not afford many of the rights conferred upon data subjects in the EU. You acknowledge you understand that by providing your personal information: (i) your personal information will be used for the uses identified above in accordance with this Privacy Policy; and (ii) your personal information will be transferred to the United States and other jurisdictions as indicated above.</p>
            </li><br />
            <li><strong>Opting out of communications</strong> 
                <p>
                    We communicate with users who subscribe to our Services on a regular basis via email. For example, we may use your email address to send you information about changes to our services and other disclosures as required by law. Generally, users cannot opt-out of these communications, but they will be primarily informational in nature rather than promotional.
                </p>
                <p>
                    We do provide you with the opportunity to opt-out of marketing communications from us, such as emails or updates regarding new services we are offering, by contacting us at support@onmood9.com. We will process your unsubscribe as soon as possible, but please be aware that in some circumstances, you may receive a few more messages before the unsubscribe is processed. 
                </p>
            </li><br />
            <li><strong>Third party websites</strong> 
                <p>Our Services contain links to other sites. We are not responsible for the privacy practices or content of such other sites. If you have any questions about how these other sites use your information, you should review their policies and contact them directly</p>
            </li><br />
            <li><strong>Security and data retention</strong> 
                <p>
                    We have implemented reasonable measures to protect against unauthorized access to and unlawful interception or processing of personal information that we store and control. That said, please understand that no data transmissions over the Internet can be guaranteed to be 100% secure. Consequently, we cannot ensure or warrant the security of any information you transmit to us and you understand that any information that you transfer to us is done at your own risk.  If we learn of a security systems breach we may attempt to notify you electronically so that you can take appropriate protective steps. By using the Services or providing personal information to us, you agree that we can communicate with you electronically regarding security, privacy and administrative issues relating to your use of the Services. We may post a notice via the Services if a security breach occurs. We may also send an email to you at the email address you have provided to us in these circumstances. Depending on where you live, you may have a legal right to receive notice of a security breach in writing.
                </p>
                <p>
                    We will retain your personal information for the period necessary to fulfill the purposes outlined in this Privacy Statement unless a longer retention period is required or permitted by law
                </p>
            </li><br />
            <li><strong>Your Rights</strong> 
                <p>
                    You can request that your account be deleted at any time by contacting us. When your account is deleted, your public activities (comments, conversation topics) will be removed.
                </p>
                
                <h6>Additionally, residents of the EU have a right to:</h6>
                <ul>
                    <li>Obtain confirmation as to whether or not their personal information exists and to be informed of its content and source, verify its accuracy and request its completion, update or amendment;</li>
                    <li>Request the deletion, anonymization or restriction of the processing of their personal information processed in breach of the applicable law;</li>
                    <li>Object to the processing, in all cases, of their personal information for legitimate reasons;</li>
                    <li>Receive an electronic copy of their personal information, if they would like such a copy for themselves or to port their personal information to a different provider;
                        
                    </li>
                </ul>
                <h6>Your California privacy rights and how we respond to “do not track” signals</h6>
                <ul>
                    <li>California Civil Code Section 1798.83 permits visitors to the Services who are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. We do not provide your personal information to third parties for their marketing purposes.</li>
                    <li>At this time our Sites do not recognize automated browser signals regarding tracking mechanisms, which may include “Do Not Track” instructions.</li>
                </ul>

                <h6>Collection and use of information from children</h6>

                <p>We do not knowingly collect information from children under the age of 16. In the event that we learn that a person under the age of 16, has provided us with personal information, we will delete such personal information</p>

            </li><br />
            <li><strong>Contact us</strong> 
                <p>If you have any questions about our privacy practices or this Privacy Policy, please contact us at:</p>
                <p><a>support@onmood9.com</a></p>
            </li>
        </ol>
    </div>
</div>