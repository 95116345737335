export const P_CURRENT_USER = "P_CURRENT_USER";
export const P_CURRENT_USER_ID = "P_CURRENT_USER_ID";
export const P_UNAME = "P_UNAME";
export const P_TOKEN = "P_TOKEN";
export const P_IS_LOGGEDIN = "P_IS_LOGGEDIN";
export const P_ACTIVE_BLOGS = "P_ACTIVE_BLOGS";
export const P_SELECTED_BLOG = "P_SELECTED_BLOG";
export const P_USER_TYPE = "P_USER_TYPE";
export const P_CUNIQUE_ID = "P_CUNIQUE_ID";
export const P_CORP = "P_CORP";
export const P_CURRENT_SUBSCRIPTION = "P_CURRENT_SUBSCRIPTION";
export const P_HAVE_ACTIVE_SUBSCRIPTION = "P_HAVE_ACTIVE_SUBSCRIPTION";
export const P_SESSION_EXPIRES_ON = "P_SESSION_EXPIRES_ON";
export const P_LINKED_ACCOUNT = "P_LINKED_ACCOUNT";
export const P_HAS_MOOD_TRACKER_DATA = "P_HAS_MOOD_TRACKER_DATA";
export const P_PARTNER_ACCOUNT = "P_PARTNER_ACCOUNT";
export const P_PARTNER_ACCOUNT_RELOAD = "P_PARTNER_ACCOUNT_RELOAD";
export const P_PARTNER_LOGIN_INPROGRESS = "P_PARTNER_LOGIN_INPROGRESS";
export const P_PARTNER_ACCOUNT_LOGIN_FAILED = "P_PARTNER_ACCOUNT_LOGIN_FAILED";
export const P_HAVE_SUBSCRIPTION = "P_HAVE_SUBSCRIPTION";
export const P_PARTNER_HAS_SUBSCRIPTION = "P_PARTNER_HAS_SUBSCRIPTION";
export const P_PARTNER_TERMS_PRIVACY = "P_PARTNER_TERMS_PRIVACY";
export const P_ENCRYPTED = "P_ENCRYPTED";
export const P_PARTNER_APP = "P_PARTNER_APP";


export const PARTNER_SESSION_KEYS = [
    P_CURRENT_USER,
    P_UNAME,
    P_TOKEN,
    P_IS_LOGGEDIN,
    P_USER_TYPE,
    P_CUNIQUE_ID,
    P_PARTNER_TERMS_PRIVACY,
    P_HAVE_ACTIVE_SUBSCRIPTION,
    P_HAVE_SUBSCRIPTION,
    P_PARTNER_HAS_SUBSCRIPTION,
    P_CURRENT_SUBSCRIPTION,
    P_SESSION_EXPIRES_ON,
    P_HAS_MOOD_TRACKER_DATA,
    P_PARTNER_APP
  ]; 