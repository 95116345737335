import { Location } from '@angular/common';

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Specialization } from 'src/app/model/specialization';
import { ApiService } from 'src/app/services/api-service';
import { DoctorHelperService } from 'src/app/services/doctor-helper.service';
import { ProfileDataService } from 'src/app/services/profile-data.service';
import { filter } from 'rxjs/operators';
import { CP_IMAGE_PATH } from 'src/app/constants/ApiEndPoints';
@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {
  data: any;
  @Input() searchByKeyword: string;
  cpImagePath: string = "";
  
  doctorblock: any[] = [];
  filterdoctorblock: any[] = [];
  specializationList: Array<Specialization> = [];
  topic: string = '';
  location: string = '';
  page: number = 1;
  minValue: number = 0;
  maxValue: number = 50;
  category: any;
  modeForm!: FormGroup;
  selectLanguage: any;
  languageFormArray: Array<any> = [];
  feeFiltered: Array<any> = [];
  Languages: string[] = [
    'English', 'Telugu', 'Hindi', 'Marathi', 'Kannada', 'Tamil', 'Assamese', 'Gujarati', 'Bengali', 'Kashmiri', 'Malayalam', 'Odia', 'Sindhi', 'Panjabi'
  ];
  loadingProfiles: boolean = false;
  constructor(private route: Router, private router: ActivatedRoute,private Location: Location, public formBuilder: FormBuilder, private service: ProfileDataService, private apiService: ApiService) { 
    this.cpImagePath = CP_IMAGE_PATH;
  }

  goToDetailsPage(id: any) {
    // console.log(id);
    this.route.navigate(['/doctors-details', id]);
  }

  searchForm: FormGroup;
  
  onSubmit() {
    console.warn(this.searchForm.value);
    this.searchForm.reset();
  }

  ngOnInit(): void {
    this.router.paramMap.subscribe((params: any) => {
      this.topic = params.get(['topic']) === 'ALL' ? '' : params.get(['topic']);
      this.location = params.get(['location']) === 'ALL' ? '' : params.get(['location']);
    });

    this.searchForm = new FormGroup({
      first_param: new FormControl(this.topic, Validators.required),
      location: new FormControl(this.location, Validators.required)
    });

    if(this.topic !== '' || this.location !== '') this.search();

    this.apiService.getSpecialization().subscribe(res => {
      //console.log(res.data);
      this.specializationList = res.data;
    });
    // this.search();
  }
  goBack() {
    // console.log('back to page');
    this.Location.back();
  }
  updateDoctorBlock() {
    this.doctorblock.forEach(doctor => {
      doctor['picture'] = doctor.is_picture === 'false' ? 'cpimage.jpg' : doctor.cp_profile_id + '.jpg';
      // doctor['picture'] = doctor.cp_profile_id + '.jpeg';
      doctor['therapyList'] = doctor.cp_therapy_id?.split(',');
      doctor['serviceList'] = doctor.cp_service_id?.split(',');
      doctor['specializationsList'] = this.getNamesArray('specialization', doctor.cp_specialization_id);//.split(',');
      const exp = doctor?.experience.split('-');
      if (exp && exp.length > 0) {
        doctor['experience'] = exp[0] + ' Years ' + (exp[1] > 0 ?  exp[1]+' Months' : '');
      } else {
        doctor['experience'] = 'NA';
      }
    });
  }

  getNamesArray(type: string, idArray: any) {
    switch (type) {
      case 'specialization':
        return this.specializationList.filter((specialization) => idArray?.split(',').indexOf(specialization?.specialization_id) !== -1 );
      default:
        return null; 
    }
  }



  search(): void {
    this.loadingProfiles = true;
    this.doctorblock = [];
    //console.log(this.searchForm.value);
    this.apiService.searchProfile(this.searchForm.value).subscribe(res=>{
      //console.log(res);
      this.loadingProfiles = false;
      const searchDetails = res['profileDetals'];
      this.doctorblock = searchDetails;
      this.updateDoctorBlock();
    })
   }
  
  getSpecializationName(id: string, i: number, itemLength: number) {
    const name = this.specializationList.find(specialization => specialization?.specialization_id === id)?.specialization_name;
    return (i === itemLength - 1) ? name : name + ", ";
  }

  filterByMode(event: any) {
    const selectedValue = event.target.value;
    if(selectedValue === 'BOTH') {
      this.doctorblock = this.filterdoctorblock.filter(f => f.mode === "ONLINE" || f.mode === "OFFLINE");
    } else {
      this.doctorblock = this.filterdoctorblock.filter(f => f.mode === selectedValue);
    }
  }

  filterByFee(value: number, isChecked: boolean) {
    console.log(value, isChecked);
    // feeFiltered
    if (isChecked) {
      this.feeFiltered.push(value);
    } else {
      let index = this.feeFiltered.indexOf(value);
      this.feeFiltered.splice(index, 1);
    }
    console.log(Math.max(...this.feeFiltered));
    let maxFee = Math.max(...this.feeFiltered);
    if(maxFee === 1501) {
      maxFee = 1000000;
    }
    if(this.feeFiltered.length > 0) {
      this.doctorblock = this.filterdoctorblock.filter(f => Number(f.fee) <= maxFee);
    } else {
      this.doctorblock = this.filterdoctorblock;
    }
    console.log(this.doctorblock.length);
    
  }

  onChange(slectedLanaguage: string, isChecked: boolean) {
    if (isChecked) {
      this.languageFormArray.push(slectedLanaguage);
     const filterBy = this.languageFormArray,
     result = this.filterdoctorblock.filter(el => filterBy.includes(el.languages));
      console.log(result);
      console.log(this.languageFormArray);
    } else {
      let index = this.languageFormArray.indexOf(slectedLanaguage);
      this.languageFormArray.splice(index, 1);
      const filterBy = this.languageFormArray,
     result = this.filterdoctorblock.filter(el => filterBy.includes(el.languages));
     
      console.log(this.languageFormArray);
      console.log(result);

    }
  }


  // ofChange(data:any){
  //   data.checked = !data.checked;
  //   let checkedValues = this.doctorblock.filter(x => x.checked).map(x => x.languages);
  //   console.log(checkedValues);
  // }


}
