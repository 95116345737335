<div class="main-wrapper">
    <div class="container">
        <div class="text-center mt-5">
            <span class="module-name">My Favourites</span>
        </div>
        <div class="row mt-5">
            <div class="row mt-5">
                <!-- <div *ngFor="let item of friendslist" class="col-sm-6 col-md-4 col-lg-3 text-center mb-4">
                        <div class="prod-img assess-img"><img src="assets/img/small-img.png">
                        </div>
                        <div class="learn-video mt-3"><a href="">Simple Text</a></div>
                    </div> -->
                    
                
                <div *ngFor="let module of favouriteModules" class="col-md-3 col-6 text-center mb-4"
                [routerLink]="module.sessionLinkAvailable ? [module.sessionsLink] : []">
                    <div class="prod-img assess-img">
                        <img [src]="moduleImagePath + module.module_image" alt="">
                    </div>
                    <div class="learn-video mt-3"><a href="">{{module.module_name}}</a></div>
                </div>
            </div>
        </div>
    </div>
</div>