<div style="margin-top: 70px;">
    <div class="container">
        <h2>
            Terms & Conditions 
        </h2>
        <ol type = "1">
            <li>
                <strong>About Us</strong>
                <p>These Terms & Conditions (these “Terms”) contain the terms and conditions on which we supply content, products or services listed on www.onmood9.com (the “Website”), through our applications (the “Apps”) or via other delivery methods (Including Social Media platforms) to you (the Website and such content, products, services and the Apps are collectively referred to herein as the “Product” or “Products”, which may be updated from time-to-time at the sole discretion of Onmood9). Please read these terms and conditions, carefully before ordering any Products from the Website or third-party App stores (e.g., the Apple App Store, the Android Play Store, Amazon, etc.). The terms “Onmood9,” “us” or “we” refers to, is a proprietary firm On Mood 9 Based out of Hyderabad India, term “Device” refers to the device which is used to access the Products including but not limited to computers, smart phones and tablets. The term “you” refers to the user of the Products. When you order (“Order”) any Products, or otherwise use or access the Products, you agree to be bound by these Terms and all applicable laws, rules and regulations. You may also be asked to click “I accept” at the appropriate place prior to your purchase of access to the Products. At such time, if you do not click “I accept”, you may not be able to complete such purchase or gain such access. By using the Products, you indicate that you accept these Terms and that you agree to abide by them. If you do not agree to these Terms, please refrain from using the Products.</p>
                <p>Our contact email address is support@onmood9.com. All correspondence to Onmood9 including any queries you may have regarding your use of the Products or these Terms should be sent to this contact email address.</p>
            </li>


            <li>
                <strong>About Our Services</strong>
                <p>We believe that the regular practice of mindfulness and compassion is the key to emotional wellness. Onmood9 provides Products to help improve your emotional wellness, every day. We deliver to users (“Personal and Partners”) meditation and emotional wellness products via the Onmood9 web portal and mobile applications, the Onmood9 website (currently located at www.onmood9.com), and through any other platform or media through which we may make such Products and services available (the “Products”).</p>
            </li>


            <li>
                <strong>About These Terms of Service</strong>
                <p>These Onmood9 Terms of Service (“Terms”) are legally binding terms for your use of the Services. You should also review Onmood9 Privacy Policy, which contains important information about how we use your data. By accessing and using the Product, whether on a free or subscription basis, you agree to comply with and be bound by these Terms of Service, whether you have registered and created an account with Onmood9 or through one of Onmood9’s connected social networking communities (“Partner Communities”), or you are viewing Onmood9 content without having an account, including as a plug-in to another service. Onmood9 reserves the right, at its sole discretion, to modify, discontinue, or terminate the Services at any time and without prior notice. If you do not agree to these Terms of Service, you should not access or use the Services.</p>
            </li>


            <li>
                <strong>Changes to These Terms of Service</strong>
                <p>Onmood9 may change these Terms of Service from time to time. We will usually inform you of any changes by posting the updated Terms of Service through the mobile applications and on the Onmood9 website. If we make any material changes to these Terms of Service, we will post on the Onmood9 website and, if you are registered, notify you via your preferred means of contact for such notices (e-mail or text message) and/or by asking you to review the changes to these Terms of Service the next time you access the Services. Your continued use of the Services after the time the changes are communicated will indicate your acceptance of the Terms of Service, including those changes.</p>
            </li>


            <li>
                <strong>Your Account</strong>
                <p><strong>Eligibility.</strong> By creating an account, you represent and warrant that:
                    <p>(i) all required registration information you submit, whether through Onmood9 or through a Partner Community, is truthful and accurate; </p>
                     <p>(ii) you will maintain the accuracy of such information; and</p>
                    <p> (iii) if you are a subscriber, your account will be used only by the subscriber, and will not be shared with others outside of the same household.</p>
                    <p> (iv) You must be 18 years of age, or the age of majority in your province, territory or country, to sign up as a registered user of the Products. Individuals under the age of 18, or the applicable age of majority, may utilize the Products only with the involvement and consent of a parent or legal guardian, under such person's account and otherwise subject to these Terms.</p>
                     <br>
                    <p><strong>Membership Type - </strong>
                  As a Onmood9 Member you will receive access to certain sections, features and functions of the Products that are not available to non-members</p>

                <p>There are two types of Memberships one is</p>
                <p>(i) Premium Membership (Paid) – These members will have access to paid content which may keep changing from time to time as per onmood9 decision.</p>
                <p>(ii) Free Membership (Unpaid) – They will have access to free content which may keep changing from time to time as per the Onmood9 decision.</p>
                <p>By agreeing to become a member you may receive occasional special offer, marketing, and survey communication emails with respect to the Product. You can easily unsubscribe from Onmood9 commercial emails by following the opt-out instruction in these emails.</p>
                <p>Onmood9 membership accounts and subscriptions are not transferable and therefore you agree to not sell, transfer, or exchange membership accounts or subscriptions in any way or under any circumstance. This expressly applies to any discounted, subsidized, or free accounts or subscriptions.</p>
                <p>By registering for a Onmood9 account, the Products, you warrant that:</p>
                <p>(i) You are legally capable of entering into binding contracts; </p>
                <p>(ii) All registration information you submit is truthful and accurate; </p>
                <p>(iii) You will maintain the accuracy of such information; and </p>
                <p> (iv) Your use of the Products does not violate any applicable law or regulation.</p>
                
                <p><strong>Your password.</strong>When you register, you will be asked to choose a username and a password as per the password policy of Onmood9. You are responsible for maintaining the confidentiality of your password and all use of your Onmood9 account. You can change the password whenever you want. You agree not to use the account, username, or password of another User. You agree to notify us immediately if you suspect any unauthorized use of your Onmood9 account or access to your password. </p>
                <p> <strong>Deactivation of account & information retention.</strong> Onmood9 may restrict, deactivate or terminate your access to the Services or your Onmood9 account if Onmood9 believes you are in breach of these Terms of Service, you understand that deactivation of your Onmood9 account by you or by us may remove information from our live databases, but that our servers may maintain certain information for archival or legal purposes.</p>
            </li>


            <li>
                <strong> Subscriptions, Account type, Payments & Fees</strong>
                <p>Certain features of the Onmood9 Service may require a subscription which may be charged in advance or periodically, according to the plan you selected when you began your subscription (the “Subscription Products”). Other features may allow for purchases of premium Content. Your payment may be charged directly by Onmood9 through its payment processor, or through your mobile application provider, in which case you should look to the provider’s terms regarding in –app payments.</p>
                <p><strong>Subscriptions -</strong></p>
                    <p>(i) Free: Free Member gets access to free content on Onmood9.</p> 	 
                    <p>(ii) Trial Subscription: trial program gives unlimited access to member for defined month to all the content  </p>
                   <p>(iii)	Paid Subscription: gives access to all content (Products and Services Covered in the plan) including the Free Products</p>
                <p><strong>Account Type -</strong></p>
                <p><strong>i) Personal</strong> You may access Onmood9 Products in the following ways: by purchasing a subscription to the Products from the Website, within the Apps, where allowed by the App marketplace partners, or through a bundle with one or more of our bundle subscription partners. </p>

                <p>You may access Onmood9 Individual Subscription via a monthly or annual subscription option. For the purposes of our monthly, Quarterly and yearly subscriptions, a month constitutes 30 calendar days, Quarterly Consists of 120 Days and a year constitutes 365 calendar days. These conditions are subject to change based on decision taken by onmood9 you will be notified about any changes in personal subscription conditions by the company. </p>
                <p>If you purchase Subscription Services, you agree that your subscription is personal to you and the members of your household, and you may not share your account and password with others. Any such sharing by you of your account and password is a violation of these Terms of Service and may result in the cancellation of your subscription without refund. Most subscriptions will automatically renew at the end of the subscription period with a charge to the credit card that Onmood9 has on file for you, or through the payment provider you have chosen.</p>
                <p> For an annual subscription, you will be notified in advance of that renewal. For all subscription plans, if you do not wish your subscription to renew, you may cancel at any time through your account. Onmood9  will not refund any unused or inadvertently renewed subscriptions, and it is always within Onmood9 sole discretion whether to grant any refunds</p>
                <p>Please note that if you purchase a subscription through the Apple iTunes Store or our iPhone application, the sale is final, and we will not provide a refund. Your purchase will be subject to Apple’s applicable payment policy, which also may not provide for refunds. If you purchase a subscription through the Google Play store, the sale is final and we will not provide a refund. Your purchase will be subject to Google’s applicable payment policy, which also may not provide for refunds. If you purchase through one or more of our bundle subscription partners, the purchase may be further subject to the Terms and Conditions of such partners, and payment and management of the bundle subscription may be administered by them.</p>

                <p><strong>ii) Partner -</strong> You may receive access to Onmood9 via your Corporate or Community (as defined and further outlined below). In such instance, you may still be required to provide Your payment information in order to access Onmood9.</p>

                <p>Corporates, groups, businesses and communities (corporations, Companies, universities, Wellness companies, Health Insurance agents, hospitals, etc.) (“Community” or “Communities”) purchase and introduce the Products to their employees and members.  Partner Members will get the account on behalf of their employer or group and the Partner will have access to all the user data and will decide the membership type and other terms of use. </p>
                <p>In some cases, these Communities may supplement these Terms with their own terms and conditions, which may include additional terms around subscription redemption, usage or supplementary payment for Onmood9 access. In such event, these Community terms and conditions shall also apply to your use of the Products. In the event of any conflict with such additional terms and these Terms, the additional terms shall prevail.</p>
                
                <br>
                <strong>Payments -</strong>
                <p> When you pay for a subscription or purchase premium Content, Onmood9 or third party will take your credit card or other payment information and billing address and will collect and use such information in accordance with the Onmood9 Privacy Policy. If your designated payment method is refused or returned, you acknowledge that Onmood9 may immediately cancel your subscription. Prices and payments are in Indian and United States currency only, and you are responsible for paying any applicable taxes on the subscription, unless stated otherwise. We reserve the right to change fees by publication in the Onmood9 Products and Services and any new subscription fees will apply after the end of your current subscription period.</p>

                <strong> Fees -</strong>

                <p> <strong>Price -</strong>  Onmood9 is available in different geographies and the pricing for the product and services may vary in different countries and will be charged in different currencies as required.</p>
                <p><strong>Discounts and Coupons – </strong>Onmood9 issues various discounts and discount coupons from time to time. Onmood9 has the right to refuse the usage of any voucher at any point of time. coupons which have expired cannot be used. Onmood9 can at any time ask the user for the proof of having received the coupon. Coupons can be used only on specific orders as deemed fit by onmood9. Applicability of coupons on certain items, on specific orders, or on specific dates or occasions or locations are at the discretion of the Company. Vouchers cannot be clubbed with any other special offer on onmood9, unless expressly otherwise stated. The coupons cannot be transferred to any other User.</p>
                <p><strong>Changing Price and charges - Onmood9 </strong>reserve the right to change our subscription plans or adjust pricing for our service or any components thereof in any manner and at any time as we may determine in our sole and absolute discretion. Except as otherwise expressly provided for in these Terms, any price changes or changes to your subscription plan will take effect following notice to you.</p>
            </li>


            <li>
            <strong> Products offered by Onmood9 </strong> 
            <p> Materials offered through the products are Content and Services </p>

            <p><strong>Content -</strong>The Product contain different types of Content Onmood9 Content, Third Party Content and User Content. In all cases, “Content” means any and all text, images, photos, videos, Audios, sounds, postings, messages, recommendations, comments, files, feedback, or other materials and works of authorship. Each type of Content that you encounter has different rules and restrictions, as set forth below: </p>

            <p><strong>a.</strong>Onmood9 content and ownership. As between Onmood9 and you, Onmood9 owns or controls all right, title and interest in the Services and in the Content included within the Services, including its logos, trademarks and all other Intellectual Property Rights it controls (“Onmood9 Content”).</p>
            <p><strong>b.</strong>Third party content. Content from third parties may be displayed to you through and as part of the Services (“Third Party Content”). We do not exercise control over Third Party Content and you agree that we are not responsible for any Third-Party Content.</p>
            <p><strong>c.</strong>User content and interactions with other users. We do not claim ownership in any content that you may add to, post on, deliver to, or otherwise make available to the Services through your comments or other features of the Services that may allow you to post or share your own Content (“User Content”) and you may choose to take down Content that you have posted. By posting User Content, you hereby grant to us an unrestricted, irrevocable, perpetual, non-exclusive, fully-paid and royalty-free license (with the right to sublicense through unlimited levels of sublicensees) to use, copy, perform, display and distribute your User Content in any and all media or distribution method (now known or later developed) throughout the world. The licenses granted under this Section will survive the termination or expiration of these Terms of Service for any reason. You may not post a photograph or video of another person without that person’s permission. You are solely responsible for any and all User Content that you post on the Services and for your interactions with other Users.</p>
            <p>You represent and warrant that: </p>

            <p>(i) You own the User Content posted by you on the Service or otherwise have the right to grant the licenses set forth in these Terms of Service, and </p>
            <p>(ii) Your User Content and the use of it by Onmood9 as permitted by the license granted in these Terms of Service does not violate the privacy rights, publicity rights, Intellectual Property Rights, or other rights of any person.</p>
            <p><strong>d.</strong>Content restrictions. You agree not to</p>

            <p>(i) Modify, copy, publish, display, transmit, distribute, perform, sell or create derivative works based on the Content and Services; </p>

            <p>(ii) Rent, lease, loan or sell access to the Services; or </p>

            <p>(iii) Decompile, reverse engineer or copy any Content (other than the Content you post) or the Services. You also agree to not remove, obscure or alter Onmood9  or a third party’s copyright notice, logos, trademarks or other proprietary rights notices affixed to or contained within the Services. You will not interfere with, disrupt or create an undue burden on the Services or the networks or services connected to the Services.</p>

            <p><strong>e.</strong>. Prohibited content. You agree that you will not post or share through the Services any Prohibited Content. “Prohibited Content” includes, without limitation, Content that: </p>


            <p>(i) Is offensive or promotes racism, bigotry, hatred or physical harm of any kind;</p>
            <p> (ii) Harasses or advocates harassment of another person; </p>
             <p> (iii) Involves the transmission of “junk mail,” “chain letters,” unsolicited mass mailing or “spamming”; </p>
              <p>(iv) Is false, misleading, libellous, defamatory or promotes, endorses or furthers illegal activities;  </p>
              <p>(v) Infringes the Intellectual Property Rights of another person; </p>
             <p>(vi) Is involved in the exploitation of persons under the age of eighteen (18) in a sexual or violent manner, or solicits personal information from anyone under thirteen (13);</p>
             <p>(vii) Contains viruses, Trojan horses, worms, time bombs, cancelbots, corrupted files, spyware, malware or other harmful content or code; or </p>
             <p>(viii) Otherwise violates applicable law, the terms of these Terms of Service or potentially creates liability for Onmood9. </p>
             <p>Any accounts posting, hosting or recommending Prohibited Content are subject to termination. We reserve the right to remove Content or links to Content that we determine in our sole discretion is unlawful, fraudulent, threatening, libellous, defamatory, obscene or otherwise objectionable. We also reserve the right to remove Content that we determine in our sole discretion is primarily intended to promote a product or service. We have the right (but not the obligation) to investigate or review any Content and delete (or modify) any Content that in our sole judgment violates these Terms of Service or which is Prohibited Content, or may otherwise violate the rights, harm or threaten the safety of any User or any other person, or create potential liability for us or any User. </p>
             <p><strong>Service - </strong> Product contains Services which include Webinars, E-mailers, Online support for any questions related to Meditation, emotional wellbeing and related to content. </p>
           

            </li>

            <li>
                <strong>Disclaimer of Warranties </strong>

                <p><strong>Products disclaimer</strong></p>
                <p>The information contained in the Products is for general information purposes only. While we endeavour to keep the information up-to-date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the Products or the information contained on the Products for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>

                <p><strong>Medical disclaimer</strong></p>

                <p>Onmood9 is a provider of online and mobile meditation, mindfulness, Contemplation, Music, sleep and exercise content in the health & wellness space. We are not a health care or medical device provider, nor should our Products be considered medical advice. Only your physician or other health care provider can do that. While there is third party evidence from research that meditation can assist in the prevention and recovery process for a wide array of conditions as well as in improving some performance and relationship issues, Onmood9space makes no claims, representations or guarantees that the Products provide a physical or therapeutic benefit.</p>

                <p>i)  	Any health information and links on the Products, whether provided by Onmood9 or by contract from outside providers, is provided simply for your convenience. </p>
                <p>ii)	Assessment scales – All the emotional and mood self-assessment scales available on Onmood9 platform are based on freely available assessment content and don’t claim to be a clinical tool to do Assessment of mental health or disorder. So please consult a licensed physician, Mental Health expert or psychologist for any clinical assessment or help   </p>
                <p>iii)	You should consult a licensed physician prior to beginning or modifying any exercise or meditation practice that you undertake on Onmood9 , especially if you have a prior injury, a history of heart disease, high blood pressure, other chronic illness, or mental health condition. You acknowledge that Onmood9 has advised you of the necessity of doing so. </p>
                <p>iv)	Any advice in the Product is intended for general information purposes only. They are not intended to be relied upon and are not a substitute for professional medical advice based on your individual condition and circumstances. We are not liable or responsible for any consequences of your having read or been told about such advice as you assume full responsibility for your decisions and actions. In particular, to the fullest extent permitted by law, we make no representation or warranties about the accuracy, completeness, or suitability for any purpose of the advice, other materials and information published as part of the Products. </p>
                <p>v)   Patients with psychiatric problems like anxiety and depression or People with existing mental health conditions should speak with their health care providers before starting a meditation practice. </p>
            </li>


            <li>
                <strong>Limitation of Liability</strong>

                <p>Under no circumstances shall Onmood9 be liable to any User in connection with that User’s use or misuse of the Content or the Services. Such limitation of liability shall apply to prevent recovery of indirect, incidental, consequential, special, exemplary, and punitive damages whether such claim is based on warranty, contract, tort (including negligence), or otherwise (even if Onmood9 has been advised of the possibility of such damages). This limitation of liability shall apply whether the damages arise from use or misuse of and reliance on the Content or Services, from inability to use the Content or Services, from any claim relating to the user of the Content or the Services, or from the interruption, suspension, or termination of the Content or Services. Some states or other jurisdictions do not allow the exclusion or limitation of liability for incidental or consequential damages, so the above limitations and exclusions may not apply to you.</p>

            </li>


             <li>
               <strong> Release</strong>

               <p>You hereby release us, our officers, employees, investors, agents and successors from claims, demands, any and all losses, damages, rights, claims, and actions of any kind including, without limitation, personal injuries, emotional distress, death and property damage, that is either directly or indirectly related to or arises from </p>

               <p>(i) Any interaction you have with other Users,</p>
               <p>(ii) Your use of the Services or </p>
               <p>(iii) Your participation in any offline events. If you are a California resident, you hereby waive </p>
               <p>California Civil Code Section 1542, which states: “A general release does not extend to claims which the creditor does not know or suspect to exist in his favour at the time of executing the release, which, if known by him must have materially affected his settlement with the debtor.”</p>
                </li>

                <li>
                    <strong> Indemnity</strong>
                    <p>You agree to defend, indemnify and hold Onmood9, their officers, directors, employees and agents, harmless from and against any claims, liabilities, damages, losses and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of the Content or Services or your violation of these Terms of Service.</p>

                </li>

                <li>
                    <strong>Responsibility</strong>

                    <p> Your interaction with other Users on the Services or with third parties, including any payment or delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and the other User, advertiser or third party. You agree that Onmood9 will not be responsible for any loss or damage incurred as the result of any such dealings or with respect to any third party’s use or disclosure of your personal information that you make available to them. If there is a dispute between you and any third party (including any User), we are under no obligation to become involved and you agree not to involve us unless we request otherwise in writing; however, we reserve the right, but have no obligation, to monitor disputes between you and other Users or third parties </p>
                </li>
                
                <li>
                    <strong>Copyright, Trademark & Intellectual Property </strong>

                    <p><strong>(a)</strong>All materials (including software, codes, all information available thereon, images, videos, logos, trade names, domain names, service marks and other materials on it, including the look and feel and content whether downloaded or not) contained in the Products are owned by Onmood9 (or our affiliates and/or third-party licensors, where applicable), unless indicated otherwise. You agree and acknowledge that the materials are valuable property and that other than any specific and limited license for use of such materials, you shall not acquire any ownership rights in or to such materials. The materials may not be used except as provided for in these Terms, and any other relevant terms and conditions provided to you without our prior written permission.</p>
                    <p><strong>(b)</strong>You acknowledge and agree that certain materials on or in the Products are the property of third-party licensors and, without prejudice to any and all other rights and remedies available, each such licensor has the right to directly enforce relevant provisions against you.</p>
                    <p><strong>(c)</strong>Audio or video content from Onmood9 not explicitly indicated as downloadable may not be downloaded or copied from the Products or any Device.</p>

                    <p><strong>(d)</strong>The Products are not intended for your commercial use. Commercial advertisements, affiliate links, and other forms of solicitation may be removed by us without notice and may result in termination of privileges. You must not use any part of the materials used in or on the Products for commercial purposes without obtaining a written license to do so from us. Material from the Products may not be copied or distributed, or republished, or transmitted in any way, without our prior written consent. Any unauthorized use or violation of these Terms immediately and automatically terminates your right to use the Products and may subject you to legal liability. You agree not to use the Products for illegal purposes (including, without limitation, unlawful, harassing, libellous, invasion of another’s privacy, abusive, threatening or obscene purposes) and you agree that you will comply with all laws, rules and regulations related to your use of the Products. Appropriate legal action may be taken for any illegal or unauthorized use of the Products.</p>
                    <p><strong>(e)</strong>A limited amount of content may be marked and authorized for the user to share in their personal social channels (Facebook, Twitter, etc.). With respect to content made available by Onmood9 through the Products that is specifically identified as available for distribution by you as part of your blog or other online Podcast, Onmood9 grants you a limited right to download, reproduce and distribute Distribution Content over the internet. You agree not to publish the Distribution Content with other content that is known by you to be false, inaccurate, or misleading or that is, or that encourages activity or conduct that is, unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, pornographic, libellous, invasive of another’s privacy, hateful, or racially, ethnically or otherwise objectionable. Distribution Content may contain trackers that enable us to collect information with respect to the distribution and consumption of such content.</p>
                    <<p><strong>(f)</strong>You may not otherwise download, display, copy, reproduce, distribute, modify, perform, transfer, create derivative works from, sell or otherwise exploit any content, code, data or materials in the Products. If you make other use of the Products, or the content, code, data or materials thereon, except as otherwise provided above, you may violate copyright and other laws of India and other countries, as well as applicable state laws and may be subject to liability for such unauthorized use. Onmood9space will enforce its intellectual property rights to the fullest extent of the law, including the seeking of criminal prosecution.</p>
                    <p><strong>(g)</strong> Onmood9 respects the intellectual property rights of others, If the any company believes that any material on Onmood9 infringes upon their copyright, they may notify the Company through an email at support@onmood9.com.</p>

                </li>

                <li>
                    <strong>Third Party Services and Partner Communities</strong>

                    <p>The Services may contain links to third party applications, websites or services, and also may be accessed via a plug-in through third party applications, websites or services (“Third Party Services”). We may also enable sharing and interoperability to Third Party Services, Partner Communities and other Users or use other means that allow you to connect the Onmood9 Services to Third Party Services, Partner Communities and other Users. You use all such connections to Third Party Services and Partner Communities at your own risk. To the extent such links are provided by us, they are provided only as a convenience and do not imply our endorsement of or affiliation with such Third-Party Service or Partner Community. While we will always strive to select the best partners, we accept no responsibility for the quality, content, policies or reliability of Third-Party Services or Partner Communities or for links back to the Onmood9 Services. You should review applicable terms and policies, including privacy and data gathering practices, for any Third-Party Services or Partner Communities and should make whatever investigation you feel necessary or appropriate and choose appropriate settings for sharing your personal information before proceeding with any interaction with a Third-Party Service or Partner Community.</p>
                
                </li>

                <li>
                     <strong>Initial Resolution of Questions or Disputes</strong>
                     
                     <p>If you have any questions or concerns about the Services or these Terms of Services, we strongly encourage you to first contact us support@onmood9.com directly.</p>

                </li>


                <li>
                    <strong>Arbitration</strong>

                    <p>For any claim where the total amount of the award sought is less than 10,000 INR (excluding claims for injunctive or other equitable relief), either party may elect to resolve the dispute in a cost effective manner through binding non-appearance-based arbitration. In the event a party elects’ arbitration, they shall initiate such arbitration through an established alternative dispute resolution (“ADR”) provider mutually agreed upon by the parties. The ADR provider and the parties must comply with the following rules: </p>
                       <p> (i) The arbitration shall be conducted by telephone, online and/or be solely based on written submissions, the specific manner shall be chosen by the party initiating the arbitration; </p>
                        <p>(ii) The arbitration shall not involve any personal appearance by the parties or witnesses unless otherwise mutually agreed by the parties; and </p>
                       <p> (iii) Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. Any dispute resolution proceedings, whether in arbitration or court, will be conducted only on an individual basis and not in a class or representative action or as a named or unnamed member in a class, consolidated, representative or private attorney general legal action.</p>
                        

                </li>

                <li>
                     <strong> Force majeure</strong>

                     <p>We will not be liable to you for any lack of performance, or the unavailability or failure, of the Products, or for any failure or delay by us to comply with these Terms, where such lack, unavailability or failure arises from any cause beyond our reasonable control.</p>
                </li>

                <li>
                    <strong>Notices</strong>

                    <p>Unless otherwise specifically indicated, all notices given by you to us must be given to Onmood9 at support@onmood9.com. We may give notice to you at the e-mail address you provide to us when you register, or in any of the ways specified above. Notice will be deemed received and properly served immediately when posted on the Products or when an e-mail or other electronic communication is sent. In proving the service of any notice via email, it will be sufficient to prove that such e-mail was sent to the specified e-mail address of the addressee.</p>
                </li>

                <li>

                    <strong>Entire agreement</strong>

                    <p>These Terms and any document expressly referred to in them constitute the whole agreement between us and supersede all previous discussions, correspondence, negotiations, previous arrangement, understanding or agreement between us relating to their subject matter. We each acknowledge that neither of us relies on, or will have any remedies in respect of, any representation or warranty (whether made innocently or negligently) that is not set out in these Terms or the documents referred to in them. Each of us agrees that our only liability in respect of those representations and warranties that are set out in this agreement (whether made innocently or negligently) will be for breach of contract. Nothing in this section limits or excludes any liability for fraud.</p>

                </li>

                <li>
                    <strong>Our liability</strong>
                     <p> <strong>(a)</strong>We will use reasonable endeavours to remedy faults in the Products. If we fail to comply with these Terms, we will be liable to you only for the purchase price of the Products in question. In addition, we will not be liable for:</p>
                     <p>(i) Faulty operation of computers during the registration process or during completion of a subscription or during the transmission of any data and/or for incorrect or overly slow transmission of data by the internet provider and/or any damage that occurs due to information submitted by you not being received by us or not being received promptly or not being considered, as a consequence of technical faults with our software or hardware (whether or not they are within or outside of our control).</p>
                     <p>(ii) Any loss or damage due to viruses or other malicious software that may infect your Device, computer equipment, software, data or other property caused by you accessing, using or downloading from the Products, or from transmissions via emails or attachments received from us.</p>
                    <p>(iii) Any use of websites linked to the Products but operated by third parties.</p>

                    <p><strong>(b)</strong> To the extent permitted by law, onmood9 and its affiliates, suppliers, clients, or licensors (collectively, the “Protected Entities”) shall not be liable for any consequential, exemplary or punitive damages arising from, or directly or indirectly related to, the use of, or the inability to use, the Products or the content, materials and functions related thereto, your provision of information via the Products, or lost business or lost sales, or any errors, viruses or bugs contained in the Products, even if such Protected Entity has been advised of the possibility of such damages. In no event shall the Protected Entities be liable for or in connection with any content posted, transmitted, exchanged or received by or on behalf of any user or other person on or through the Products. In no event shall the total aggregate liability of the Protected Entities to you for all damages, losses, and causes of action (whether in contract or tort, including, but not limited to, negligence or otherwise) arising from these terms of use or your use of the Products exceed, in the aggregate, the amount, if any, paid by you to Onmood9 for your use of the Products. </p>
                </li>

                
                <li>
                    <strong>Exclusive venue</strong>

                    <p>To the extent the parties are permitted under these Terms to initiate litigation in a court, both you and Onmood9 agree that all claims and disputes arising out of or relating to the Terms or the use of the Products will be litigated exclusively in the Telangana High Court, India. If, however, that court would lack original jurisdiction over the litigation, then all claims and disputes arising out of or relating to the Terms or the use of the Products will be litigated exclusively in the Telangana High Court. You and Onmood9 consent to the personal jurisdiction of both courts. </p>

                </li>

                <li>
                    <strong>Law and Jurisdiction</strong>
                    <p> These terms of service and any action related there to will be governed and interpreted in accordance with the laws of Republic of India. </p>
                    <p> Any disputes arising out of or relating to these Terms or their subject matter, including tort claims, without giving effect to any conflict of law or other principles that provide for the application of the law of another jurisdiction, will be resolved exclusively in the courts of law at Hyderabad, Telangana. The United Nations convention on contracts for the international sale of goods does not apply to this agreement.</p>
                    <p>The Company shall also have the right to hold you or such other person liable for the damages, losses and expenses which the Company may incur and initiate proceedings in any appropriate courts or other forums for the material breach of these Terms by You.</p>

                </li>

                <li>

                    <strong>Severability</strong>

                    <p>If any provision of these Terms is found unenforceable, then that provision will be severed from these Terms and not affect the validity and enforceability of any remaining provisions. </p>
                    <p>These Terms are effective and were last updated on Jan 25th, 2023.</p>

                    <p> Onmood9, Gachibowli, Hyderabad, India 500032 </p>
                </li>
          </ol>
    </div>
</div>