import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs/index';
import { MusicSingle } from "../model/music-single";
import { BgMusic } from "../model/bg-music";
import { GET_ACTIVE_MUSIC_SINGLES_URL, GET_ACTIVE_BACKGROUND_MUSICS_URL } from '../constants/ApiEndPoints';

@Injectable({
  providedIn: 'root'
})
export class MusicSingleService {
  constructor (private httpClient: HttpClient) {}

  getActiveMusicSingles(): Observable<MusicSingle[]> {
		return this.httpClient.get<MusicSingle[]>(GET_ACTIVE_MUSIC_SINGLES_URL);
	}

  getActiveBgMusics(): Observable<BgMusic[]> {
		return this.httpClient.get<BgMusic[]>(GET_ACTIVE_BACKGROUND_MUSICS_URL);
	}
}