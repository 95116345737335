<div class="section section-padding sigma_team-sec style-14 bg-gray">
    <div class="container-fluid p-sm-0">
        <div class="section-title centered">
            <!-- <span class="subtitle text-white">Meet Our Team</span> -->
            <h3 class="title text-white">Featured <br />Care Providers</h3>
        </div>
        <ngx-slick-carousel [config]="settings" class="sigma_team-slider-2">
            <!-- Data -->
            <div ngxSlickItem class="sigma_team style-14" *ngFor="let item of favouriteProfiles">
                <div class="sigma_team-thumb">
                    <img [src]="item.image" [alt]="item.name">
                </div>
                <div class="sigma_team-body">
                    <h5>
                        <a>{{item.name}}</a>
                    </h5>
                    <div class="sigma_team-categories">
                        <a class="sigma_team-category">{{item.specialist}}</a>
                    </div>
                    <div class="sigma_team-info">
                        <span>
                            <i class="fal fa-map-marker-alt"></i>
                            {{item.location}}
                        </span>
                    </div>
                </div>
            </div>
            <!-- Data -->
        </ngx-slick-carousel>
    </div>
</div>