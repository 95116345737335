import { Component, inject, OnInit } from '@angular/core';
import { UserUtil } from 'src/app/Utils/UserUtil';
import { ONMOOD9_COURSE_IMAGES_PATH } from 'src/app/constants/ApiEndPoints';
import { CategoriesService } from 'src/app/services/categories.service';
import { ModuleService } from 'src/app/services/module.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-user-favourite-module',
  templateUrl: './user-favourite-module.component.html',
  styleUrls: ['./user-favourite-module.component.css'],
  providers:[UserUtil]

})
export class UserFavouriteModuleComponent implements OnInit {
  private readonly userUtil = inject(UserUtil);
  
  favouriteModules: any;
  moduleImagePath = "";
  constructor(private categoriesService: CategoriesService, private storageService: StorageService,  private moduleService: ModuleService) {
    this.moduleImagePath = ONMOOD9_COURSE_IMAGES_PATH;
  }

  ngOnInit(): void {
    const userId = this.storageService.getCurrentUser().id;
    this.categoriesService.getFavouriteModules(userId).subscribe((data:any) => {
      // console.log(data);
      const JSONData = JSON.parse(JSON.stringify(data));
      // console.log(JSONData);

      if(JSONData['result'] === "success") {
        this.favouriteModules = JSONData['modules'];
        this.prepareModuleLinks(this.favouriteModules);
      }
    })
  }

  async prepareModuleLinks(favouriteModules: any) {
    const haveActiveSubscription = await this.userUtil.haveActiveSubscription();
    this.moduleService.prepareFavouriteModuleLinks(favouriteModules, haveActiveSubscription);
    // console.log(favouriteModules); 
  }

}
