<div class="main-wrapper">
    <div class=" pmodel " id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">{{pages[selectedPageIndex].title}}</h5>
                    <!-- <button type="button" #closeIcon class="close" data-dismiss="modal" aria-label="Close"
                        (click)="selectedPageIndex = 0; pleasentUnPleasentValue = ''; thoughtsValue = ''; selectedEmotion = '';">
                        <span aria-hidden="true">&times;</span>
                    </button> -->
                </div>
                <div class="modal-body" *ngIf="selectedPageIndex === 0">
                    <div class="felling">
                        <h3>
                            <p>I am your Companion and guide on your journey to the "9<sup>th</sup> Mood" the mood of Calm and Bliss.      
                            </p><br/>
                            How is your Mood today ?</h3>
                    </div>
                    <div class="feeling-area">
                        <div class="row">
                            <div class="col-sm-6" (click)="selectedPageIndex = 1;isPleasent = true; selectedEmotionIndex = 0"><img
                                    src="assets/img/pleasent.svg" style="width: 150px;" /></div>
                            <div class="col-sm-6" (click)="selectedPageIndex = 1;isPleasent = false; selectedEmotionIndex = 1"><img
                                    src="assets/img/unpleasent.svg" style="width: 150px;" /></div>
                        </div>
                    </div>
                </div>
                <div class="modal-body" *ngIf="selectedPageIndex === 1">
                    <div class="felling">
                        <h3>Try to identify the dominant emotion in your feelings
                            <span class="mt-3">Mark the emotion in the group below</span>
                        </h3>
                    </div>
                    <div [ngClass]="isPleasent === true ? 'hapemotion-sect' : 'emotion-sect'">
                        <div *ngFor="let emotion of emotionsData[selectedEmotionIndex]" (click)="moodClick(emotion)" [ngClass]="{'slectedMood': emotion.isSelected }">
                            <div><h3>{{emotion.title}}</h3></div>
                            <div class="d-flex justify-content-between">
                                <div>
                                    <ul>
                                        <li *ngFor="let synonym of emotion.synonyms">
                                            <figure><i class="fab-solid fab-circle"></i></figure>
                                            {{synonym}}
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <div>
                                        <div><img [src]="emotion.image" /></div>
                                        <!-- <div><input type="checkbox" class="custom_checkbox" id="{{emotion.id}}"
                                                [value]="emotion.code"
                                                (change)="pleasentUnPleasentCheckBoxClick($event, emotion, 'checkbox')"
                                                [checked]="pleasentUnPleasentValue === emotion.code">
                                            <label for="{{emotion.id}}"></label>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3" *ngIf="selectedEmotion !== ''">
                        <button type="button" class="radius-drkbtn" [disabled]="pleasentUnPleasentValue == ''"
                            (click)="selectedPageIndex = 2; moveToTop();">Next</button>
                    </div>
                </div>
                <div class="modal-body" *ngIf="selectedPageIndex === 2">
                    <div class="felling ">
                        <h3>Try to identify the dominant thought in your mind which is leading to
                            this feeling of {{selectedEmotion}}

                        </h3>
                    </div>

                    <div class="thoughtarea">

                        <div class="thought-box" [ngClass]="{'slectedThought': thought.isSelected }" *ngFor="let thought of thoughtsData" (click)="thoughtsDivClick(thought)">
                            <div>
                                <h3>{{thought.title}} <span>example -</span></h3>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div>

                                    <ul>
                                        <li *ngFor="let image of thought.images">
                                            <figure><img [src]="image.src" /> </figure> {{image.name}}
                                        </li>
                                    </ul>
                                </div>

                                <!-- <div class="ch" style="position: relative;">
                                    <div style="position: absolute; bottom: 16px;">
                                        <input type="checkbox"
                                            class="custom_checkbox" id="{{thought.id}}" [value]="thought.code"
                                            (change)="thoughtsCheck($event)" [checked]="thoughtsValue === thought.code">
                                        <label for="{{thought.id}}"></label>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="mt-3" *ngIf="thoughtsValue !== 'NA'"><button type="button" class="radius-drkbtn"
                            [disabled]="pleasentUnPleasentValue == ''" (click)="save2();">Next</button>
                    </div>
                </div>
                <div class="modal-body" *ngIf="selectedPageIndex === 3">
                    <div class="felling ">
                        <h3>How can I help you today in your Journey to the "9<sup>th</sup> Mood", the mood of Calm and Bliss ? </h3>
                    </div>
                    <div class="pop1">
                        <div class="hapemotion-sect-1">
                            <div *ngFor="let question of onmood9CoachQuestions" (click)="selectOnmood9CoachQuestion(question)">
                                <div class="text-center">
                                    <h3>{{question.title}}</h3>
                                </div>
                                <div class="justify-content-between">

                                        <div>

                                            <div class="text-center" style="position: relative;">
                                                <img [src]="question.image" />
                                                <!-- <div class="text-right" style="display: inline-flex; position: absolute; top: 20px; right: 5px;">
                                                        <input type="checkbox" class="custom_checkbox"
                                                        id="{{question.id}}" [value]="question.code"
                                                        >
                                                    <label for="{{question.id}}"></label>
                                                </div> -->
                                            </div>

                                        </div>
                                 
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="mt-3"><button style="background: #ccc;" type="button" (click)="gotoHome()" class="radius-drkbtn">Skip</button>
                    </div>
                </div>
                <div class="modal-body" *ngIf="selectedPageIndex === 4">
                    <div class="felling">
                        <h3>{{selectedQuestion.questionText}}</h3>
                    </div>
                    <div class="pop2">
                        <div class="p2-section"  [ngClass]="{'p2-section-unpleasant': selectedQuestion.code === 'UNP'}">
                            <div  *ngFor="let emotion of selectedQuestion.questionTextOptions" (click)="selectPleasantUnpleasantQuestion(selectedQuestion, emotion)">
                                <div>
                                    <h3>{{emotion.title}}</h3>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <div><img [src]="emotion.image" /></div>
                                    <!-- <div>
                                        <input class="custom_checkboxp2" type="checkbox"  >
                                        <label for="{{emotion.title}}"></label>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-body" *ngIf="selectedPageIndex === 5">
                    <div class="felling">
                        <h3 *ngIf="isPleasantQuestion">
                            {{selectedPleasantUnpleasantQuestion.question.questionText}} 
                            {{selectedPleasantUnpleasantQuestion.option.title}}
                        </h3>
                        <h3 *ngIf="!isPleasantQuestion">
                            {{selectedQuestion.questionText}}
                        </h3>
                    </div>
                    <div class="thoughtarea" *ngIf="isPleasantQuestion">
                        <div  class="thought-boxp2" style="position: relative;" *ngFor="let thought of selectedPleasantUnpleasantQuestion.question.questionTextOptions" (click)="gotoModule(selectedPleasantUnpleasantQuestion.option, thought.index)">
                            <div class="d-flex justify-content-between ">

                            <div>
                                <h3>{{thought.text}}</h3>                            
                            </div>
                            <!-- <div class="text-right"  style= "display: inline-flex; position: absolute; top: 22px; right: 18px;" >
                                <input  class="custom_checkboxp2" type="checkbox" id="{{thought.index}}"  [value]="thought.index" >
                                <label for="{{thought.text}}"></label>
                            </div> -->
                        </div>
                        </div>
                    </div>
                    <div class="thoughtarea" *ngIf="!isPleasantQuestion">
                        <div  class="thought-boxp2" style="position:relative" *ngFor="let thought of selectedQuestion.questionTextOptions" (click)="gotoModule2(thought)">
                            <div>
                                <h3>{{thought.text}}</h3>                            
                            </div>
                            <!-- <div class="text-right" style= "display: inline-flex; position: absolute; top: 22px; right: 18px;">
                                <input class="custom_checkboxp2" type="checkbox" id="{{thought.index}}"  [value]="thought.index" >
                                <label for="{{thought.text}}"></label>
                            </div> -->
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>
