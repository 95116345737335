import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { APPROVE_CP_MEMBER_PROFILE, CP_IMAGE_PATH, UNAPPROVE_CP_MEMBER_PROFILE } from 'src/app/constants/ApiEndPoints';
import { Service } from 'src/app/model/service';
import { Therapy } from 'src/app/model/therapy';
import { ApiService } from 'src/app/services/api-service';
import { ProfileDataService } from 'src/app/services/profile-data.service';
import { getNamesArray } from 'src/app/Utils/NameUtil';
import { Location } from '@angular/common';
import { StorageService } from 'src/app/services/storage.service';
import { CP_PROFLE_ID, CURRENT_USER, CURRENT_USER_ID } from 'src/app/constants/Constants';


@Component({
  selector: 'app-my-therapists',
  templateUrl: './my-therapists.component.html',
  styleUrls: ['./my-therapists.component.css']
})
export class MyTherapistsComponent implements OnInit {
  cpImagePath: string;

  constructor(private router: Router, private route: ActivatedRoute, private service: ProfileDataService,
     private apiService: ApiService, private location: Location,private storageService: StorageService) {
    this.cpImagePath = CP_IMAGE_PATH;
  }
  item: any;
  cpProfileId :any;
  doctordetail: any;
  languages: [] = [];
  available_days: [] = [];
  therapyArray: [] = [];
  specializationArray: [] = [];
  serviceArray: [] = [];
  therayList: Array<Therapy> = [];
  serviceList: Array<Service> = [];
  allotedMemberCpProfile:any;
  getAuthor(arg0: any): any {
    throw new Error('Method not implemented.');
  }

  goBack() {
    this.location.back();
  }
  ngOnInit(): void {
     const currentUser = JSON.parse(this.storageService.getItem(CURRENT_USER));
     console.log(currentUser);
     
    this.apiService.getUserByMemberCpProfileId(currentUser.id).subscribe(response => {
      this.storageService.storeItem(CP_PROFLE_ID, response['data'][0].cp_profile_id);
      this.cpProfileId = this.storageService.getItem(CP_PROFLE_ID);
      this.allotedMemberCpProfile = response['data'][0];
      console.log(this.cpProfileId)
          console.log(response['data'][0]);
    });
  
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    this.apiService.getTherapy().subscribe(res => {
      // console.log(res.data);
      this.therayList = res.data;
    });
    this.apiService.getServices().subscribe(res => {
      // console.log(res.data);
      this.serviceList = res.data;
    });
    this.service.getProfileDetails(this.storageService.getItem(CP_PROFLE_ID)).subscribe(response => {
      console.log(response.data[0]);
      this.doctordetail = response.data[0];
      this.doctordetail['picture'] = this.storageService.getItem(CP_PROFLE_ID) + '.jpeg';
      this.doctordetail['picture'] = this.doctordetail.is_picture === 'false' ? 'cpimage.jpg' : this.doctordetail.cp_profile_id + '.jpeg';

      this.doctordetail['cp_therapy_id'] = getNamesArray('therapy', this.doctordetail?.cp_therapy_id, this.therayList);
      this.doctordetail['cp_service_id'] = getNamesArray('service', this.doctordetail?.cp_service_id, this.serviceList);
      this.doctordetail['cp_specialization_id'] = this.doctordetail?.cp_specialization_id?.split(',');
      this.doctordetail['mode'] = this.doctordetail?.mode === 'BOTH' ? 'ONLINE/OFFLINE' : this.doctordetail?.mode;
      const exp = this.doctordetail?.experience.split('-');
      if (exp && exp.length > 0) {
        this.doctordetail['experience'] = exp[0] + ' Years ' + exp[1] + ' Months';
      } else {
        this.doctordetail['experience'] = 'NA';
      }
      this.languages = response.data[0]?.languages?.split(',');
      this.available_days = response.data[0]?.available_days?.split(',');
    });
  }

  ApproveStatus(e:any){
      console.log(e.target.checked);
      let checked = e.target.checked;
      if(checked === true){
        console.log('approve service calling');
        let url = APPROVE_CP_MEMBER_PROFILE;
        const payload = {
          cp_profile_id: this.storageService.getItem(CP_PROFLE_ID)
        }
        this.apiService.activateProfile(url, payload).subscribe((res: any) => {
          console.log(res);
          if(res['status'] === true) {
              // confirm("Successfully Approved");
              
          } else {
            alert("Something went wrong");
          }
        });
      }else{
        let url = UNAPPROVE_CP_MEMBER_PROFILE;
        const payload = {
          cp_profile_id: this.storageService.getItem(CP_PROFLE_ID)
        }
        this.apiService.inActivateProfile(url, payload).subscribe((res: any) => {
          console.log(res);
          if(res['status'] === true) {
              window.alert("Successfully Rejected");
          } else {
            alert("Something went wrong");
          }
        });
        console.log('un approve service calling');
      }
  }

}
