import { NgModule } from '@angular/core';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ApexChartsRoutingModule } from './apex-charts-routing.module';
import { ApexChartsComponent } from './apex-charts.component';



@NgModule({
  declarations: [
    ApexChartsComponent
  ],
  imports: [
    ApexChartsRoutingModule,
    NgApexchartsModule
  ]
})
export class ApexChartsModule { }
