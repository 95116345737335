import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import {  throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { CategoryGroupModule } from '../model/category-group-module';
import { StorageService } from './storage.service';
import { CORP, INDI, USER_TYPE } from '../constants/Constants';
import { isPartnerAccount } from '../Utils/WindowUtil';
import { P_USER_TYPE } from '../constants/PartnerConstants';
import { GET_COURCE_MODULES_URL } from '../constants/ApiEndPoints';

@Injectable({
  providedIn: 'root'
})
export class ModuleService {

  constructor(private httpClient: HttpClient, private storageService: StorageService) { }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  public getCourseModules(courseId:number)
  {
    let url = GET_COURCE_MODULES_URL.replace('{courseId}', ""+courseId);
    return this.httpClient.get(url).pipe(retry(3),catchError(this.handleError));
  }


  public prepareModuleLinks(modules: Array<CategoryGroupModule>, objectInput: any) {
    modules.forEach(module => {
      const isPA = isPartnerAccount(this.storageService);
      const userType = isPA ? P_USER_TYPE : USER_TYPE;
      
      if(this.storageService.getItem(userType) === CORP) {
        const isLocked = module.is_paid === 'free' || objectInput.haveActiveSubscription ? false : true;
        module.isLocked = isLocked;
        module.sessionLinkAvailable = isLocked ? false : true;
        module.sessionsLink = "/onmood-course/"+objectInput.categoryId+"/course/"+objectInput.courseId+"/group/"+objectInput.groupId+"/module/"+module.id+"/sessions";

      } else if(this.storageService.getItem(userType) === INDI) {
        const isLocked = module.is_paid === 'free' || objectInput.haveActiveSubscription ? false : true;
        module.isLocked = isLocked;
        module.sessionLinkAvailable = isLocked ? false : true;
        module.sessionsLink = "/onmood-course/"+objectInput.categoryId+"/course/"+objectInput.courseId+"/group/"+objectInput.groupId+"/module/"+module.id+"/sessions";
      }
    });
  }

  public prepareFavouriteModuleLinks(modules: Array<CategoryGroupModule>, haveActiveSubscription: boolean) {
    modules.forEach(module => {
      const isPA = isPartnerAccount(this.storageService);
      const userType = isPA ? P_USER_TYPE : USER_TYPE;    
      if(this.storageService.getItem(userType) === CORP) {
        const isLocked = module.is_paid === 'free' || haveActiveSubscription ? false : true;
        module.isLocked = isLocked;
        module.sessionLinkAvailable = isLocked ? false : true;
        module.sessionsLink = "/onmood-course/"+module.categoryId+"/course/"+module.courseTypeId+"/group/"+module.group_id+"/module/"+module.id+"/sessions";

      } else if(this.storageService.getItem(userType) === INDI) {
        const isLocked = module.is_paid === 'free' ? false : true;
        module.isLocked = isLocked;
        module.sessionLinkAvailable = isLocked ? false : true;
        module.sessionsLink = "/onmood-course/"+module.categoryId+"/course/"+module.courseTypeId+"/group/"+module.group_id+"/module/"+module.id+"/sessions";
      }
    });
  }
}
