<div *ngIf="isProcessing" style="margin: 0; text-align: center; padding-top: 20%; font-size: 25px;">
    {{message1}}<br />
    {{message2}}
</div>
<div *ngIf="!isProcessing">
    <div style="margin-bottom: 100px;">
        <app-terms></app-terms>
        <app-privacy></app-privacy>
    </div>
    <div class="accept">
        <button style="background-color: #ef6603; color: #fff; padding: 10px;" (click)="saveTermsPrivacy()">Accept</button>
    </div>
</div>