<div class="main-wrapper home">
    <div class="main-head">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 order-first">
                    <h1 style="text-align: center;">Sleep & Emotional Health</h1>
                    <div class="personal-des">
                        
                        <p>
                            Our comprehensive solution amalgamates cutting-edge AI technology, modern psychology, ancient mindfulness practices and compassionate coach-supported care to address your  Sleep and emotional wellbeing  needs
                        </p>

                        <ul>
                            <li>
                                Sleep & Emotional health Courses
                            </li>
                            <li>Sleep quality & Mood trackers</li>
                            <li>Coach supported Care</li>
                            <li>Sleep & Emotional health assessment</li>
                            <li>Mood and Thought tracker</li>
                        </ul>

                        <div class="personal-watermark">Personal</div>
                    </div>
                </div>
                <div class="col-lg-6 order-last">
                    <div class="rht-img personal">
                        <img src="assets/img/personal.svg" alt="" class="mood-img" />
                    </div>
                </div>
            </div>

            <div class="subsc-btn">
                <button type="button" class="radis-btn" data-toggle="modal" data-target="#sign-in">
                    <a href="https://wa.me/918897045351" style="color: #fff;" class="float" target="_blank">Subscribe</a>
                </button>
            </div>
        </div>
    </div>
    <div class="container partner">
        <div class="row">
            <div class="col-lg-6 text-center">
                <img src="assets/img/Corporate.webp" alt="" />
            </div>
            <div class="col-lg-6 partner-des">
                <p>
                    Innovative sleep care and emotional health for your associates and their family  
Onmood9 provides employee programs including Sleep care, De-stress, Anxiety Manage, Increase Joy, Improve resilience, Mindful workplace, Maternal wellbeing and many more to help you create an emotionally healthy and resilient workplace.
                </p>
                
                <ul>
                    <li>AI-Powered Sleep care and Emotional Health Platform</li>
                    <li>Guided audios & videos of body mind therapies & practices</li>
                    <li>Care supported by Sleep & Emotional wellbeing Coach</li>
                    <li>Awareness programs & Onsite workshops</li>
                    <li>Team Progress reports & charts</li>
                </ul>
                <div class="partner-watermark">Partner</div>
            </div>
        </div>
        <div class="getin-btn">
            <button type="button" class="radis-btn" data-toggle="modal" data-target="#sign-in">
                <a href="https://wa.me/918897045351" style="color: #fff;" class="float" target="_blank">Get in Touch</a>
            </button>
        </div>
    </div>
</div>