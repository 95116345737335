<div class="modal-dialog signin-model" style="height: 100vh;">
    <div class="modal-content">
        <div class="modal-header text-center">
            <h5 class="modal-title w-100" id="exampleModalLabel" *ngIf="popupType === 'signin'">Sign In</h5>
            <h5 class="modal-title w-100" id="exampleModalLabel" *ngIf="popupType === 'signup'">Sign Up</h5>

            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetForm()" #closeIcon>
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="sign-form-area">
                <form class="signin-form" [formGroup]="loginForm" *ngIf="popupType === 'signin'">
                    <div><input type="email" class="form-control" placeholder="Email" formControlName="username"/></div>
                    <div><input type="password" class="form-control" placeholder="Password" formControlName="password"/></div>
                    <div *ngIf="isErrorOccured" style="color: red; text-align: center">
                        {{errorMessage}}
                    </div>
                    <div class="mt-5"><button (click)="doOnmoodLogin()" type="button" class="sm-blue-btn">Sign In</button></div>
                    <div class="mt-3 org-txt"><a (click)="switchToForgotPassword()" style="cursor:pointer;">Forgot Password?</a></div>
                    <div class="looking"><span>Looking to</span> <a (click)="switchPopup('signup')" style="cursor:pointer;"> create an account?</a></div>
                </form>
                <form class="signin-form" [formGroup]="signupForm" *ngIf="popupType === 'signup'">
                    <div *ngIf="isErrorOccured" style="color: red; text-align: center">
                        {{errorMessage}}
                    </div>
                    <div><input type="text" class="form-control" placeholder="Name" formControlName="fName" /></div>
                    <div><input type="text" class="form-control" placeholder="Email" formControlName="email" /></div>
                    <div><input type="password" class="form-control" placeholder="Password" formControlName="password" /></div>
                    <div><input type="password" class="form-control" placeholder="Retype Password" formControlName="confirmPassword"/></div>

                    <div class="hinttxt">By continuing, you agree to Onmood9's <a  href="{{onmood9TermsUrl}}" target="_blank">Terms & Conditions</a> and <a href="{{onmood9PrivacyUrl}}" target="_blank">Privacy Policy</a></div>
                    <div class="mt-5"><button type="button" class="sm-blue-btn" (click)="signup()">Sign Up</button></div>
                    <div class="looking mt-2"><span>Already have account? </span> <a (click)="switchPopup('signin')" style="cursor:pointer;"> Sign In</a></div>
                </form>
            </div>
            <div class="text-center mt-2 mb-4">
                <img src="assets/img/or.webp" alt="" />
            </div>
            <div class="signinwith mb-4" style="text-align: center;" >{{sign_in_up_text}} with Social Media</div>
            <div class="signsocial-icon" style="display: grid;
            column-gap: 5px;
            justify-content: center;
            grid-template-columns: auto auto auto auto;">
                        <div class="google-icon">
                            <div id="g_id_onload"
                                data-client_id="302985111021-pjfe2cnk0f6oohntnqhfr3sbpnuea73m.apps.googleusercontent.com"
                                data-context="signin"
                                data-ux_mode="popup"
                                data-callback="handleCredentialResponse"
                                data-auto_prompt="false">
                            </div>

                            <div class="g_id_signin"
                                data-width=100
                                data-type="icon"
                                data-theme="filled_black"
                                data-shape="square"
                                data-text="Google"
                                data-size="large"
                                data-logo_alignment="center">
                            </div>

                        </div>
                        <div class="facebook-icon" style="background-image: none;
                        border: 2px solid #000;
                        color: #3c4043;
                        cursor: pointer;
                        font-size: 14px;
                        height: 40px;
                        letter-spacing: 0.25px;
                        outline: none;
                        overflow: hidden;
                        padding: 0 12px;
                        position: relative;
                        text-align: center;
                        vertical-align: middle;
                        white-space: nowrap;
                        margin-left: 50px;
                        width: 40px;
                        border-radius: 5px;" (click)="doFacebookLogin()">
                            <i (click)="doFacebookLogin()" class="fab fa-facebook-f"></i>
                        </div>
                    </div>
            <!-- <div class="row">
                <div class="col-sm-3 col-md-6">
                    <div id="g_id_onload"
                        data-client_id="302985111021-pjfe2cnk0f6oohntnqhfr3sbpnuea73m.apps.googleusercontent.com"
                        data-context="signin"
                        data-ux_mode="popup"
                        data-callback="handleCredentialResponse"
                        data-auto_prompt="false">
                    </div>

                    <div class="g_id_signin"
                        data-width=100
                        data-type="standard"
                        data-theme="filled_blue"
                        data-text="Google"
                        data-size="large"
                        data-logo_alignment="left">
                    </div>
                </div>
                <div class="col-sm-3 col-md-6">
                    <div class="fb-login-button" data-width="200" data-size="" data-button-type="" data-layout="" data-auto-logout-link="false" data-use-continue-as="true"></div>
                    <div style="border: 1px solid #ccc;
                    padding: 2px;
                    border-radius: 5px;
                    background-color: #1a73e8;">
                        <i (click)="doFacebookLogin()" style="color: #1a73e8;
                        padding: 10px 14px 10px 14px;
                        background-color: #fff;
                        border-radius: 5px 0px 0px 5px" class="fab fa-facebook-f"></i>
                        <span style="font-size: 14px;
                        font-weight: 500;
                        color: #fff;
                        padding-left: 10px;">Facebook</span>
                    </div>
                </div>
                <div class="col text-left">
                    <div style="border: 1px solid;
                    width: 40px;
                    text-align: center;
                    padding: 10px;
                    border-radius: 50%;
                    height: 40px;
                    border-color: #ccc;">
                        <i (click)="doFacebookLogin()" style="color: blue;" class="fab fa-facebook-f"></i>
                    </div>
                </div>
            </div> -->
            <!-- <div class="signsocial-icon">
                <div class="mt-5">
                    <div id="g_id_onload"
                        data-client_id="302985111021-pjfe2cnk0f6oohntnqhfr3sbpnuea73m.apps.googleusercontent.com"
                        data-context="signin"
                        data-ux_mode="popup"
                        data-callback="handleCredentialResponse"
                        data-auto_prompt="false">
                    </div>

                    <div class="g_id_signin"
                        data-type="icon"
                        data-shape="circle"
                        data-text="Sign in with Google"
                        data-size="medium"
                        data-logo_alignment="center">
                    </div>
                </div>
                <i (click)="doFacebookLogin()" class="fab fa-facebook-f"></i>
            </div> -->

            <!-- <div style="margin: auto; width: 50%;">
                <div class="mt-5">
                    <div id="g_id_onload"
                        data-client_id="302985111021-pjfe2cnk0f6oohntnqhfr3sbpnuea73m.apps.googleusercontent.com"
                        data-context="signin"
                        data-ux_mode="popup"
                        data-callback="handleCredentialResponse"
                        data-auto_prompt="false">
                    </div>

                    <div class="g_id_signin"
                        data-type="icon"
                        data-shape="circle"
                        data-text="Sign in with Google"
                        data-size="medium"
                        data-logo_alignment="center">
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
