import { Component } from '@angular/core';
import { DoctorHelperService } from 'src/app/services/doctor-helper.service';
// import { Options, LabelType } from "@angular-slider/ngx-slider";

@Component({
  selector: 'app-doctor-sidebar',
  templateUrl: './doctor-sidebar.component.html',
  styleUrls: ['./doctor-sidebar.component.css']
})
export class DoctorSidebarComponent {
  minValue: number = 0;
  maxValue: number = 50;
  category: any;
  // options: Options = {
  //   floor: 0,
  //   ceil: 50
  // };
}
