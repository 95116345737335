import { PLEASANT_EMOTIONS, UNPLEASANT_EMOTIONS, QUESTION_3, QUESTION_4, QUESTION_5, QUESTION_6, QUESTION_7, QUESTION_9, QUESTION_10,} from "./Constants";

export const p2_pleasant_question = {
    questionText: 'How you want to increase your feeling of ',
    questionTextOptions: [
        {index: 0, text: 'Instantly through Mindful Movements', link:''},
        {index: 1, text: 'Quickly by Revitalizing energy', link:''},
        {index: 2, text: 'Slowly though Contemplations', link:''},
        {index: 3, text: 'Deeply through meditation', link:''},
        {index: 4, text: 'Through Music', link:''},
    ]
};

export const p2_unpleasant_question = {
    questionText: 'How you want to control you feeling of ',
    questionTextOptions: [
        {index: 0, text: 'Instantly through Mindful Movements', link:''},
        {index: 1, text: 'Quickly by Revitalizing energy', link:''},
        {index: 2, text: 'Slowly though Contemplations', link:''},
        {index: 3, text: 'Deeply through meditation', link:''},
        {index: 4, text: 'Through Music', link:''},
    ]
};

export const p2_pleasant = {
    question : "Enhance Pleasant emotions",
    questionText : "Which pleasant Emotion you want to enhance ?",
    index: 0,
    id: 'mood1',
    code: 'P',
    title: 'Enhance Pleasant emotions',
    image: 'assets/img/pleasantemotions.png',
    synonyms: ['Euphoria', 'Elated', 'Joyful', 'Glad'],
    next: p2_pleasant_question,
    questionTextOptions: [
        PLEASANT_EMOTIONS[0],
        PLEASANT_EMOTIONS[1],
        PLEASANT_EMOTIONS[2],
        PLEASANT_EMOTIONS[3],
    ]
};

export const p2_unpleasant = {
    question : "Control Unpleasant emotions",
    questionText : "Which Unpleasant Emotion you want to Control ?",
    index: 0,
    id: 'mood2',
    code: 'UNP',
    title: 'Control Unpleasant emotions',
    image: 'assets/img/unpleasantemotions.png',
    synonyms: ['Passionate', 'Attached', 'Affection', 'Compassion'],
    next: p2_unpleasant_question,
    questionTextOptions: [
        UNPLEASANT_EMOTIONS[0],
        UNPLEASANT_EMOTIONS[1],
        UNPLEASANT_EMOTIONS[2],
        UNPLEASANT_EMOTIONS[3],
    ]
};


export const question_1 = () => {
    return {
       
        question : "Enhance Pleasant emotions",
        questionText : "Which pleasant Emotion you want to enhance ?",
        index: 0,
        id: 'mood1',
        code: 'M1',
        title: 'Enhance Pleasant emotions ',
        image: 'assets/img/pleasantemotions.png',
        synonyms: ['Euphoria', 'Elated', 'Joyful', 'Glad'],
        next: p2_pleasant,
        questionTextOptions: [
            { id:1, optionText : "Joy" },
            { id:2, optionText : "Passion And Love " },
            { id:3, optionText : "Courage And Resilience" },
            { id:4, optionText : "Wonder" }
     ]
  }
};

export const question_2 = () => {
    return {
      
        question : "Control Unpleasant emotions",
        questionText : "Which Unpleasant Emotion you want to Control ?",
        index: 1,
        id: 'mood2',
        code: 'M2',
        title: ' Control Unpleasant emotions ',
        image: 'assets/img/unpleasantemotions.png',
        synonyms: ['Passionate', 'Attached', 'Affection', 'Compassion'],
        next: p2_unpleasant,
        questionTextOptions: [
            { id:1, optionText : "Anger" },
            { id:2, optionText : "Fear and Anxiety" },
            { id:3, optionText : "Sadness and depression" },
            { id:4, optionText : " Hate & disgust" }
     ]
 }
};

export const question_3 = () => {
    return {
        question : "De-stress",
        questionText : "How you want to destress yourself ?",
        index: 2,
        id: 'mood3',
        code: 'M3',
        title: 'De - Stress  ',
        image: 'assets/img/destress.png',
        synonyms: ['Fearless', 'Arrogant', 'Brave', 'Pride'],
        next: QUESTION_3,
        questionTextOptions: [
            { id:1, optionText : "Destress Meditation" },
            { id:2, optionText : "Destress Music" },
            { id:3, optionText : "Manage Intense emotions SOS" },
            { id:4, optionText : "Natural Sounds" }
     ]
 }
};

export const question_4 = () => {
    return {
    
        question : "Meditate",
        questionText : " Which Meditation you will prefer now ?",
        index: 3,
        id: 'mood4',
        code: 'M4',
        title: 'Meditate',
        image: 'assets/img/meditation.png',
        synonyms: ['Amazed', 'Surprized', 'Curious', 'Excited'],
        next: QUESTION_4,
        questionTextOptions: [
            { id:1, optionText : "Basic meditation" },
            { id:2, optionText : "Mindfulness" },
            { id:3, optionText : " Selfcare" },
            { id:4, optionText : "Self healing" },
            { id:5, optionText : "Positive attitude"},
            { id:6, optionText :"Morning energy"}
     ]
 }
};

export const question_5 = () => {
    return {

        question : " Relax with Music",
        questionText : "Which Music will you prefer ? ",
        index: 4,
        id: 'mood5',
        code: 'M5',
        title: 'Relax with Music ',
        image: 'assets/img/music.png',
        synonyms: ['Amazed', 'Surprized', 'Curious', 'Excited'],
        next: QUESTION_5,
        questionTextOptions: [
            { id:1, optionText : "Natural Sounds" },
            { id:2, optionText : "Reduce Anxiety" },
            { id:3, optionText : "De- Stress Music" },
            { id:4, optionText : "Overcome depression" },
            { id:5, optionText : "Increase Joy" },
            { id:6, optionText : " Improve resilience" },
            { id:7, optionText : "Calm and Blissful" },
            { id:8, optionText : "Enhance Love" },
     ]
 }
};

export const question_6 = () => {
    return {
        question : "Take a Nap or Deep Sleep",
        questionText : "How you want  to proceed to  Sleep? With help  of ... ",
        index: 5,
        id: 'mood6',
        code: 'M6',
        title: 'Take a Nap or Deep Sleep ',
        image: 'assets/img/sleep.png',
        synonyms: ['Amazed', 'Surprized', 'Curious', 'Excited'],
        next: QUESTION_6,
        questionTextOptions: [
            { id:1, optionText : "Sleep exercise" },
            { id:2, optionText : "Sleep Training" },
            { id:3, optionText : "Sleep Meditation" },
            { id:4, optionText : "Sleep Music" }
     ]
 }
};

export const question_7 = () => {
    return {
       
        question : "Improve Emotional Intelligence",
        questionText : "Learn about emotions & Moods",
        index: 6,
        id: 'mood7',
        code: 'M7',
        title: 'Improve Emotional Intelligence',
        image: 'assets/img/emotionalintelligence.png',
        synonyms: ['Amazed', 'Surprized', 'Curious', 'Excited'],
        next: QUESTION_7,
        questionTextOptions: [
            { id:1, optionText : "improve Self-awareness" },
            { id:2, optionText : "Explore thoughts" },
            { id:3, optionText : "Identify Emotions" },
            { id:4, optionText : "Label Moods" },
        ]
    }
};

export const question_8 = () => {
    return {
       
        question : "Assess Emotional Wellbeing",
        questionText : "Assessment",
        index: 7,
        id: 'mood8',
        code: 'M8',
        title: 'Assess Emotional Wellbeing',
        image: 'assets/img/emotionalassessment.png',
        synonyms: ['Amazed', 'Surprized', 'Curious', 'Excited'],
          questionTextOptions: [
              { id:1, optionText : "improve Self-awareness" },
              { id:2, optionText : "Explore thoughts" },
              { id:3, optionText : "Identify Emotions" },
              { id:4, optionText : "Label Moods" },
  
     ]
 }
};

export const question_9 = () => {
    return {
       
        question : "Take care of Personal emotions",
        questionText : "Learn about emotions & Moods",
        index: 8,
        id: 'mood9',
        code: 'M9',
        title: 'Take care of Personal emotions',
        image: 'assets/img/personalEmotions.png',
        synonyms: ['Amazed', 'Surprized', 'Curious', 'Excited'],
        next: QUESTION_9,
        questionTextOptions: [
            { id:1, optionText : "improve Self-awareness" },
            { id:2, optionText : "Explore thoughts" },
            { id:3, optionText : "Identify Emotions" },
            { id:4, optionText : "Label Moods" },
        ]
    }
};

export const question_10 = () => {
    return {
       
        question : "Manage Social Emotions",
        questionText : "Learn about emotions & Moods",
        index: 9,
        id: 'mood10',
        code: 'M10',
        title: 'Manage Social Emotions',
        image: 'assets/img/socialEmotions.png',
        synonyms: ['Amazed', 'Surprized', 'Curious', 'Excited'],
        next: QUESTION_10,
        questionTextOptions: [
            { id:1, optionText : "Selfawarness" },
            { id:2, optionText : "Explore thoughts" },
            { id:3, optionText : "Identify Emotions" },
            { id:4, optionText : "Label Moods" },
        ]
    }
};
