export const getPercentageValue = (moodArray: any, totalCount: number) => {
  return totalCount > 0
    ? Number(((moodArray.length / totalCount) * 100).toFixed(1)) + '%'
    : 0 + '%';
};

export const getPercentageValue2 = (moodArray: any, totalCount: number) => {
  let percentage = Number((moodArray.length / totalCount) * 100); //.toFixed(0));
  percentage = Number(percentage.toFixed(0));
  const perce = totalCount > 0 ? Math.round(percentage) : 0;
  // console.log('perce', perce);
  return perce;
};


export const getApexPercentageValue = (moodArray: any, totalCount: number) => {
  let percentage = Number((moodArray.length / totalCount) * 100); //.toFixed(0));
  percentage = Number(percentage.toFixed(0));
  const perce = totalCount > 0 ? Math.round(percentage) : 0;
  // console.log('perce', perce);
  return perce > 0 ? 1 : 0;
};

export const getMoodDataLabel = (moodName: string) => {
  return `<lablel class='p-2'>${moodName.slice(
    0,
    moodName.indexOf('-')
  )}</lablel>`;
};

export const getMoodNameWithPerentage = (
  moodName: string,
  moodArray: [],
  totalMoodCount: number
) => {
  return moodName + ' - ' + getPercentageValue(moodArray, totalMoodCount);
};

export const getThoughtNameWithPerentage = (
  thoughtName: string,
  thoughtArray: [],
  totalMoodCount: number
) => {
  return thoughtName + ' - ' + getPercentageValue(thoughtArray, totalMoodCount);
};

export const getFromDate = (selectedOptionInd: number) => {
  let fromDate: any = new Date();
  if (selectedOptionInd === 0) {
    fromDate = fromDate.setDate(fromDate.getDate() - 15);
  }
  if (selectedOptionInd === 1) {
    fromDate = fromDate.setDate(fromDate.getDate() - 30);
  }
  if (selectedOptionInd === 2) {
    fromDate = fromDate.setDate(fromDate.getDate() - 365);
  }
  if (selectedOptionInd === 3) {
    fromDate = fromDate.setDate(fromDate.getDate() - 1825);
  }
  return fromDate;
};

export const getFromDateInDays = (days:number) => {
  let fromDate: any = new Date();
  return fromDate.setDate(fromDate.getDate() - days);
}

export const M1MoodName = 'Resilience';
export const  M2MoodName = 'Wonder';
export const M3MoodName = 'Joy';
export const M4MoodName = 'Love';
export const  M5MoodName = 'Disgust';
export const  M6MoodName = 'Depression & Sadness';
export const M7MoodName = 'Anxiety & Fear';
export const M8MoodName = 'Anger';

export const moodWithColorCodes =  [
  {index: 0, moodName: 'Resilience', colorCode: '#FEA500'},
  {index: 1, moodName: 'Wonder', colorCode: '#CBD109'},
  {index: 2, moodName: 'Joy', colorCode: '#FFD700'},
  {index: 3, moodName: 'Love', colorCode: '#2DB12D'},
  {index: 4, moodName: 'Disgust', colorCode: '#1870C5'},
  {index: 5, moodName: 'Depression & Sadness', colorCode: '#7228bc'},
  {index: 6, moodName: 'Anxiety & Fear', colorCode: '#D36BD3'},
  {index: 7, moodName: 'Anger', colorCode: '#fe0000'},
];

export const getMoodColorCode = (moodType: string) => {
  const moodObj2 = moodWithColorCodes.find(element => element.moodName.match(moodType));
  return moodObj2?.colorCode ? moodObj2?.colorCode : '';
}

export const getMoodIndexByColorCode = (colorCode: string) => {  
  const moodObj2 = moodWithColorCodes.find(element => element.colorCode.match(colorCode));
  return moodObj2 && moodObj2.index >= 0 ? moodObj2.index : -1;
}


export const T1ThoughtName = 'Me & MySelf';
export const T2ThoughtName = 'Family & Friends';
export const T3ThoughtName = 'Community & Others';

export const thoughtWithColorCodes =  [
  {index: 0, thoughtName: 'Me & MySelf', colorCode: '#808000'},
  {index: 1, thoughtName: 'Family & Friends', colorCode: '#00008B'},
  {index: 2, thoughtName: 'Community & Others', colorCode: '#800000'},
];

export const getThoughtColorCode = (thoughtType: string) => {
  const thoughtObject = thoughtWithColorCodes.find(element => element.thoughtName.match(thoughtType));
  return thoughtObject?.colorCode ? thoughtObject?.colorCode : '';
}