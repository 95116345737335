<div class="row">
    <div class="col-lg-5 order-2 order-lg-1">
        <div class="sigma_about style-21">
            <div class="section-title">
                <h3 class="title text-white">What is Onmood9 ?</h3>
            </div>
            <div class="sigma_about-content">
                <p>Onmood9 is an Initiative to help people improve sleep and enhance happiness naturally, through 500+ guided sessions of ancient mind-body practices and mind training techniques, trackers, assessment tools, and personalized coach support.</p>
                <!-- Data -->
                <div class="sigma_info style-15" *ngFor="let item of whyus | slice:0:2">
                    <div class="sigma_info-title">
                        <i class="sigma_info-icon" [ngClass]="item.icon"></i>
                    </div>
                    <div class="sigma_info-description">
                        <h5>{{item.title}}</h5>
                        <p>{{item.text}}</p>
                    </div>
                </div>
                <!-- Data -->
            </div>
        </div>
    </div>
    <div class="col-lg-6 offset-lg-1 order-1 order-lg-2">
        <div class="sigma_about style-21 mt-0 w-100 h-100">
            <div class="sigma_about-image-1">
                <img src="assets/img/cp/sleep-2.jpg" alt="img">
            </div>
            <div class="sigma_about-image-2 d-none d-sm-block">
                <img src="assets/img/cp/happiness -3.jpg" alt="img">
            </div>
        </div>
    </div>
</div>