import { ApiService } from 'src/app/services/api-service';
import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { convertEmail } from 'src/app/Utils/StringUtil';
import { CarePlan } from 'src/app/model/care-plan';
import { ONMOOD9_COURSE_IMAGES_PATH } from 'src/app/constants/ApiEndPoints';

@Component({
  selector: 'app-care-plan',
  templateUrl: './care-plan.component.html',
  styleUrls: ['./care-plan.component.scss']
})
export class CarePlanComponent implements OnInit {
  currentUser: any;
  carePlanList: Array<CarePlan> = [];
  moduleImagePath = "";
  constructor(private apiService: ApiService, private storageService: StorageService) {
    this.moduleImagePath = ONMOOD9_COURSE_IMAGES_PATH;
  }

  ngOnInit(): void {
    this.getMyCarePlan();
  }

  getMyCarePlan() {
    this.currentUser = this.storageService.getCurrentUser();
    convertEmail(this.currentUser.email)
    this.apiService.getMyCarePlan(convertEmail(this.currentUser.email)).subscribe((data)=> {
      console.log(data);
      this.carePlanList = data['ActiveCarePlan'];
    })
  }
}
