import {
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest,
  } from "@angular/common/http";
  import { Injectable } from "@angular/core";
import { LocalStorageService } from "./local-storage.service";
  @Injectable()
  export default class HttpAPIInterceptor implements HttpInterceptor {
	constructor(private localStorageService: LocalStorageService) {

	}
	intercept(request: HttpRequest<any>, next: HttpHandler) {
	  let token = this.localStorageService.getToken();
	
	  let newRequest = null;
	  const url = `${request.url}`;
	  if (token != null) {
		const headers = new HttpHeaders().set('Token', ""+token );
		newRequest = request.clone({ headers, url });
	  } else {
		newRequest = request.clone({ url });
	  }
	  return next.handle(newRequest);
	}
  }
  