import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { GET_USER_SUBSCRIPTIONS } from 'src/app/constants/ApiEndPoints';
import { HAVE_ACTIVE_SUBSCRIPTION } from 'src/app/constants/Constants';
import { P_HAVE_ACTIVE_SUBSCRIPTION } from 'src/app/constants/PartnerConstants';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { StorageService } from 'src/app/services/storage.service';
import { isPartnerAccount } from 'src/app/Utils/WindowUtil';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent {
  isPA: boolean = false;
  validUserSubscription: boolean = false;
  constructor(public storageService: StorageService, public http: HttpClient) {
    console.log('BaseComponent constructor is called');
    this.isPA = isPartnerAccount(this.storageService);
    const email = this.storageService.getCurrentUser().email;
    // if(email === null) {
    //   this.authenticationService.logout();
    // } else {
      this.getUserActiveSubscription(email).subscribe((response: any) => {
        if(response['result'] === true && response['subscriptions'].length > 0) {
          this.storageService.storeItem(HAVE_ACTIVE_SUBSCRIPTION, "true");
          this.validUserSubscription = true;
          // console.log(response);
          // console.log('reset user subscription to true');

        } else {
          // console.log('reset user subscription to false');
          
          this.storageService.storeItem(HAVE_ACTIVE_SUBSCRIPTION, "false");
          this.validUserSubscription = false;
        }
      });
    // }
  }

  async haveActiveSubscription() {
    this.isPA = isPartnerAccount(this.storageService);
    const key = this.isPA ? P_HAVE_ACTIVE_SUBSCRIPTION : HAVE_ACTIVE_SUBSCRIPTION;    
    return await JSON.parse(this.storageService.getItem(key));
  }

  public getUserActiveSubscription(email: any) {
    const payLoad = { email: email };
    return this.http.post<any>(GET_USER_SUBSCRIPTIONS, payLoad);
  }

  getCurrentUser() {
    return this.storageService.getCurrentUser();
  }
}