import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartnerTermsPrivacyComponent } from './partner-terms-privacy.component';
import { PrivacyComponent } from '../privacy/privacy.component';
import { TermsComponent } from 'src/app/_common/terms/terms.component';

@NgModule({
  declarations: [
    PartnerTermsPrivacyComponent,
    PrivacyComponent,
    TermsComponent
  ],
  imports: [
    CommonModule
    
  ]
})
export class PrivacyTermsPrivacyModule { }
