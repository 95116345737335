<div class="sigma_banner-info style-2">
    <div class="container">
        <div class="sigma_cta style-13">
            <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
                <div class="row no-gutters">
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Serach Care Provider</label>
                            <input type="text" class="topic-field" placeholder="Search By Name, Problems, Therapies, Services etc... "
                                formControlName="topic">
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <label>Location</label>
                            <div class="input-group">
                                <i class="fal fa-map-marker-alt d-none d-sm-block"></i>
                                <input type="text" class="location-field" placeholder="Location" formControlName="location">
                                <div class="input-group-append">
                                    <button type="submit"> <i class="fal fa-search mr-1"></i> <span id="mb">Find Now</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>