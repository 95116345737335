import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FACEBOOK_APP_ID, FACEBOOK_USER_DETAILS_URL } from 'src/app/constants/Constants';


declare var FB: any;

@Component({
	selector: 'app-test-fb-login',
	templateUrl: './test-fb-login.component.html',
	styleUrls: ['./test-fb-login.component.css']
})
export class TestFbLoginComponent implements OnInit {
	isFbUser: boolean = false;
	@ViewChild('alertPopupId') alertPopupId!: ElementRef<HTMLElement>;
	constructor(private http: HttpClient  ) { }

	ngOnInit(): void {
		this.fbSetup();
		this.getFBLoginStatus();
	}
	ngAfterViewInit() {
		let el: HTMLElement = this.alertPopupId.nativeElement;
    el.click();
	}

	/**Facebook login */
	fbSetup() {
		(window as any).fbAsyncInit = function () {
			FB.init({
				appId: FACEBOOK_APP_ID,
				cookie: true,
				xfbml: true,
				version: 'v3.1'
			});

			FB.AppEvents.logPageView();
			

		};
	}

	getFBLoginStatus() {
		FB.getLoginStatus(function(response: any) { 
			
			// statusChangeCallback(response);
		});
	}

	doFBLogin() {
		FB.login((response: any) => {
			// handle the response 
			if (response.status === "connected") {
                //login success
                //login success code here
                //redirect to home page
                let accessToken = response.authResponse.accessToken;
                let userId = response.authResponse.userID;
                let url = FACEBOOK_USER_DETAILS_URL.replace("{fb-user-id}", userId).replace("{access-token}",accessToken);
                let fbResponse = this.getFacebookUserInfo(url);
                fbResponse.subscribe((data:any) => { 
					this.isFbUser = true;
					
                })        
            } else {
                console.log('User login failed');
            }
			
		  }, {scope: 'public_profile, email'});
	}

	getFacebookUserInfo(url: string): Observable<any> {
		return this.http.get<any>(url);
    }

	doFBLogout() {
		FB.logout((response: any) => {
			// Person is now logged out
			this.isFbUser = false;

		});
	}
	
}
