import { Component, OnInit,  Renderer2, Inject, HostListener  } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import CryptoJS from 'crypto-js';
import encHex from 'crypto-js/enc-hex'

import aes from 'crypto-js/aes'
import padZeroPadding from 'crypto-js/pad-zeropadding'

import { Platform } from '@angular/cdk/platform';
import { filter, map } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { DOCUMENT } from '@angular/common';
import { StorageService } from './services/storage.service';
import { isOpenedInBrowser, isPartnerAccount, isPartnerAppMobile, isPartnerAppWeb } from './Utils/WindowUtil';
import { LocalStorageService } from './services/local-storage.service';
import { P_CUNIQUE_ID, P_ENCRYPTED, P_PARTNER_ACCOUNT_RELOAD, P_PARTNER_APP, P_PARTNER_LOGIN_INPROGRESS } from './constants/PartnerConstants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  // [x: string]: any;
  isOnline: boolean;
  modalVersion: boolean;
  modalPwaEvent: any;
  modalPwaPlatform: string|undefined;
  time = new Date();
  intervalId: any;

  public screenWidth: any;
  public screenHeight: any;
  public headerHeight = 50;
  public footerHeight = 114;
  public bodyHeight = 0;
  hideFooter: boolean = false;

  // PARTNER_LOGIN_INPROGRESS
  hideHeader: boolean = false; 
  partnerLoginInprogress: boolean = false;
  showPartnerRefresh: boolean = false;

  // isPartner: boolean = false;

  serviceClient: any
  serviceClientWithSAS: any
  constructor(private platform: Platform, private swUpdate: SwUpdate, private storageService: StorageService, private _router: Router, private localStorageService: LocalStorageService,  private    authenticationService: AuthenticationService, private _renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document ) {
    this.isOnline = false;
    this.modalVersion = false;
    this.authenticationService.userLogoutEvent.subscribe((isloggedOut) => this.reloadGoogleScript(isloggedOut));
    this.authenticationService.hideFooterEvent.subscribe((hideFooter) => {
        this.hideFooter = hideFooter;
    });
    this.authenticationService.hideHeaderEvent.subscribe((hideHeader) => {
      this.hideHeader = hideHeader;
    });

    this.authenticationService.partnerLogoutEvent.subscribe((showRefresh) => {
      this.showPartnerRefresh = showRefresh;
    });

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
    this.screenHeight = event.target.innerHeight;
    this.bodyHeight = this.screenHeight - this.headerHeight - this.footerHeight;
  }

  @HostListener('window:storage')
  onStorageChange() {
    const isOpenedInBrowser = window.self === window.top;
    const isPartnerAccountReload = this.storageService.getItem(P_PARTNER_ACCOUNT_RELOAD);
    if(isPartnerAccountReload != null && isPartnerAccountReload === 'true' && !isOpenedInBrowser) {
      this.showPartnerRefresh = true;
    } else {
      this.showPartnerRefresh = false;
    }
  }

  

  reload() {
    this.showPartnerRefresh = false;
    this.storageService.removeItem(P_PARTNER_ACCOUNT_RELOAD);
    // window.self.location.reload();
    const encrypted = this.storageService.getItem(P_ENCRYPTED);
    const p_cunique_id = this.storageService.getItem(P_CUNIQUE_ID);    
    this._router.navigate(["/partner-login/"+p_cunique_id+"/"+encrypted]);
  }

  public ngOnInit(): void {
    // console.log('================>',this.storageService.getItem('window'));
    
    const isPA = isPartnerAccount(this.storageService);
    if(isPartnerAppMobile(this.storageService)) {
      this.hideFooter = true;
    } else if(isPA && isPartnerAppWeb(this.storageService) && !isOpenedInBrowser()) {    
      this.hideFooter = true;
    } else {
      this.hideFooter = false;
    }
    
    const pLoginInProgress = this.storageService.getItem(P_PARTNER_LOGIN_INPROGRESS);
    if(pLoginInProgress != null && pLoginInProgress === 'true') {
      this.partnerLoginInprogress = true;
    } else {
      this.partnerLoginInprogress = false;
    }
    // this.testYuvaCrypto();

    // console.log(CryptoJS.AES);
    
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this._router.events.subscribe((evt: any) => {
        if (!(evt instanceof NavigationEnd)) {
          
            // return;
        }
        window.scrollTo(0, 0)
    });
    this.bodyHeight = this.screenHeight - this.headerHeight - this.footerHeight;
    

	// Using Basic Interval
    this.intervalId = setInterval(() => {
		  this.time = new Date();
	  }, 1000);


    this.updateOnlineStatus();

    window.addEventListener('online', this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.updateOnlineStatus.bind(this));

    if (this.swUpdate.isEnabled) {
      // console.log('enabled');
      // console.log(this.swUpdate.versionUpdates);
      
      this.swUpdate.versionUpdates.pipe(
        filter(
          (evt: any): evt is VersionReadyEvent => evt.type === 'VERSION_READY'
        ),
        map((evt: any) => {
          console.info(
            `currentVersion=[${evt.currentVersion} | latestVersion=[${evt.latestVersion}]`
          );
          this.modalVersion = true;
        })
      );
    }

    this.swUpdate.versionUpdates.subscribe((evt: any) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${evt.currentVersion.hash}`);
          console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
          this.updateVersion();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          break;
      }
    });
    

	// console.log(this.platform);
	  this.loadModalPwa();    
  }


  reloadGoogleScript (isloggedOut: boolean) {
    console.log("load back google account");
		let script = this._renderer2.createElement('script');
		script.src = "https://accounts.google.com/gsi/client";
		this._renderer2.appendChild(this._document.body, script);
	}

  private updateOnlineStatus(): void {
    this.isOnline = window.navigator.onLine;
    // console.info(`isOnline=[${this.isOnline}]`);
  }

  public updateVersion(): void {
    this.modalVersion = false;
    window.location.reload();
  }

  public closeVersion(): void {
    this.modalVersion = false;
  }

  public loadModalPwa2(): void {
	// console.log(this.platform);
  }

  private loadModalPwa(): void {
	if (this.platform.ANDROID) {
	    // alert(this.platform.ANDROID);



      window.addEventListener('beforeinstallprompt', (event: any) => {
		    // console.log(event)
		    // alert(this.platform.ANDROID);

        event.preventDefault();
        this.modalPwaEvent = event;

        this.modalPwaPlatform = 'ANDROID';
        window.addEventListener('touchmove', (event: any) => {
          // console.log(event);
          // alert(123);
          // this.addToHomeScreen();
        });
      });
      
    }

    if (this.platform.IOS && this.platform.SAFARI) {
      const isInStandaloneMode = ('standalone' in window.navigator) && ((<any>window.navigator)['standalone']);
      if (!isInStandaloneMode) {
        this.modalPwaPlatform = 'IOS';
        window.addEventListener('beforeinstallprompt', (event: any) => {
          // console.log(event)
          // alert(this.platform.ANDROID);
  
          event.preventDefault();
          this.modalPwaEvent = event;
  
          window.addEventListener('touchmove', (event: any) => {
            // console.log(event);
            // alert(123);
            this.addToHomeScreen();
          });
        });
      }
    }
  }

  public addToHomeScreen(): void {
    this.modalPwaEvent.prompt();
    this.modalPwaEvent.userChoice.then((choice:any) => {
      if (choice.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      // Clear the saved prompt since it can't be used again
      this.modalPwaEvent = null;
    });
    this.modalPwaPlatform = undefined;
  }

  public closePwa(): void {
    this.modalPwaPlatform = undefined;
  }

  testCrypto() {
    // message to encrypt
    let msg1 = "user_id=testuser12&status=Active&start_date=2023-06-01&end_date=2023-06-30";

    // the key and iv should be 32 hex digits each, any hex digits you want, but it needs to be 32 on length each
    let key1 = encHex.parse("5bc6xmwr7e0qkvp324ghfa9z1jn8ludo");
    let iv1 =  encHex.parse("gdkx4fshvqyp6uzc1bt83i9erlo7n5mj");

    // encrypt the message
    let encrypted1 = aes.encrypt(msg1, key1, {iv:iv1, padding:padZeroPadding}).toString();
    console.log(encrypted1);



        // message to encrypt
    let msg2 = "user_id=testuser12&status=Active&start_date=2023-08-01&end_date=2023-08-31";
    // let msg2 = "user_id=testuser12&status=Inactive";

    // the key and iv should be 32 hex digits each, any hex digits you want, but it needs to be 32 on length each
    let key2 = encHex.parse("8a0976d5abc40274354b37fbf5c93eaa");
    let iv2 =  encHex.parse("9b110990b68db3670b86381893118bf9");

    // encrypt the message
    let encrypted2 = aes.encrypt(msg2, key2, {iv:iv2, padding:padZeroPadding}).toString();
    console.log(encodeURIComponent(encrypted2));

    let textSample = "YeC9tXJktTp6RB4UjxSJdMfiQNSyNYwP6JrBP5oKPa0evggTruE22bsbs3lfflqhRVuHPctYP2e3q0l7qdfM/gjes0EP6iCyKVgvzb0JkU4=";
    console.log(encodeURIComponent(textSample));


    // Llee7BT02U9BTwqnXNa3bzuiui73k5o6ammPLA1NbVSnmraLsrPdhw05LQGpQJInaYQ1/+xOzAH/kxevSlg0Yd824G+KmBkeTQVqZ39s9EI=
    
    let msg3 = "user_id=12&status=Inactive";
    let key3 = encHex.parse("8a0976d5abc40274354b37fbf5c93eaa");
    let iv3 =  encHex.parse("9b110990b68db3670b86381893118bf9");
    let encrypted3 = aes.encrypt(msg3, key3, {iv:iv3, padding:padZeroPadding}).toString();
    console.log((encrypted3));
    console.log(encodeURIComponent(encrypted3));

    // partner-login/wT28b53UeY1gtN9d/awnfmGNm6xTyC6WBvPx6gPWwbepZORjGtRPZlb16rSM%3D

    let msg4 = "user_id=12&status=Inactive";
    let key4 = encHex.parse("9b98b82119d3c4e953dd3953925a973e");
    let iv4 =  encHex.parse("ac62af77da94b3a7d1ac148c9c895a33");
    let encrypted4 = aes.encrypt(msg4, key4, {iv:iv4, padding:padZeroPadding}).toString();
    console.log((encrypted4));
    console.log(encodeURIComponent(encrypted4));
    // Yuva corporate id: Gm58wzOBav6YdquP
    // key: 9b98b82119d3c4e953dd3953925a973e
    // iv: ac62af77da94b3a7d1ac148c9c895a33


    let decrypted4 = aes.decrypt(encodeURIComponent(encrypted4), key4, {iv:iv4, padding:padZeroPadding}).toString();
    console.log(decrypted4);
  }
  genHexString(len: number) {
        let output = '';
        for (let i = 0; i < len; ++i) {
            output += (Math.floor(Math.random() * 16)).toString(16);
        }
        return output;
    }
    testCrypto3() {
      // let msg = "user_id=testuser@123&status=Inactive";
      let msg = "user_id=testuser@1234&status=Active&start_date=2023-08-01&end_date=2023-08-31";
      const key = '8885df29956aaf920aa356dad7438bb8';//this.genHexString(32); //'0a55fe39f66f3320143caf647d5a7261';
      const iv = 'a2a0fe40080317daaad95bcd65d80fdb';//this.genHexString(32);//'4b2391ea751a9e62aa451c88b5481c4e';
      console.log(key, iv);
      let key4 = encHex.parse(key);
      let iv4 =  encHex.parse(iv);
      let encrypted4 = aes.encrypt(msg, key4, {iv:iv4, padding:padZeroPadding}).toString();
    console.log((encrypted4));
    console.log(encodeURIComponent(encrypted4));
   
      
    }
  testCrypto2() {
    let msg4 = "user_id=12&status=Inactive";
    // let key4 = encHex.parse("8a0976d5abc40274354b37fbf5c93eaa");
    // let iv4 =  encHex.parse("9b110990b68db3670b86381893118bf9");
    
const k = this.genHexString(32);
    const i = this.genHexString(32);
    let key4 = encHex.parse(k);
    let iv4 =  encHex.parse(i);
    console.log(k);
    console.log(i);
    let encrypted4 = aes.encrypt(msg4, key4, {iv:iv4, padding:padZeroPadding}).toString();
    console.log((encrypted4));
    console.log(encodeURIComponent(encrypted4));
  }

  testAAAACrypto() {
        // message to encrypt
        let msg2 = "user_id=abcd123&status=Active&start_date=2023-08-01&end_date=2023-08-31";
    
        // the key and iv should be 32 hex digits each, any hex digits you want, but it needs to be 32 on length each
        let key2 = encHex.parse("8a0976d5abc40274354b37fbf5c93eaa");
        let iv2 =  encHex.parse("9b110990b68db3670b86381893118bf9");
    
        // encrypt the message
        let encrypted2 = aes.encrypt(msg2, key2, {iv:iv2, padding:padZeroPadding}).toString();
        console.log(encrypted2);
        
        console.log(encodeURIComponent(encrypted2));
  }

  testYuvaCrypto() {
    // Gm58wzOBav6YdquP_testuser12
    let msg2 = "user_id=418&status=Active&start_date=2024-01-12&end_date=2024-02-02";
    let key2 = encHex.parse("9b98b82119d3c4e953dd3953925a973e");
    


    let iv2 =  encHex.parse("ac62af77da94b3a7d1ac148c9c895a33");

    // encrypt the message
    let encrypted2 = aes.encrypt(msg2, key2, {iv:iv2, padding:padZeroPadding}).toString();
    console.log(encrypted2);
    
    console.log(encodeURIComponent(encrypted2));
    // https://onmood9.com/testing/#/partner-login/web/Gm58wzOBav6YdquP/YdBvo9yCu90XPHE2YZleoNx%2FzsxoZ%2B9Lv68J4yX8lkL%2B3bTFiNvli0v9MIuIwHUg
  }

}
