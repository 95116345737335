import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { P_CUNIQUE_ID, P_CURRENT_SUBSCRIPTION, P_HAVE_ACTIVE_SUBSCRIPTION, P_PARTNER_HAS_SUBSCRIPTION } from '../constants/PartnerConstants';
import { CUNIQUE_ID, CURRENT_SUBSCRIPTION, HAVE_ACTIVE_SUBSCRIPTION } from '../constants/Constants';
import { isPartnerAccount } from '../Utils/WindowUtil';
import { GET_CORP_SUBSCRIPTIONS_URL } from '../constants/ApiEndPoints';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private http:HttpClient, private storageService: StorageService) { }

  getCorpActiveSubscriptions(corporateUniqueId: string) {
    return this.http.get<any>(GET_CORP_SUBSCRIPTIONS_URL+corporateUniqueId);
  }

  setUserSubscriptions() {
    const isPA = isPartnerAccount(this.storageService);
    const corporateUniqueId = isPA != null && isPA === true ? P_CUNIQUE_ID : CUNIQUE_ID;

    this.getCorpActiveSubscriptions(this.storageService.getItem(corporateUniqueId)).subscribe((data:any) => {
      if (data['count'] > 0) {
        let currentSubscription = data['data'][0];
        if(isPA) {
          this.savePartnerSubscription(currentSubscription);
        } else {
          this.saveIndividualUserSubscription(currentSubscription);
        }
        
      }
    });
  }
  saveIndividualUserSubscription(currentSubscription: any) {
    this.storageService.storeItem(CURRENT_SUBSCRIPTION, JSON.stringify(currentSubscription));
    // this.storeItem(HAVE_ACTIVE_SUBSCRIPTION, "false");
    const haveSubscription = currentSubscription != null 
      && currentSubscription.is_cancelled === 'N' 
      && new Date(currentSubscription.start_date) <= new Date() 
      &&  new Date(currentSubscription.end_date) >= new Date();
    if (haveSubscription) {
        this.storageService.storeItem(HAVE_ACTIVE_SUBSCRIPTION, "true");
    }
  }

  savePartnerSubscription(currentSubscription: any) {
    this.storageService.storeItem(P_CURRENT_SUBSCRIPTION, JSON.stringify(currentSubscription));
    // this.storeItem(HAVE_ACTIVE_SUBSCRIPTION, "false");
    const haveSubscription = currentSubscription != null 
      && currentSubscription.is_cancelled === 'N' 
      && new Date(currentSubscription.start_date) <= new Date() 
      &&  new Date(currentSubscription.end_date) >= new Date();
    if (haveSubscription) {
        this.storageService.storeItem(P_HAVE_ACTIVE_SUBSCRIPTION, "true");
    }

    if(this.storageService.getItem(P_PARTNER_HAS_SUBSCRIPTION) === null) {
      this.storageService.storeItem(P_HAVE_ACTIVE_SUBSCRIPTION, "false");
    }
  }
  
}
