import { Injectable,Output, EventEmitter } from '@angular/core';
import { StorageService } from './storage.service';
import { P_CUNIQUE_ID, P_CURRENT_SUBSCRIPTION, P_CURRENT_USER, P_HAVE_ACTIVE_SUBSCRIPTION, P_HAVE_SUBSCRIPTION, P_IS_LOGGEDIN, P_LINKED_ACCOUNT,P_PARTNER_APP,P_PARTNER_HAS_SUBSCRIPTION, P_PARTNER_TERMS_PRIVACY, P_SESSION_EXPIRES_ON, P_TOKEN, P_UNAME, P_USER_TYPE } from '../constants/PartnerConstants';
import { DateUtil } from '../Utils/DateUtil';
import { isOpenedInBrowser, isPartnerAccount } from '../Utils/WindowUtil';
import { TOKEN } from '../constants/Constants';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  @Output() partnerUserEvent: EventEmitter<any> = new EventEmitter();


  constructor(private storageService: StorageService, private authService: AuthenticationService,) {
   
  }

  getToken() {
    // const isBrowser = isOpenedInBrowser();
    let token = null;
    const isPA = isPartnerAccount(this.storageService);
    const isPartnerApp = this.storageService.getItem(P_PARTNER_APP);
    if(isPartnerApp && isPartnerApp === 'mobile') {
      token = this.storageService.getItem(P_TOKEN);
    } else if(isPA && isPartnerApp && isPartnerApp === 'web' && !isOpenedInBrowser()) {      
      token = this.storageService.getItem(P_TOKEN);
    } else {
      token = this.storageService.getItem(TOKEN);
    } 


    // if(!isBrowser && isPA) {
    //   // console.log('partner token');
    //   token = this.storageService.getItem(P_TOKEN);
    // } else {
    //   // console.log('token');
    //   token = this.storageService.getItem(TOKEN);
    // }
    return token;
  }

  storePartnerUserInLocalStorage(obj: any){
    if(isPartnerAccount(this.storageService)) {
        this.storageService.storeItem(P_CURRENT_USER, obj.user);
        this.storageService.storeItem(P_UNAME, obj.name);
        this.storageService.storeItem(P_TOKEN, obj.token);
        this.storageService.storeItem(P_IS_LOGGEDIN, "true");
        this.storageService.storeItem(P_USER_TYPE, obj.user_type);
        this.storageService.storeItem(P_CUNIQUE_ID, obj.cunique_id);
        this.storageService.storeItem(P_PARTNER_TERMS_PRIVACY,obj.terms_privacy);
        this.storageService.storeItem(P_LINKED_ACCOUNT, obj.linkedAccount);

        if(obj.haveSubscription === 'true') {
          this.storageService.storeItem(P_HAVE_ACTIVE_SUBSCRIPTION, "true");
          this.storageService.storeItem(P_HAVE_SUBSCRIPTION, obj.haveSubscription);
          this.storageService.storeItem(P_PARTNER_HAS_SUBSCRIPTION,  obj.haveSubscription);
          this.storageService.storeItem(P_CURRENT_SUBSCRIPTION, JSON.stringify(obj.subscription));
        }
        this.storageService.storeItem(P_SESSION_EXPIRES_ON, JSON.stringify(DateUtil.getDateAfterDays(365)));
        this.partnerUserEvent.emit(true);
      }
  }

}
