<div class="main-wrapper">
    <div class="container" style="margin-top: 20px;" *ngIf="oldcareplanlist">
        <div (click)="goBack()"><img src="assets/img/wht-arrow.svg" /></div>
        <div class="text-center py-3 mb-4">
            <h1 style="color: green;">
                Old Care Plans
            </h1>
        </div>
        <div class="table-scrollable justify-content-md-center">
            <table class="table table-striped 
                          table-bordered table-hover">
                <thead>
                    <tr>
                        <th><b>S. No.</b></th>
                        <th><b>Name</b></th>
                        <th><b>From Date</b></th>
                        <th><b>To Date</b></th>
                        <th><b>Actions</b></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="oldCarePlanList.length === 0">
                        <td colspan="8">No active old care plans available</td>
                    </tr>
                    <tr *ngFor="let oc of oldCarePlanList;index as i">
                        <td>{{i+1}}</td>
                        <td>{{oc.care_plan_name}}</td>
                        <td>{{oc.start_date}}</td>
                        <td>{{oc.end_date}}</td>
                        <td><button class="btn btn-sm btn-info" (click)="goToMyCarePlan(oc)">view</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="container" *ngIf="shownMyCarePlan">
        <!-- <div style="text-align: center;" >My care Plan</div> -->
        <div (click)="BackOldCarePlanList()"><img src="assets/img/wht-arrow.svg" /></div>
        <div class="row justify-content-center font-weight-bold"
            style="text-align: center;">
            <div><span class="module-name">My Old Care Plan</span></div>
            <div>&nbsp;</div>
        </div>
       
        <div style="text-align: center;" *ngIf="carePlanList.length === 0">No Active Care Plan Exist</div>
        <div class="all-modulebx text-center">
            <div class="card-body">
                <div class="row justify-content-md-center">
                    <div class="col col-lg-2">
                        <div class="d-flex justify-content-between align-items-center modul-head">
                            <div class="carePlanName">
                                <span>{{carePlanList[0].care_plan_name}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col col-lg-6" style="font-weight: 500;">
                        Date: <span class="ToDate">{{carePlanList[0].start_date | date: 'dd-MM-yyyy' }} To
                            {{carePlanList[0].end_date | date:
                            'dd-MM-yyyy'}}</span>
                    </div>
                </div>
            </div>
        </div><br>
        <div class="all-modulebx">
            <div class="card-body">
                <div class="row justify-content-md-center">
                    <div class="row">
                        <div class="col-9">
                            <span class="descriptionText">{{carePlanList[0].care_plan_description}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div><br>
        <div class="all-modulebx">
            <div class="card-body">
                <div class="row justify-content-md-center">
                    <div class="row">
                        <div class="col-9">
                            <!-- <span class="descriptionText">{{carePlanList[0].care_plan_long_description}}</span> -->
                            <div [innerHTML]= "carePlanList[0].care_plan_long_description | safeHtml"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div><br>
        

        <div class="table-scrollable justify-content-md-center" *ngIf="carePlanList[0].items.length>0">
            <div class="all-modulebx" id="item-module">
                <table class="table table-bordered table-hover">
                    <tbody>
                        <tr *ngFor="let item of carePlanList[0].items; index as i">
                            <td>{{i+1}}</td>
                            <td>
                                <div class="m_name">
                                    <div class="moduleName" *ngIf="item.module_name.length <= 20">
                                        <a>{{item.module_name}}</a>
                                    </div>
                                    <div class="prod-img">
                                        <img style="width: 200px;" [src]="moduleImagePath + item.module_image" alt="" />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <p *ngFor="let time of item.time">
                                    {{time?.time_of_day}} - ({{time?.item_time_status}})</p>
                            </td>
                            <!-- <td>
                                                <div class="itemStatus">{{item.item_status}}</div>
                                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div><br>
    </div>
</div>