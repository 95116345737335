import { Component } from '@angular/core';
// import { ServiceHelperService } from 'src/app/components/helper/service/service-helper.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent {
  serviceblock = {
    // [Q69G4SKxUhXIk5gc,nbEPrmx9OvH2dSBI,0bpVgJDQiWSc7f9z]
    "services":["Stress","Depression","Anxeity","Sleep care","Trauma","Relationship"]
  }
}
