import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserFavouriteModuleRoutingModule } from "./user-favourite-module.routing.module";
import { UserFavouriteModuleComponent } from './user-favourite-module.component';
import { IvyCarouselModule } from '../carousel/carousel.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [UserFavouriteModuleComponent],
  imports: [
    CommonModule,
    IvyCarouselModule,
    UserFavouriteModuleRoutingModule
  ]
})
export class UserFavouriteModuleModule { }
