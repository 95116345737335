
<!-- <div class="main-wrapper"> -->
    <div class="container">
        <!-- <div style="text-align: center;" >My care Plan</div> -->
        <div *ngIf="carePlanList.length > 0" class="row justify-content-center font-weight-bold"
            style="text-align: center;">
            <div><span class="module-name">My Care Plan</span></div>
            <div>&nbsp;</div>
        </div>
        <div class="oldCarePlanbtn">
            <button class="btn btn-sm btn-info" (click)="oldCarePlanList()">old care plans</button>
        </div><br>
        <div style="text-align: center;"  *ngIf="carePlanList.length === 0 && isLoading === false">No Active Care Plan Exist</div>
        <div class="all-modulebx text-center" *ngIf="carePlanList.length > 0">
            <div class="card-body">
                <div class="row justify-content-md-center">
                    <div class="col col-lg-2">
                        <div *ngIf="carePlanList.length > 0"
                            class="d-flex justify-content-between align-items-center modul-head">
                            <div class="carePlanName">
                                <span>{{carePlanList[0].care_plan_name}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col col-lg-6" style="font-weight: 500;">
                        Date: <span class="ToDate">{{carePlanList[0].start_date | date: 'dd-MM-yyyy' }} To
                            {{carePlanList[0].end_date | date:
                            'dd-MM-yyyy'}}</span>
                    </div>
                </div>
            </div>
        </div><br>
        <div class="all-modulebx" *ngIf="carePlanList.length > 0">
            <div class="card-body">
                <div class="row justify-content-md-center">
                    <div class="row">
                        <div class="col-9">
                            <span class="descriptionText">{{carePlanList[0].care_plan_description}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div><br>
        <div class="all-modulebx" *ngIf="carePlanList.length > 0">
            <div class="card-body">
                <div class="row justify-content-md-center">
                    <div class="row">
                        <div class="col-9">
                            <!-- <span class="descriptionText">{{carePlanList[0].care_plan_long_description}}</span> -->
                            <div [innerHTML]= "carePlanList[0].care_plan_long_description | safeHtml"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  <br>
        <div class="table-scrollable justify-content-md-center" *ngIf="carePlanList.length > 0">
            <div class="all-modulebx" id="item-module">
                <table class="table 
                                        table-bordered table-hover">
                    <tbody>
                        <tr *ngFor="let item of carePlanList[0].items; index as i">
                            <td>{{i+1}}</td>
                            <td>
                                <div class="m_name">
                                    <div class="moduleName" *ngIf="item.module_name.length <= 20">
                                        <a>{{item.module_name}}</a>
                                    </div>
                                    <div class="prod-img">
                                        <img style="width: 200px;" [src]="moduleImagePath + item.module_image" alt="" />
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="playbtn" style="display: flex; justify-content: center;" (click)="goToSessions(item)">
                                    <i class="session-play-new fa fa-play" [ngClass]="{'isDisabled' : item.item_status === 'PAUSED' || canPlay === false }"></i>
                                </div>
                            </td>
                            <td>
                                <p *ngFor="let time of item.time">
                                    {{time.time_of_day}} - ({{time.item_time_status}})</p>
                            </td>
                            <!-- <td>
                                                <div class="itemStatus">{{item.item_status}}</div>
                                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div> 
    </div>
<!-- </div> -->