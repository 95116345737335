export const secondsToHoursMinutes = (timeInSeconds: number) => {
    if(timeInSeconds === 0) {
        return '00:00';
      } else {
        timeInSeconds = Number(timeInSeconds);
        var h = Math.floor(timeInSeconds % 3600);
        console.log(h);
        
        var m = Math.floor(timeInSeconds % 3600 / 60);
        var hDisplay = h > 0 ? h +":" : "00:";
        var mDisplay = m > 0 ? m +"" : "00:";
        return hDisplay +""+ (m < 10 ? "0"+mDisplay : mDisplay);
      }
};


export const toHoursAndMinutes = (totalSeconds: number) => {
  const totalMinutes = Math.floor(totalSeconds / 60);

  const seconds = totalSeconds % 60;
  let hours = Math.floor(totalMinutes / 60);
  let minutes = totalMinutes % 60;
  // console.log(hours, minutes, seconds);
  const hours2 = hours < 9 ? "0"+hours : hours; 
  const minutes2 = minutes < 9 ? "0"+minutes : minutes;
  const seconds2 = seconds < 9 ? "0"+seconds : seconds;
  // console.log(hours2, minutes2);
  return hours2 +":"+minutes2; 
  // return { h: hours, m: minutes, s: seconds };
}