<footer>
  	<div class="container">
    	<div class="copyright">
      		<div class="social-icon d-block d-sm-block d-md-none mb-3">
				<a href=" https://twitter.com/Onmood9" target="_blank"><i class="fab fa-twitter"></i></a>
				<a href="https://www.facebook.com/onmood9" target="_blank"><i class="fab fa-facebook-f"></i></a>
				<a href="https://www.instagram.com/onmood9"  target="_blank"><i class="fab fa-instagram"></i></a>
				<a href="https://www.youtube.com/channel/UCzu3oQrhR2E6OSylITE-6Ew"  target="_blank"><i class="fab fa-youtube"></i></a>
        		<a href="https://www.linkedin.com/company/onmood9/"  target="_blank"><i class="fab fa-linkedin-in"></i></a> 
				<a href="https://in.pinterest.com/onmood9/"  target="_blank"><i class="fab fa-pinterest-p"></i></a>
			</div>
      		<div>© Copyrights - All rights reserved to onmood9(V-{{version}})</div>
      		<div class="social-icon d-none d-sm-none d-md-block">
				<a href=" https://twitter.com/Onmood9" target="_blank"><i class="fab fa-twitter"></i></a>
				<a href="https://www.facebook.com/onmood9" target="_blank"><i class="fab fa-facebook-f"></i></a>
				<a href="https://www.instagram.com/onmood9"  target="_blank"><i class="fab fa-instagram"></i></a>
				<a href="https://www.youtube.com/channel/UCzu3oQrhR2E6OSylITE-6Ew"  target="_blank"><i class="fab fa-youtube"></i></a>
        		<a href="https://www.linkedin.com/company/onmood9/"  target="_blank"><i class="fab fa-linkedin-in"></i></a> 
				<a href="https://in.pinterest.com/onmood9/"  target="_blank"><i class="fab fa-pinterest-p"></i></a>
			</div>
    	</div>
  	</div>
</footer>