import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApexChartsComponent } from './apex-charts.component';

const routes: Routes = [
    {
        path: 'apex',
        component: ApexChartsComponent
    },
];
@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})  
export class ApexChartsRoutingModule { }
