import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CareProviderRoutingModule} from "./care-provider.routing.module";
import { BrowserModule } from '@angular/platform-browser';
import { CareProviderComponent } from './care-provider.component';
import { SearchFormComponent } from '../search-form/search-form.component';
import { DoctorSidebarComponent } from '../doctor-sidebar/doctor-sidebar.component';
import { ContentComponent } from '../content/content.component';
import { DoctorDetailsComponent } from '../doctor-details/doctor-details.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ServicesComponent } from '../services/services.component';
import { WhyUsComponent } from '../why-us/why-us.component';
import { WorkProcessComponent } from '../work-process/work-process.component';
import { TeamComponent } from '../team/team.component';
import { FilterPipe } from 'src/app/filter.pipe';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@NgModule({
  declarations: [
    CareProviderComponent,
    SearchFormComponent,
    DoctorSidebarComponent,
    ContentComponent,
    DoctorDetailsComponent,
    ServicesComponent,
    WhyUsComponent,
    WorkProcessComponent,
    TeamComponent,
    FilterPipe
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    CareProviderRoutingModule,
    NgxPaginationModule,
    SlickCarouselModule
  
   
  ],
  providers: [FilterPipe],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class CareProviderModule { }
