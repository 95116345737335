import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ONMOOD9_BG_MUSIC_PATH, ONMOOD9_COURSE_IMAGES_PATH, ONMOOD9_IMAGES_PATH, ONMOOD9_SESSION_FILE_PATH } from 'src/app/constants/ApiEndPoints';
import { CarePlanModuleSession } from 'src/app/model/care-plan-module-session';
import { CategoryGroupModule } from 'src/app/model/category-group-module';
import { ApiService } from 'src/app/services/api-service';
import { CategoriesService } from 'src/app/services/categories.service';
import { StorageService } from 'src/app/services/storage.service';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { UserService } from 'src/app/services/user.service';
import { BaseComponent } from '../base/base.component';
import { Video } from 'src/app/model/video';
import { CategoryGroupModuleSession } from 'src/app/model/category-group-module-session';
import Aos from 'aos';
import { UserUtil } from 'src/app/Utils/UserUtil';
import { isPartnerAccount } from 'src/app/Utils/WindowUtil';
import { P_CUNIQUE_ID, P_CURRENT_SUBSCRIPTION, P_USER_TYPE } from 'src/app/constants/PartnerConstants';
import { CORP, CUNIQUE_ID, CURRENT_SUBSCRIPTION, INDI, USER_TYPE } from 'src/app/constants/Constants';

@Component({
  selector: 'app-care-plan-sessions',
  templateUrl: './care-plan-sessions.component.html',
  styleUrls: ['./care-plan-sessions.component.scss'],
  providers:[UserUtil]
})
export class CarePlanSessionsComponent extends BaseComponent implements OnInit {
  private readonly userUtil = inject(UserUtil);
  moduleId:any;
  moduleSessions: Array<CarePlanModuleSession> = [];
  noSessionsText: string = "";
  voiceOtionButtons: Array<{ title: string, isActive: boolean, canDisplay: boolean, isBtn: boolean, value: string }>;
  isBothVoiceSelected = false;
  selectedVoiceOption = "M";
  selectedGroupModule!: CategoryGroupModule;
  isSelectedGroupModuleAvailable: boolean = false;
  mentalHealthVideoUrl = "";
  moduleVideos: Array<Video> = [];
  videosPath = "";
  moduleImagePath = "";
  selectedAudioSource: string = "https://onmood9.com/assets/media/meditation.mp3";
  userPlayedSessionIds: Array<number> = [];
  @ViewChildren("audio") audios!: QueryList<ElementRef>;
  selectedSession = '';
  selectedSessionId = 0;
  isSessionPlayed: boolean = false;

  showAudioPopup = false;
  audioElement!: ElementRef ;
  totalDuration = "00:00";

  selectedIndex = -1;
  user: any;

  deviceHeight = 0;
  deviceWidth = 0;
  image = "http://localhost/meditation_server/uploads/courses/images/meditation.jpg";
  moduleImage = "";
  courseId = 0;
  groupId = 0;
  isHidden = true;
  customOptions: any = {
    loop: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      800: {
        items: 4
      }
    },
    nav: false
  }

  
  categoryId = 0;
  rVideos = 10;
  @ViewChild('video1') video1!: ElementRef;
  @ViewChild('video2') video2!: ElementRef;
	@ViewChild('videoDiv') videoDiv!: ElementRef;
	@ViewChild('closeVideoIcon') closeVideoIcon!: ElementRef;
  relavantVideoUrl: string = ""; 
  voiceOPtions = [];
  moduleFavouriteIcon = "";


  constructor(private apiService: ApiService,private route: ActivatedRoute, private router: Router, 
    private userService: UserService, 
    private categoriesService: CategoriesService, 
    public override http: HttpClient,
    private subscriptionService: SubscriptionService,
    public override storageService: StorageService) { 
    super(storageService, http);
    this.mentalHealthVideoUrl  = ONMOOD9_IMAGES_PATH + "Corona_mental_health_pandemic_480.mp4#t=2";
    this.videosPath = ONMOOD9_BG_MUSIC_PATH;
    this.moduleImagePath = ONMOOD9_COURSE_IMAGES_PATH;
    this.voiceOtionButtons = [
      { title: "Male", isActive: true, canDisplay: false, isBtn: false, value: 'M' },
      { title: "Female", isActive: false, canDisplay: false, isBtn: false, value: 'F' },
      { title: "Music", isActive: false, canDisplay: false, isBtn: false, value: 'A' }
    ];
    this.route.paramMap.subscribe((params:any) => { 
      this.moduleId = Number(params.get('module_id'));
    });
   }


  ngOnInit(): void {
    this.deviceWidth = window.screen.width;
    this.deviceHeight = window.screen.height;
    const currentUser = this.storageService.getCurrentUser(); 
    if(currentUser) {
      this.user = currentUser;
    }
    this.checkActiveSubscription();
    Aos.init();
    this.moveToTop();
  }
  moveToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;    
  }
  async checkActiveSubscription() {
    const isPA = isPartnerAccount(this.storageService);
    const userType = isPA ? P_USER_TYPE : USER_TYPE;
    // console.log(userType, this.storageService.getItem(userType));
    
    if(this.storageService.getItem(userType) === CORP) {
      const isPA = isPartnerAccount(this.storageService);
      const cunique_id = isPA != null && isPA === true ? P_CUNIQUE_ID : CUNIQUE_ID;
      this.subscriptionService.getCorpActiveSubscriptions(this.storageService.getItem(cunique_id)).subscribe((data:any) => {        
        if (data['count'] > 0) {
          let currentSubscription = data['data'][0];
          if(isPA != null && isPA === true) {
            this.storageService.storeItem(P_CURRENT_SUBSCRIPTION, JSON.stringify(currentSubscription));
          } else {
            this.storageService.storeItem(CURRENT_SUBSCRIPTION, JSON.stringify(currentSubscription));
          }          
          if (currentSubscription != null &&
            currentSubscription.is_cancelled === 'N' &&
            new Date(currentSubscription.start_date) <= new Date() &&
            new Date(currentSubscription.end_date) >= new Date()) {
          }
          //  else {
          //   this.router.navigate(["/user-home"]);
          //   return;
          // }
        }
      });
    } else if(this.storageService.getItem(USER_TYPE) === INDI) {
      // Add logic to get subscription, need to re think on below logic
      // const status = await this.userUtil.haveActiveSubscription();
      // console.log('status', status);
      // this.haveActiveSubscription = status;
    }
    console.log(this.moduleId);
    this.getModuleVideos();
    this.getModuleInformation(this.moduleId);

    this.apiService.getCarePlanModuleSessions(this.moduleId).subscribe((data:any) => {
      if(data['result'] === "success" && data['data'].length > 0) {
        this.moduleSessions = data['data'];
        console.log(this.moduleSessions);
        this.getUserPlayedSessions();
       
      } else {
        this.noSessionsText = "No Sessions available right now under this module";
      }
    });
  }

  getModuleInformation(moduleId: number) {
    this.categoriesService.getModuleDetails(moduleId).subscribe((response:any) => {
      if(response && response['data'] && response['data'].length > 0) {
        this.selectedGroupModule = response['data'][0]; 
        this.isSelectedGroupModuleAvailable = true;
        this.setVoiceOption();
        this.moduleImage =  ONMOOD9_COURSE_IMAGES_PATH + this.selectedGroupModule.module_image;
      }
      this.checkModuleIsFavourite(this.moduleId);
    });
  }
  checkModuleIsFavourite(moduleId: number) {
    const userId = this.storageService.getCurrentUser().id;
    this.categoriesService.checkModuleIsFavourite(userId, moduleId).subscribe((data:any) => {
      // console.log(data);
      const JSONData = JSON.parse(JSON.stringify(data));
      if(JSONData['result'] && JSONData['count'] > 0) {
        this.selectedGroupModule.ufm_id = JSONData['modules'][0].ufm_id;
        // console.log('Favourite');
        this.selectedGroupModule.isFavourite = true;
        this.moduleFavouriteIcon = "assets/img/heart.svg";
      } else {
        // console.log('Not Favourite');
        this.selectedGroupModule.isFavourite = false;
        this.moduleFavouriteIcon = "assets/img/awesome-heart.svg";

      }
    });
  
  }

  isVoicesAvailable = false;
  setVoiceOption(){
    let voiceOption = this.selectedGroupModule.voice_over_option;
    this.isBothVoiceSelected = voiceOption === 'BMD' || voiceOption === 'BFD';
    if(this.isBothVoiceSelected) {
      this.voiceOtionButtons[0].canDisplay = true;
      this.voiceOtionButtons[1].canDisplay = true;
    } else if(voiceOption === 'OMD'){
      this.voiceOtionButtons[0].canDisplay = true;
    } else if(voiceOption === 'OFD'){
      this.voiceOtionButtons[1].canDisplay = true;
    } else if(voiceOption === 'M'){
      this.voiceOtionButtons[2].canDisplay = true;
    }


    if(voiceOption === 'OMD' || voiceOption === 'BMD') {
      this.selectedVoiceOption = 'M';
      this.changeVoiceOption('M');
    } else if(voiceOption === 'OFD' || voiceOption === 'BFD') {
      this.selectedVoiceOption = 'F';
      this.changeVoiceOption('F');
    } else if(voiceOption === 'M')   {
      this.selectedVoiceOption = 'A';
      this.changeVoiceOption('A');
    }
    this.isVoicesAvailable = true;
  }

  changeVoiceOption(value: string) {
   this.voiceOtionButtons.forEach(navLink => {
     if (navLink.value === value) {
       navLink.isActive = true;
       this.selectedVoiceOption = navLink.value;
     } else {
       navLink.isActive = false;
     }
   });
  }


  videoCarosalHeight = 0;
  videoCarosalItems = 0;

  fetchSessionFiles(index: number, sessionId: number, title: string, canPlay: boolean, status: string) {
    console.log(status);
    console.log(canPlay);
    if(status === 'L') return;
    this.categoriesService.getSessionFilesBySessionId(sessionId, this.selectedVoiceOption).subscribe((response: any) => {
      if(response['count'] === 0) {
        alert("No files available")
      } else if (response['count'] > 0){
        const fileName = response['data'][0]['file_name'];
        this.selectedAudioSource = ONMOOD9_SESSION_FILE_PATH + fileName;
        this.showPopupModal(index, sessionId, title, canPlay);
      } else {
        alert("Something went wrong, please try again after sometime");
      }
    });
  }

  showPopupModal(index: number, sessionId: number, title: string, canPlay: boolean) {
    // alert(index);
    // alert(sessionId);
    // alert(title);
    // alert(canPlay);
    this.selectedSessionId = sessionId;
    this.selectedSession = title;
    if(this.userPlayedSessionIds.indexOf(sessionId) >=0 ) {
      this.isSessionPlayed = true;
    }
    // this.fetchSessionFiles(sessionId);
    // return;
    if(canPlay) {
      this.selectedIndex = index;
      this.selectedSession = title;
      this.showAudioPopup = true;
      // No need below commented code
      /*let tempAudiosList = this.audios['_results'];
      this.audioElement = tempAudiosList[index].nativeElement; 
      document.body.style.overflow = "hidden";
      this.totalDuration = DateUtil.convertNumberToTime(this.audioElement['duration']); 
      */     
    }
  }
  getUserPlayedSessions() {
    let userId = this.user.id;
    let uId = Number(userId);
    this.userService.getUserPlayedSessions(userId).subscribe((data:any) => {
      const JSONData = JSON.parse(JSON.stringify(data));

      if(JSONData['status'] === true && JSONData['count'] > 0) {
        let playedSessionsArray = JSONData['data'];
        playedSessionsArray.forEach((session: CategoryGroupModuleSession) => {
          if(session.is_fully_listened === 'Y' && this.userPlayedSessionIds.indexOf(session.session_id) === -1) {
            this.userPlayedSessionIds.push(session.session_id); 
          }           
        });
      }

      this.checkPlayedSessions();
      
    });
  }

  checkPlayedSessions() {

    // C- Completed, L- Lock, P- Ready to Play
    let readyToPlayIndex = 0;
    this.moduleSessions.forEach((session, index) => {      
      if(this.userPlayedSessionIds.indexOf(session.id) >=0 ) {
        session.status = "C";
        session.canPlay = true;
        readyToPlayIndex = index + 1;
      } else {
        session.status = "P";
        session.canPlay = false;
      }


      
      // Make first session available to play      
      if(index === 0 && session.status != 'C') {
        session.status = "P";
        session.canPlay = true;
      }
      if(index === readyToPlayIndex) {
        session.status = "P";
        session.canPlay = true;
      }

      const isPA = isPartnerAccount(this.storageService);
      const key = isPA ? P_USER_TYPE : USER_TYPE;

      if(this.storageService.getItem(key) === CORP) {
        if(!this.userUtil.haveActiveSubscription() && session.is_paid === "paid") {
          session.status = "L";
          session.canPlay = false;
        }
      } else if(this.storageService.getItem(key) === INDI) {
        // Need to add logic to check active subscription later, for as of now only check for paid or free
        if(!this.userUtil.haveActiveSubscription() && session.is_paid === "paid") {
          session.status = "L";
          session.canPlay = false;
        }
      }
    });     
  }  
  getModuleVideos() {
    let moduleId = this.moduleId;
    this.categoriesService.getModuleVideos(moduleId).subscribe((data:any) => { 
      const JSONData = JSON.parse(JSON.stringify(data));
      if(JSONData['result'] === 'success') {
        this.moduleVideos = JSONData['data'];
        // this.filterVideos();
        this.videoCarosalHeight = this.deviceWidth > this.deviceHeight ? 300 : 200;
        this.videoCarosalItems = this.deviceWidth > this.deviceHeight ? 3 : 2;

        this.moduleVideos.forEach(video => {
          if(video.video_thumb_image === "") {
            video.isVideoThumbAvailable = false;
          } else {
            video.isVideoThumbAvailable = true;
          }
        });
      }
    });
  }

  playVideoEvent(event: any) {
    if(event.name === "click" && event.cellIndex !== undefined && event.cellIndex >=0) {
      this.playRelavantVideo(this.moduleVideos[event.cellIndex].audio_video_file);
    }
  }
  handlePopup(value: any) {
    this.showAudioPopup = value;
    document.body.style.overflow = "";
  }

  playRelavantVideo(src: string) {
    // alert(src);
    let video = this.video1.nativeElement;
    video.src = this.videosPath + src; // "https://onmood9.com/onmood9.com/assets/media/Corona_mental_health _pandemic_480.mp4"
    video.style.height = '95%';
    video.currentTime = 0;
    video.play();
    var isPlaying = video.currentTime > 0 && !video.paused && !video.ended 
    && video.readyState > video.HAVE_CURRENT_DATA;
    if (!isPlaying) {
      video.play();
      let closeVideoIcon = this.closeVideoIcon.nativeElement;
      closeVideoIcon.style.display = 'block';

      let videoDivObject = this.videoDiv.nativeElement;
      videoDivObject.style.height = '100%';

      document.body.style.overflow = "hidden";
    }
  }
  makeFavourite(module_id: number) {
    if(!this.selectedGroupModule.isFavourite) {
      const userId = this.storageService.getCurrentUser().id;
      this.categoriesService.saveFavouriteModule(module_id, userId).subscribe((data:any) => {
        // console.log(data);
        
        const JSONData = JSON.parse(JSON.stringify(data));
        if(JSONData['status'] === true) {
          // this.favouriteModules = JSONData['modules'];
          this.getModuleInformation(this.moduleId);
        }
      })
    } else {
      // console.log(this.selectedGroupModule);
      
      this.categoriesService.removeFavouriteModule(this.selectedGroupModule.ufm_id).subscribe((data:any) => {
        // console.log(data);
        
        const JSONData = JSON.parse(JSON.stringify(data));
        if(JSONData['result'] === 'success') {
          // this.favouriteModules = JSONData['modules'];
          this.getModuleInformation(this.moduleId);
        }
      })
    }
  }

  goBack() { 
    this.router.navigate(["/my-care-plan"]);
  }
}
