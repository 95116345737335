
export const getNamesArray = (type: string, idArray: any, list: any) => {
    switch (type) {
        case 'specialization':
            return list.filter((specialization: any) => idArray?.split(',').indexOf(specialization?.specialization_id) !== -1 );
        case 'therapy':
            return list.filter((therapy: any) => idArray?.split(',').indexOf(therapy?.therapy_id) !== -1 );
        case 'service':
            return list.filter((service: any) => idArray?.split(',').indexOf(service?.service_id) !== -1 );
        default:
            return null; 
    }
}