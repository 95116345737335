import { Component, OnInit } from '@angular/core';
import data from "../../../data/whyus.json";

@Component({
  selector: 'app-why-us',
  templateUrl: './why-us.component.html',
  styleUrls: ['./why-us.component.css']
})
export class WhyUsComponent implements OnInit {
 whyus = [
  {
      "id":1,
      "title":"Improve Sleep Program",
      "icon":"flaticon-heartbeat",
      "text":"Improve your sleep quality naturally, through personalized sleep plan, guided sessions, sound and music therapies, sleep trackers and coach support."
  },
  {
      "id":2,
      "title":"Happiness Program",
      "icon":"flaticon-group",
      "text":"Learn to enhance your emotional well-being by amplifying your pleasant emotions & managing your negative moods through guided sessions, mood trackers and coach  support."
  }
];
  constructor() { }

  ngOnInit(): void {
  }

}
