import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserHomeRoutingModule } from "./user-home.routing.module";
import { UserHomeComponent } from './user-home.component';
import { IvyCarouselModule } from '../carousel/carousel.module';
import { PartnerAlertComponent } from '../partner-alert/partner-alert.component';

@NgModule({
  declarations: [UserHomeComponent, PartnerAlertComponent],
  imports: [
    CommonModule,
    IvyCarouselModule,
    UserHomeRoutingModule
  ],
  exports:[
    PartnerAlertComponent
  ]
})
export class UserHomeModule { }
