<div class="container">
  <div class="row signup-container">   
    <div class="col-sm-6 login-form" *ngIf="showResetForm">    
      <h4 class="txt1 text-center">Reset New Password</h4>
      <p *ngIf="isSuccessfull">Successfully updated, will redirect to home page in few seconds...</p>
      <div>
          <form [formGroup]="passwordResetForm" (ngSubmit)="resetPassowrd()">
            <div class="form-group">
              <label>New Password</label>
              <input type="password" formControlName="newPassword" class="form-control"
                [ngClass]="{ 'is-invalid': resetPassowrdFormSubmitted && passwordResetFormControls['newPassword'].errors }" />
              <div *ngIf="resetPassowrdFormSubmitted && passwordResetFormControls['newPassword'].errors" class="invalid-feedback">
                <div *ngIf="passwordResetFormControls['newPassword'].errors['required']">Password is required</div>
                <div *ngIf="passwordResetFormControls['newPassword'].errors['minlength']">Password must be at least 6 characters</div>
              </div>
            </div>
            <div class="form-group">
              <label>Retype Password</label>
              <input type="password" formControlName="confirmPassword" class="form-control"
                [ngClass]="{ 'is-invalid': resetPassowrdFormSubmitted && passwordResetFormControls['confirmPassword'].errors }" />
              <div *ngIf="resetPassowrdFormSubmitted && passwordResetFormControls['confirmPassword'].errors" class="invalid-feedback">
                <div *ngIf="passwordResetFormControls['confirmPassword'].errors['required']">Retype Password is required</div>
                <div *ngIf="passwordResetFormControls['confirmPassword'].errors['mustMatch']">Passwords must match</div>
              </div>
            </div>
            <div class="form-group">
              <button class="btn btn-primary" [disabled]="isSuccessfull">Reset Password</button>
            </div>
          </form>
      </div>
    </div>
    <div class="col-sm-6 login-form"  *ngIf="!showResetForm">
      {{invalidResetLinkMessage}}
    </div>
  </div>
</div>