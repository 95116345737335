
<div class="section section-padding sigma_service-sec style-16">
    <div class="container">
        <div class="row">
            <!-- Data -->
            <div class="col-lg-2 col-md-6 col-sm-6" *ngFor="let s of serviceblock.services">
                <div class="sigma_service style-16">
                    <div class="sigma_service-body">
                        <h5 style="font-size:18px; text-align: center;">
                            <a>{{s}}</a>
                        </h5>
                        <a class="btn-link primary-color">
                            Search
                            <i class="far fa-long-arrow-alt-right"></i>
                        </a>
                    </div>
                </div>
            </div>
            <!-- Data -->
        </div>
    </div>
</div>