import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CareProviderComponent } from './care-provider.component';
import { ContentComponent } from '../content/content.component';
import { DoctorDetailsComponent } from '../doctor-details/doctor-details.component';


const routes: Routes = [
  {
    path: 'care-provider',
    component: CareProviderComponent
  },
  {
    path: 'doctors-grid/:topic/:location',
    component: ContentComponent
  },
  {
    path: 'doctors-details/:id',
    component: DoctorDetailsComponent
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CareProviderRoutingModule { }