<div class="container">
    <div class="row signup-container">   
      <div class="col-sm-6 login-form">    
        <h4 class="txt1 text-center">Forgot Password ?</h4>
        <div >
          <form [formGroup]="codeSendForm" (ngSubmit)="sendCode()">
            <p style="text-align: center">{{infoMessage}}</p>
            <div class="form-group">
              <label>Email</label>
              <input type="text" formControlName="email" class="form-control" (input)="resetErrorMessage()"
                [ngClass]="{ 'is-invalid': codeSendFormSubmitted && codeSendFormControls['email'].errors }" />
              <div *ngIf="codeSendFormSubmitted && codeSendFormControls['email'].errors" class="invalid-feedback">
                <div *ngIf="codeSendFormControls['email'].errors['required']">Email is required</div>
                <div *ngIf="codeSendFormControls['email'].errors['email']">Email must be a valid email address</div>
              </div>
              <div style="color: red; width: 100%">{{errorResponseMessage}}</div>
            </div>
            <div class="form-group">
              <button class="btn btn-primary" [disabled]="showSuccessMessage">Send link</button>
            </div>
          </form>
        </div>
    </div>     
  </div>
</div>