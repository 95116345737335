import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
import {  CURRENT_USER,IS_LOGGEDIN, SESSION_EXPIRES_ON } from '../constants/Constants';
import { P_CURRENT_USER, P_IS_LOGGEDIN, P_PARTNER_ACCOUNT, P_PARTNER_APP } from '../constants/PartnerConstants';
import { isOpenedInBrowser, isPartnerAccount } from '../Utils/WindowUtil';

const SECRET_KEY = 'SjlE2w3Be472mc3rdhet_kedy_KweYl1h87e23';

@Injectable({
  providedIn: 'root'
})
export class StorageService {


  constructor() { }


  storeItem(key: string, value: any) {
    window.localStorage.setItem(key, this.encrypt(value));
  }

  getItem(key: string) {
    return this.decrypt(window.localStorage.getItem(key));
  }

  removeItem(key: string) {
    window.localStorage.removeItem(key);   
  }


  clearStorage() {
    window.localStorage.clear();
  }
  getStorageCount() {
    return window.localStorage.length;
  }

  getCurrentUser() {
    const isPA = this.getItem(P_PARTNER_ACCOUNT);
    const isPartnerApp = this.getItem(P_PARTNER_APP);
    let key = "";
    if(isPartnerApp && isPartnerApp === 'mobile') {
      key = P_CURRENT_USER;
    } else if(isPA != null && isPA === 'true' && !isOpenedInBrowser()) {
      key = P_CURRENT_USER;
    } else {
      key = CURRENT_USER;
    }
    // const key = isPA != null && isPA === 'true' && !isOpenedInBrowser() ?  P_CURRENT_USER : CURRENT_USER;
    return JSON.parse(JSON.parse(JSON.stringify(this.getItem(key))));
  }

  isUserLoggedIn() {
    const isPA = this.getItem(P_PARTNER_ACCOUNT);
    const isPartnerApp = this.getItem(P_PARTNER_APP);
// console.log(isPA);
// console.log(isPartnerApp);
// console.log(!isOpenedInBrowser());


    if(isPartnerApp && isPartnerApp === 'mobile') {
      return this.getItem(P_IS_LOGGEDIN);
    } else if(isPartnerApp && isPartnerApp === 'web') {
      return this.getItem(P_IS_LOGGEDIN);
    } else if(isPA != null && isPA === 'true' && !isOpenedInBrowser()) {
      return this.getItem(P_IS_LOGGEDIN);
    } else {
      return this.getItem(IS_LOGGEDIN);
    }
    // return isPA != null && isPA === 'true' && !isOpenedInBrowser() ? this.getItem(P_IS_LOGGEDIN) : this.getItem(IS_LOGGEDIN);
  }

  isSessionExpired() {
   return new Date(JSON.parse(this.getItem(SESSION_EXPIRES_ON))) < new Date();
  }



  encrypt(data: any) {
    data = CryptoJS.AES.encrypt(data, SECRET_KEY);
    data = data.toString();
    return data;
  }

  decrypt(data: any) {
    if(data) {
      data = CryptoJS.AES.decrypt(data, SECRET_KEY);
      data = data.toString(CryptoJS.enc.Utf8);
    } else {
      data = null;
    }
    return data;
  }

}
