<div class="modal-dialog signin-model" style="height: 100vh;">
    <div class="modal-content">
        <div class="modal-header text-center">
            <h5 class="modal-title w-100" id="exampleModalLabel">Alert</h5>
        </div>
        <div class="modal-body">
           <p> Welcome to Onmood9.com</p><br/>

            You are already signed in to onmood9, through Partner account. As you open onmood9.com you will be signed out of the partner account. If you want to go back to the partner account, please close this window. <br/><br />
            <p>If you want to continue at onmood9.com press the below button.</p>

            <button class="btn btn-primary btn-block" (click)="continueHere()">Continue on Onmood9.com</button>
        </div>
    </div>
</div>
