import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,Validators} from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { UserService } from "../../services/user.service";
import { AuthenticationService } from "../../services/authentication.service";

import { MustMatch } from "./mustMatch";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  
  resetLink: string = "";
  userEmail: string = "";
  passwordResetForm: FormGroup;
  showResetForm: boolean = false;
  invalidResetLinkMessage = "Verifying, please wait...";
  resetPassowrdFormSubmitted: boolean = false;
  isSuccessfull: boolean = false;
  
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public formBuilder:FormBuilder, 
    private userService: UserService,
    private authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params:any) => {
      this.resetLink = params['code'];
      this.userEmail = params['login'];
      // console.log(this.userEmail+","+this.resetLink);
      this.authenticationService.clearUser();
      
      this.verifyLink();
    });
    
  }

  verifyLink() {
    this.userService.verifyPasswordLink(this.userEmail, this.resetLink)
      .subscribe(
        (data:any) => {
              // console.log("data:", data);
              if(data['status'] === false) {
                // this.errorResponseMessage = data['errorMessage'] 
                this.invalidResetLinkMessage = "Reset password link invalid, redirecting to home page in few seconds...";
                setTimeout(()=> {
                  this.router.navigate(['/home'])
                  // alert("Please signin");
                }, 5000)
              } else if(data['status'] === true) {
                this.invalidResetLinkMessage = "";

                this.showResetForm = true;
                this.setupPasswordResetForm();
              }
          },
          error => {
              console.log("error:", error);
          });
  }

  get passwordResetFormControls() { return this.passwordResetForm.controls; }

  setupPasswordResetForm() {
    this.passwordResetForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required]
    }, {
        validator: MustMatch('newPassword', 'confirmPassword')
    });
  }

  resetPassowrd() {
    this.resetPassowrdFormSubmitted = true;
    if(this.passwordResetForm.invalid) {
      // console.log("newPassword is empty"+ JSON.stringify(this.passwordResetFormControls['newPassword']))
      // this.isEmailRequired = this.codeSendFormControls.email.errors.required;
      return;
    }
    this.userService.setUserPassword(this.userEmail, this.resetLink,     
      this.passwordResetFormControls['newPassword'].value)
      .subscribe(
        (data:any) => {
              // console.log("data:", data);
              if(data['status'] === false) {

              } else if(data['status'] === true) {
                this.isSuccessfull = true;
                setTimeout(()=> {
                  this.router.navigate(['/home']);
                  alert("Please Signin")
                }, 5000)
              }
          },
          error => {
              console.log("error:", error);
          });
  }
}
