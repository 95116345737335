import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ChartComponent,
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexLegend
} from "ng-apexcharts";
import ApexCharts from 'apexcharts';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
  legend: ApexLegend;

};
@Component({
  selector: 'app-apex-charts',
  templateUrl: './apex-charts.component.html',
  styleUrls: ['./apex-charts.component.css']
})
export class ApexChartsComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any;
  ngOnInit(): void {

   
  }
  constructor() {
    this.chartOptions = {
      series: [44, 55, 13, 43, 22],
      legend: {
        show: true,
        position: 'bottom'
      },
      chart: {
        
        width: 380,
        type: "pie",
        events: {
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            // console.log(event, chartContext, config);
          },
          legendClick: function(chartContext: any, seriesIndex: any, config: any) {
            // console.log(chartContext, seriesIndex, config);  
          }
        },
        dataLabels: {
          name: {
             show: false
          }
        }
      },
      // labels: ["Team A", "Team B", "Team C", "Team D", "Team E"],
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 400
      //       },
      //       legend: {
      //         position: "bottom"
      //       }
      //     }
      //   }
      // ]
    };
  }
  
  

}
