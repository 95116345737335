import { ApiService } from 'src/app/services/api-service';
import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/services/storage.service';
import { convertEmail } from 'src/app/Utils/StringUtil';
import { CarePlan } from 'src/app/model/care-plan';
import { ONMOOD9_COURSE_IMAGES_PATH } from 'src/app/constants/ApiEndPoints';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-care-plan',
  templateUrl: './care-plan.component.html',
  styleUrls: ['./care-plan.component.scss']
})
export class CarePlanComponent implements OnInit {
  currentUser: any;
  carePlanList: Array<CarePlan> = [];
  isLoading: boolean = false;
  moduleImagePath = "";
  canPlay:boolean=true;
    constructor(private apiService: ApiService,private route: ActivatedRoute, private router: Router, private storageService: StorageService) {
    this.moduleImagePath = ONMOOD9_COURSE_IMAGES_PATH;
  }

  ngOnInit(): void {
    this.getMyCarePlan();
  }

  getMyCarePlan() {
    this.currentUser = this.storageService.getCurrentUser();
    convertEmail(this.currentUser.email)
    this.isLoading = true;
    this.apiService.getMyCarePlan(convertEmail(this.currentUser.email)).subscribe((data)=> {
      console.log(data);
      this.isLoading = false;
      this.carePlanList = data['ActiveCarePlan'];
      this.carePlanList.filter((carePlanItem) => {
        return carePlanItem.items.filter((item)=>item.item_status !== 'PAUSED')
      });
    });
  }

  goToSessions(item:any){
    console.log(item);
    if(item.item_status !== 'PAUSED') this.router.navigate(['/care-plan-sessions/'+item.module_id]);
  }
  oldCarePlanList(){
    this.router.navigate(['/my-old-care-plans']);
  }
}
